import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import reducer from "../reducers";
import currentUserReducer from "../reducers/currentUser";
import vehicleSelectorReducer from "../reducers/vehicleSelector";
import rootSagas from "../sagas";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const rootReducer = combineReducers({
  reducer,
  currentUserReducer,
  vehicleSelectorReducer
});
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(middleware)
});

sagaMiddleware.run(rootSagas);

export default store;
