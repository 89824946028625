import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  EXPORT_CAMPAIGN,
  EXPORT_CONVERSION_REPORT,
  EXPORT_USAGE_REPORT
} from "../actions/types";
import { setLoading } from "../actions";
import {
  exportCampaignApi,
  exportConversionReportApi,
  exportUsageReportApi
} from "../api/handler";

function handleDownloadFile(response) {
  const filename = response.headers.get("content-disposition").split('"')[1];
  const blobObj = new Blob([response.data], {
    type: response.headers.get("content-type")
  });
  const url = URL.createObjectURL(blobObj);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

function* handleExportCampaign({ campaignId }) {
  yield put(setLoading(true));
  try {
    const response = yield call(exportCampaignApi, campaignId);
    if (response.status === 200) {
      handleDownloadFile(response);
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleExportUsageReport({ filters }) {
  yield put(setLoading(true));
  try {
    const response = yield call(exportUsageReportApi, filters);
    if (response.status === 200) {
      handleDownloadFile(response);
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleExportConversionReport({ filters }) {
  yield put(setLoading(true));
  try {
    const response = yield call(exportConversionReportApi, filters);
    if (response.status === 200) {
      handleDownloadFile(response);
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

export default function* exportSaga() {
  yield all([
    takeLatest(EXPORT_CAMPAIGN, handleExportCampaign),
    takeLatest(EXPORT_USAGE_REPORT, handleExportUsageReport),
    takeLatest(EXPORT_CONVERSION_REPORT, handleExportConversionReport)
  ]);
}
