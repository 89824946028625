/* eslint-disable react-hooks/exhaustive-deps */
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import SlideButton from "./SlideButton";
import { getIcon } from "../../utils/iconUtils";
import { useState, useEffect, useRef, cloneElement } from "react";
import { Pager } from "@progress/kendo-react-data-tools";
import gridColumns from "./gridColumns";
import BulkActions from "./BulkActions";
import FilterModal from "./FilterModal";
import "../../styles/portfolio/portfolio.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ColumnHider from "../shared/ColumnHider";
import { getStatusClass } from "../../utils/statusUtil";
import { useLocation, useNavigate } from "react-router-dom";
import fetchClient from "../../api/fetch";
import { fetchImg } from "../../utils/iconUtils";
import { formatLogo } from "../shared/brandLogo";
import { validateUserPermission } from "../../utils/helpers";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import noFound from "../../assets/icons/noFound.svg";
import ellipse from "../../assets/icons/ellipse.svg";
import { DateFilterCell } from "../shared/DateFilterCell";

const Portfolio = ({
  getPortfolioList,
  portfolioList,
  portfolioListPage,
  portfolioFilters,
  setPortfolioFilters,
  setLoading,
  currentUser
}) => {
  const { t } = useTranslation();
  let location = useLocation();
  const Navigate = useNavigate();
  const carPerPage = useRef(0);
  const [carData, setCarData] = useState([]);
  const [carDistribution, setCarDistribution] = useState([]);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [columns, setColumns] = useState(gridColumns);
  const [showColumnHider, setShowColumnHider] = useState(false);
  const [selectedCar, setSelectedCar] = useState();
  const [selectedLeads, setSelectedLeads] = useState([""]);
  const [sortParam, setSortParam] = useState("");
  const [gridSetting, setGridSetting] = useState({
    sort: [],
    filter: undefined,
    pageSizeValue: 10,
    page: {
      skip: 0,
      take: 10
    }
  });
  const [showDialog, setShowDialog] = useState({
    bulkActions: false,
    filter: false
  });
  const [filters, setFilters] = useState({
    unselected: [],
    modal: {},
    grid: [],
    status: []
  });

  useEffect(() => {
    setLoading(true);
    fetchClient()
      .get("/v1/portfolio/brand")
      .then((res) => {
        const data = res.data.data.map((item, idx) => {
          let imageUrl = "placeholder.png";

          if (item.isBrand) {
            imageUrl = item.brand.toLowerCase() + "/" + formatLogo(item.brand);
          } else {
            imageUrl =
              item.brand.toLowerCase() +
              "/" +
              formatLogo(item.brand + " " + item.model, "jpg");
          }

          return {
            ...item,

            imageUrl: fetchImg(imageUrl)
          };
        });
        setCarData(data);
        if (location.state?.prevPage !== "customer-record") {
          setSelectedCar(data[0]);
        }
        if (!res.data.data.length) {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // set the filters to how it was before the navigation
    if (
      location.state?.prevPage === "customer-record" &&
      Object.keys(portfolioFilters).length > 0
    ) {
      setSelectedLeads(portfolioFilters.selected);
      setGridSetting({
        ...gridSetting,
        page: portfolioFilters.page,
        sort: portfolioFilters.sort,
        filter: portfolioFilters.filter
      });
      setSelectedCar(portfolioFilters.car);
      setFilters(portfolioFilters.filterState);
    }
    setPortfolioFilters({});
  }, []);

  useEffect(() => {
    if (selectedCar) {
      getPortfolioList(queryBuilder());
    }
  }, [
    filters.grid,
    filters.modal,
    filters.status,
    sortParam,
    gridSetting.page,
    selectedCar
  ]);

  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    const pageNum = Math.floor(window.innerWidth / 290);
    carPerPage.current = pageNum;
    const arr = [];
    // store to array 0, 0 + carPerPage, ...
    // previous element becomes start & next element is end
    for (let i = 0; i < Math.ceil(carData.length / pageNum); i++) {
      if (i === 0) {
        arr.push(i);
      } else {
        arr.push(arr[i - 1] + pageNum);
      }
    }
    setCarDistribution(arr);
  }, [currentWidth, carData]);

  const getModalFilters = () => {
    let modalquery = "";
    for (const key in filters.modal) {
      modalquery += `&${key}=${filters.modal[key]}`;
    }

    return modalquery.concat(
      `&statusFilters=[${filters.status.map((stat) => stat.value)}]`
    );
  };

  const queryBuilder = () => {
    // ensure selectedCar has a value
    const car = selectedCar
      ? selectedCar.isBrand
        ? selectedCar.brand
        : selectedCar.model
      : "";
    const isBrand = selectedCar ? selectedCar.isBrand : true;
    const filtersParam = `&filters=[${filters.grid}]`;
    const modalFilters = getModalFilters();

    return `?page=${JSON.stringify(
      gridSetting.page
    )}${sortParam}&brandOrModel=${car}&isBrand=${isBrand}${filtersParam}${modalFilters}`;
  };

  const handleSwiperButtons = (swiper) => {
    if (swiper.isBeginning) {
      swiper.navigation.prevEl[0].classList.add("disabled");
      swiper.navigation.nextEl[0].classList.remove("disabled");
    } else if (swiper.isEnd) {
      swiper.navigation.nextEl[0].classList.add("disabled");
      swiper.navigation.prevEl[0].classList.remove("disabled");
    } else {
      swiper.navigation.prevEl[0].classList.remove("disabled");
      swiper.navigation.nextEl[0].classList.remove("disabled");
    }
  };

  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.take;
    if (targetEvent.value) {
      setGridSetting({ ...gridSetting, pageSizeValue: targetEvent.value });
    }
    setGridSetting((prevState) => ({
      ...prevState,
      page: {
        ...prevState.page,
        skip: e.skip,
        take
      }
    }));
  };

  const handleSort = (e) => {
    setGridSetting({
      ...gridSetting,
      sort: e.sort
    });
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setSortParam(`&sort=${obj}`);
    } else {
      setSortParam("");
    }
  };

  const handleGridFilter = (e) => {
    setGridSetting({
      ...gridSetting,
      filter: e.dataState.filter
    });
    if (e.dataState.filter !== undefined) {
      setFilters({
        ...filters,
        grid: e.dataState.filter.filters.map((item) => {
          let obj = { ...item };
          obj["condition"] = obj["operator"];
          delete obj["operator"];
          obj = JSON.stringify(obj);
          return obj;
        })
      });
      setGridSetting((prevState) => ({
        ...prevState,
        page: { ...prevState.page, skip: 0 }
      }));
    } else {
      setFilters({ ...filters, grid: "" });
    }
  };

  const handleChangeCar = (car) => {
    setSelectedCar(car);
    setGridSetting({ ...gridSetting, page: { ...gridSetting.page, skip: 0 } });
  };

  const handleSelectAll = () => {
    if (selectedLeads.length > 0 || filters.unselected.length > 0) {
      setSelectedLeads([]);
      setFilters({ ...filters, unselected: [] });
    } else {
      setSelectedLeads([""]);
    }
  };

  const handleSelect = (dataItem) => {
    // check if all are selected
    if (selectedLeads.length === 0) {
      if (filters.unselected.includes(dataItem.leadId)) {
        // add to unselected arr if current dataItem is already selected
        setFilters({
          ...filters,
          unselected: filters.unselected.filter(
            (item) => item !== dataItem.leadId
          )
        });
      } else {
        // remove from unselected arr if current dataItem is not selected
        setFilters({
          ...filters,
          unselected: filters.unselected.concat(dataItem.leadId)
        });
      }
    } else {
      // conditions if all is not selected
      // check if dataItem is already selected (on selected arr)
      if (selectedLeads.includes(dataItem.leadId)) {
        // check first if the selected array will be empty if dataItem is removed
        // array should not be empty since empty array means all are selected
        const holderArr = selectedLeads.filter(
          (item) => item !== dataItem.leadId
        );
        // assign array with empty string if it will be empty
        setSelectedLeads(holderArr.length > 0 ? holderArr : [""]);
      } else {
        // if selected arr first element is an empty string,
        // set dataItem as the first element
        // if not, add dataItem to the array
        setSelectedLeads(
          !selectedLeads[0]
            ? [dataItem.leadId]
            : selectedLeads.concat(dataItem.leadId)
        );
      }
    }
  };

  const handleRowClick = (dataItem) => {
    // save filter settings
    setPortfolioFilters({
      sort: gridSetting.sort,
      page: gridSetting.page,
      filter: gridSetting.filter,
      filterState: filters,
      selected: selectedLeads,
      car: selectedCar
    });
    // if status = record, return ivid
    // if status != record, return leadId
    Navigate(
      dataItem.statusName === "Record"
        ? `/customer-record/${dataItem.ivId}`
        : `/customer-record/${dataItem.leadId}`,
      {
        state: { prevPage: "portfolio" }
      }
    );
  };

  const statusCell = ({ dataItem }) => {
    return (
      <td onClick={() => handleRowClick(dataItem)}>
        <span className={getStatusClass(dataItem.statusName)}>
          {getTranslation(dataItem.statusName, t)}
        </span>
      </td>
    );
  };

  const selectAllCell = ({ dataItem }) => {
    return (
      <div
        onClick={handleSelectAll}
        className="d-flex align-items-center justify-content-center gap-2"
      >
        <Checkbox
          value={selectedLeads.length === 0 && filters.unselected.length === 0}
        />
        <span className="k-cell-inner">
          <span className="k-column-title">
            {getTranslation("Select All", t)}
          </span>
        </span>
      </div>
    );
  };

  const checkboxCell = ({ dataItem }) => {
    return (
      <td onClick={() => handleSelect(dataItem)}>
        <Checkbox
          disabled={dataItem.statusName === "Record"}
          value={
            dataItem.statusName === "Record"
              ? false
              : (selectedLeads.length === 0 &&
                  filters.unselected.length === 0) ||
                (selectedLeads.length === 0 &&
                  !filters.unselected.includes(dataItem.leadId)) ||
                selectedLeads.includes(dataItem.leadId)
          }
        />
      </td>
    );
  };

  const customPager = (props) => {
    return (
      <div className="custom-pager">
        <img
          onClick={() => setShowColumnHider(true)}
          src={getIcon("settings.png")}
          alt="column hider icon"
        />
        <Pager
          skip={props.skip}
          take={props.take}
          type="numeric"
          total={props.total}
          buttonCount={props.buttonCount}
          previousNext={true}
          onPageChange={pageChange}
          pageSizes={props.pageSizes}
          pageSizeValue={props.pageSizeValue}
        />
      </div>
    );
  };

  const rowRender = (trElement, props) => {
    const clickable = props.dataItem.statusName !== "Record";
    const trProps = {
      className: clickable ? "" : " k-disabled"
    };
    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  return (
    <div className="portfolio">
      <h1 className="h1 mb-4">{getTranslation("Portfolio", t)}</h1>
      <div className="swiper-cont module-cont mb-3">
        {carDistribution.length ? (
          <Swiper
            modules={[Navigation, Pagination]}
            navigation={{
              nextEl: ".slider-btn-next",
              prevEl: ".slider-btn-prev"
            }}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true, type: "bullets" }}
            onInit={(swiper) =>
              swiper.navigation.prevEl[0].classList.add("disabled")
            }
            // onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={(swiper) => handleSwiperButtons(swiper)}
          >
            {carDistribution.map((current, idx, arr) => {
              return (
                <SwiperSlide key={idx}>
                  {(idx !== carDistribution.length
                    ? carData.slice(current, arr[idx + 1])
                    : carData.slice(current)
                  ).map((car, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => handleChangeCar(car)}
                        className="text-center"
                      >
                        <span
                          className={
                            JSON.stringify(selectedCar) === JSON.stringify(car)
                              ? "selected mb-2"
                              : "mb-2"
                          }
                        >
                          <img
                            draggable={false}
                            src={car.imageUrl ?? fetchImg("placeholder.png")}
                            onError={(e) => {
                              e.target.src = fetchImg("placeholder.png");
                            }}
                            alt={"icon"}
                            className={car.isBrand ? "" : "vehicle"}
                          />
                        </span>
                        <p className="m-0 p-0">{car.brandOrModel}</p>
                        <p className="m-0 p-0">{car.count}</p>
                      </div>
                    );
                  })}
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <div className="text-center">
            <span>
              <img className="no-result-img" src={noFound} alt="noFound" />
              <p className="no-result">Sorry! No result found</p>
              <p className="no-result-data">
                The data is not currently available. Please try again at any
                time later.
              </p>
              <img className="ellipse-img" src={ellipse} alt="ellipse" />
            </span>
          </div>
        )}
        {carDistribution.length ? (
          <>
            <SlideButton mode="prev" />
            <SlideButton mode="next" />
          </>
        ) : null}
      </div>
      <div className="module-cont">
        <div className="header mb-2 d-flex gap-4 align-items-center">
          {validateUserPermission(
            currentUser.permissions,
            "canEdit",
            "Campaigns"
          ) && (
            <button
              disabled={selectedLeads.includes("")}
              onClick={() =>
                setShowDialog({ ...showDialog, bulkActions: true })
              }
              className="btn primary-btn"
            >
              {getTranslation("Bulk actions", t)}
            </button>
          )}
          <button
            onClick={() => setShowDialog({ ...showDialog, filter: true })}
            className="primary-btn"
          >
            <img src={getIcon("filter.svg")} alt="filter icon" />
          </button>
        </div>
        <Grid
          data={portfolioList.map((item) => ({
            ...item,
            financeType: getTranslation(item.financeType, t),
            lastActivity: item.lastActivity ? new Date(item.lastActivity) : null
          }))}
          skip={gridSetting.page.skip}
          take={gridSetting.page.take}
          filter={gridSetting.filter}
          sortable={true}
          sort={gridSetting.sort}
          onSortChange={handleSort}
          total={portfolioListPage.totalRecords}
          filterable={true}
          pager={customPager}
          onDataStateChange={handleGridFilter}
          pageable={{
            buttonCount:
              portfolioListPage.totalPages > 10
                ? 10
                : portfolioListPage.totalPages,
            pageSizes: [5, 10, 15],
            pageSizeValue: gridSetting.pageSizeValue
          }}
          onRowClick={({ dataItem }) => handleRowClick(dataItem)}
          rowRender={rowRender}
        >
          {columns.map((column, idx) => {
            if (idx === 0) {
              return validateUserPermission(
                currentUser.permissions,
                "canEdit",
                "Campaigns"
              ) ? (
                <Column
                  key={idx}
                  filterable={false}
                  width={column.width}
                  cell={checkboxCell}
                  headerCell={selectAllCell}
                />
              ) : null;
            } else {
              return column.isShown ? (
                <Column
                  key={idx}
                  field={column.column}
                  width={column.width}
                  title={getTranslation(column.title, t)}
                  cell={column.column === "statusName" ? statusCell : undefined}
                  filterable={column.filter !== "numeric"}
                  filter={column.filter}
                  filterCell={column.filter === "date" ? DateFilterCell : undefined}
                  format={
                    column.filter === "date" ? "{0:dd/MM/yyyy}" : undefined
                  }
                />
              ) : null;
            }
          })}
        </Grid>
      </div>
      {showDialog.bulkActions && (
        <BulkActions
          closeDialog={() =>
            setShowDialog({ ...showDialog, bulkActions: false })
          }
          setLoading={setLoading}
          queryBuilder={queryBuilder}
          getPortfolioList={getPortfolioList}
          selectedCar={selectedCar}
          setSelectedLeads={setSelectedLeads}
          setFilters={setFilters}
          leadIds={selectedLeads}
          filters={filters}
          getModalFilters={getModalFilters}
        />
      )}
      {showDialog.filter && (
        <FilterModal
          closeDialog={() => setShowDialog({ ...showDialog, filter: false })}
          filters={filters}
          setFilters={setFilters}
          setGridSetting={setGridSetting}
          gridSetting={gridSetting}
        />
      )}
      {showColumnHider && (
        <ColumnHider
          closeHider={() => setShowColumnHider(false)}
          columns={columns}
          setColumns={setColumns}
        />
      )}
    </div>
  );
};

export default Portfolio;
