const sampleConversionData = [
  {
    brand: "BMW",
    numCustomer: "200",
    numCampaigns: "2",
    leads: "266",
    percLeads: "26%",
    delivered: "26",
    percDelivered: "14.5%",
  },
  {
    brand: "BMW",
    numCustomer: "200",
    numCampaigns: "2",
    leads: "266",
    percLeads: "26%",
    delivered: "26",
    percDelivered: "14.5%",
  },
  {
    brand: "BMW",
    numCustomer: "200",
    numCampaigns: "2",
    leads: "266",
    percLeads: "26%",
    delivered: "26",
    percDelivered: "14.5%",
  },
  {
    brand: "BMW",
    numCustomer: "200",
    numCampaigns: "2",
    leads: "266",
    percLeads: "26%",
    delivered: "26",
    percDelivered: "14.5%",
  },
  {
    brand: "BMW",
    numCustomer: "200",
    numCampaigns: "2",
    leads: "266",
    percLeads: "26%",
    delivered: "26",
    percDelivered: "14.5%",
  },
  {
    brand: "BMW",
    numCustomer: "200",
    numCampaigns: "2",
    leads: "266",
    percLeads: "26%",
    delivered: "26",
    percDelivered: "14.5%",
  },
  {
    brand: "BMW",
    numCustomer: "200",
    numCampaigns: "2",
    leads: "266",
    percLeads: "26%",
    delivered: "26",
    percDelivered: "14.5%",
  },
  {
    brand: "BMW",
    numCustomer: "200",
    numCampaigns: "2",
    leads: "266",
    percLeads: "26%",
    delivered: "26",
    percDelivered: "14.5%",
  },
  {
    brand: "BMW",
    numCustomer: "200",
    numCampaigns: "2",
    leads: "266",
    percLeads: "26%",
    delivered: "26",
    percDelivered: "14.5%",
  },
  {
    brand: "BMW",
    numCustomer: "200",
    numCampaigns: "2",
    leads: "266",
    percLeads: "26%",
    delivered: "26",
    percDelivered: "14.5%",
  },
  {
    brand: "BMW",
    numCustomer: "200",
    numCampaigns: "2",
    leads: "266",
    percLeads: "26%",
    delivered: "26",
    percDelivered: "14.5%",
  },
  {
    brand: "BMW",
    numCustomer: "200",
    numCampaigns: "2",
    leads: "266",
    percLeads: "26%",
    delivered: "26",
    percDelivered: "14.5%",
  },
  {
    brand: "BMW",
    numCustomer: "200",
    numCampaigns: "2",
    leads: "266",
    percLeads: "26%",
    delivered: "26",
    percDelivered: "14.5%",
  },
];

export default sampleConversionData;
