/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useNavigate } from "react-router-dom";
import { getIcon } from "../../utils/iconUtils";
import { Checkbox } from "@progress/kendo-react-inputs";
import "../../styles/campaigns/campaigns.css";
import { campaignListColumns } from "./gridColumns";
import { useEffect, useState } from "react";
import { Pager } from "@progress/kendo-react-data-tools";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import ColumnHider from "../shared/ColumnHider";
import DuplicateCampaignModal from "./DuplicateCampaignModal";
import { format } from "date-fns";
import { validateUserPermission } from "../../utils/helpers";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import DeleteCampaignModal from "./DeleteCampaignModal";
import { DateFilterCell } from "../shared/DateFilterCell";

const Campaigns = ({
  getCampaignList,
  campaignList,
  campaignListPage,
  currentUser,
  setSuccessModalValues,
  setLoading
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const [campaignId, setCampaignId] = useState(null);
  const [columns, setColumns] = useState(campaignListColumns);
  const [currentView, setCurrentView] = useState("grid");
  const [showColumnHider, setShowColumnHider] = useState(false);
  const [showDuplicateCampaignModal, setShowDuplicateCampaignModal] =
    useState(false);
  const [showDeleteCamapaignModal, setShowDeleteCampaignModal] =
    useState(false);
  const [page, setPage] = useState({
    skip: 0,
    take: 10
  });
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState(undefined);
  const [params, setParams] = useState({
    sort: "",
    filter: "",
    search: "",
    expired: false
  });

  useEffect(() => {
    getCampaignList(queryBuilder());
  }, [page, params]);

  const queryBuilder = () => {
    const filter = `&filters=[${params.filter.concat(params.search)}]`;

    return `?showExpired=${params.expired}${filter}${
      params.sort
    }&page=${JSON.stringify(page)}`;
  };

  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...e.page,
      skip: e.skip,
      take
    });
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      setParams({
        ...params,
        search: JSON.stringify({
          field: "campaignName",
          value: e.target.value,
          condition: "contains"
        })
      });
      setPage({ ...page, skip: 0 });
    } else {
      setParams({
        ...params,
        search: ""
      });
    }
  };

  const handleSort = (e) => {
    setSort(e.sort);
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `&sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.dataState.filter);
    if (e.dataState.filter !== undefined) {
      setParams({
        ...params,
        filter: e.dataState.filter.filters.map((item) => {
          let obj = { ...item };
          obj["condition"] = obj["operator"];
          delete obj["operator"];
          obj = JSON.stringify(obj);
          return obj;
        })
      });
      setPage({
        ...page,
        skip: 0
      });
    } else {
      setParams({ ...params, filter: "" });
    }
  };

  const handleDuplicate = (id) => {
    setShowDuplicateCampaignModal(true);
    setCampaignId(id);
  };

  const handleDelete = (id) => {
    setShowDeleteCampaignModal(true);
    setCampaignId(id);
  };

  const campaignActions = ({ dataItem }) => {
    const campaignId = dataItem.campaignId;
    const campaignType = dataItem.campaignType;

    return (
      <td className="text-center">
        {campaignType === 1 ? (
          <>
            {validateUserPermission(
              currentUser.permissions,
              "canView",
              "Campaigns"
            ) && (
              <img
                className="px-1"
                src={getIcon("folder.svg")}
                alt="leads list icon"
                onClick={() =>
                  Navigate(`/campaigns/${campaignId}/leads`, {
                    state: { prevPage: "campaigns" }
                  })
                }
              />
            )}
            {validateUserPermission(
              currentUser.permissions,
              "canCreate",
              "Campaigns"
            ) && (
              <img
                className="px-1"
                src={getIcon("copy.svg")}
                alt="duplicate campaign icon"
                onClick={() => handleDuplicate(campaignId)}
              />
            )}
            {validateUserPermission(
              currentUser.permissions,
              "canDelete",
              "Campaigns"
            ) && (
              <img
                className="px-1"
                src={getIcon("trash.svg")}
                alt="delete campaign icon"
                onClick={() => handleDelete(campaignId)}
              />
            )}
          </>
        ) : (
          <>
            <img
              className="px-1"
              src={getIcon("folder.svg")}
              alt="leads list icon"
              onClick={() => Navigate(`/campaigns/${campaignId}/leads`)}
            />
          </>
        )}
      </td>
    );
  };

  const customPager = (props) => {
    return (
      <div className="custom-pager">
        <div className="imgs">
          <span className={currentView === "grid" ? "selected" : ""}>
            <img
              className={currentView === "grid" ? "selected" : ""}
              onClick={() => setCurrentView("grid")}
              src={getIcon("column.svg")}
              alt="campaign grid icon"
            />
          </span>
          <span className={currentView === "alt" ? "selected" : ""}>
            <img
              className={currentView === "alt" ? "selected" : ""}
              onClick={() => setCurrentView("alt")}
              src={getIcon("altColumn.svg")}
              alt="campaign alt view icon"
            />
          </span>
          <img
            onClick={() => setShowColumnHider(true)}
            src={getIcon("settings.png")}
            alt="column hider icon"
          />
        </div>
        <Pager
          skip={props.skip}
          take={props.take}
          type="numeric"
          total={props.total}
          buttonCount={props.buttonCount}
          previousNext={true}
          pageSizes={props.pageSizes}
          pageSizeValue={props.pageSizeValue}
          onPageChange={pageChange}
        />
      </div>
    );
  };

  const sampleAltViewCell = ({ dataItem }) => {
    const campaignType = dataItem.campaignType;
    return (
      <td>
        <div className="d-flex align-items-center altview-label-wrap ">
          <div className="d-flex ">
            <span
              className="altview-campaign-title cursor-pointer"
              onClick={() =>
                Navigate(`/campaigns/${dataItem.campaignId}/leads`, {
                  state: { prevPage: "campaigns" }
                })
              }
            >
              {campaignType === 1 ? (
                <span>{dataItem.campaignName}</span>
              ) : (
                <strong>{dataItem.campaignName}</strong>
              )}
            </span>
            <div className="altview-action-wrap">
              {campaignType === 1 ? (
                <>
                  {validateUserPermission(
                    currentUser.permissions,
                    "canView",
                    "Campaigns"
                  ) && (
                    <img
                      className="px-1"
                      src={getIcon("folder.svg")}
                      alt="leads list icon"
                      onClick={() =>
                        Navigate(`/campaigns/${dataItem.campaignId}/leads`, {
                          state: { prevPage: "campaigns" }
                        })
                      }
                    />
                  )}
                  {validateUserPermission(
                    currentUser.permissions,
                    "canCreate",
                    "Campaigns"
                  ) && (
                    <img
                      className="px-1"
                      src={getIcon("copy.svg")}
                      alt="duplicate campaign icon"
                      onClick={() => handleDuplicate(campaignId)}
                    />
                  )}
                  {validateUserPermission(
                    currentUser.permissions,
                    "canDelete",
                    "Campaigns"
                  ) && (
                    <img
                      className="px-1"
                      src={getIcon("trash.svg")}
                      alt="delete campaign icon"
                      onClick={() => handleDelete(campaignId)}
                    />
                  )}
                </>
              ) : (
                <>
                  <img
                    className="px-1"
                    src={getIcon("folder.svg")}
                    alt="leads list icon"
                    onClick={() =>
                      Navigate(`/campaigns/${dataItem.campaignId}/leads`, {
                        state: { prevPage: "campaigns" }
                      })
                    }
                  />
                </>
              )}
            </div>
          </div>
          <div>
            {campaignType === 1 && (
              <span
                onClick={() =>
                  Navigate(`/campaigns/${dataItem.campaignId}/leads`, {
                    state: { prevPage: "campaigns" }
                  })
                }
              >
                <strong>{getTranslation("From", t)}: </strong>
                {format(new Date(dataItem.validFrom), "dd/MM/yyyy")}
              </span>
            )}
          </div>
          <div>
            {campaignType === 1 && (
              <span
                onClick={() =>
                  Navigate(`/campaigns/${dataItem.campaignId}/leads`, {
                    state: { prevPage: "campaigns" }
                  })
                }
              >
                <strong>{getTranslation("To", t)}: </strong>
                {format(new Date(dataItem.validTo), "dd/MM/yyyy")}
              </span>
            )}
          </div>
          <div>
            <span className="alt-view-percent">{dataItem.totalPercent}%</span>
          </div>
        </div>
        <ProgressBar value={dataItem.totalPercent} />
      </td>
    );
  };

  const customCampaignName = ({ dataItem }) => {
    const campaignType = dataItem.campaignType;
    return (
      <td>
        <span
          onClick={() =>
            Navigate(`/campaigns/${dataItem.campaignId}/leads`, {
              state: { prevPage: "campaigns" }
            })
          }
          className={
            campaignType === 1 ? "campaign-name-normal" : "campaign-name-bold"
          }
        >
          {dataItem.campaignName}
        </span>
      </td>
    );
  };

  return (
    <div className="campaigns">
      <p className="h1 mb-4">{getTranslation("Campaigns", t)}</p>
      <div className="module-cont">
        <div
          className={`header d-flex ${
            currentView === "grid" ? "grid-view" : "alt-view"
          }`}
        >
          <div className="search d-flex gap-4">
            <div className="d-flex flex-column">
              <label>{getTranslation("Search", t)}</label>
              <input
                name="search"
                onChange={handleSearch}
                className="primary-input"
                type="text"
              />
            </div>
            <button className="primary-btn">
              <img src={getIcon("searchInput.png")} alt="search icon" />
            </button>
          </div>

          {validateUserPermission(
            currentUser.permissions,
            "canCreate",
            "Campaigns"
          ) && (
            <button
              onClick={() => Navigate(`/campaigns/new`)}
              currentUser={currentUser}
              className="offset-1 d-flex justify-content-center align-items-center gap-2 primary-btn"
            >
              <img src={getIcon("plus.svg")} alt="add icon" />
              <p className="m-0">{getTranslation("New campaign", t)}</p>
            </button>
          )}
          <div className="offset-1 d-flex gap-2 align-items-end">
            <Checkbox
              value={params.expired}
              onChange={(e) => {
                setParams({ ...params, expired: !params.expired });
              }}
            />
            <label>{getTranslation("Show expired campaigns", t)}</label>
          </div>
        </div>
        <div
          className={`grid-cont ${
            currentView === "grid" ? "grid-view" : "alt-view"
          }`}
        >
          <Grid
            data={campaignList.map((item) => ({
              ...item,
              updatedDate: item.updatedDate ? new Date(item.updatedDate) : null,
              validTo: item.validTo ? new Date(item.validTo) : null,
              validFrom: item.validFrom ? new Date(item.validFrom) : null
            }))}
            skip={page.skip}
            take={page.take}
            total={campaignListPage.totalRecords}
            filterable={true}
            filter={filter}
            sortable={true}
            sort={sort}
            onSortChange={handleSort}
            onDataStateChange={handleFilterChange}
            pager={customPager}
            onRowClick={(e) =>
              Navigate(`/campaigns/${e.dataItem.campaignId}/leads`, {
                state: { prevPage: "campaigns" }
              })
            }
            pageable={{
              buttonCount:
                campaignListPage.totalPages > 10
                  ? 10
                  : campaignListPage.totalPages,
              pageSizes: [5, 10, 15],
              pageSizeValue
            }}
          >
            {currentView === "grid" ? (
              columns.map((column, idx) => {
                if (idx === 0) {
                  return (
                    <Column
                      key={idx}
                      width={column.width}
                      cell={campaignActions}
                      filterable={false}
                      title={column.title}
                    />
                  );
                } else {
                  return column.isShown ? (
                    <Column
                      key={idx}
                      field={column.column}
                      cell={
                        column.column === "campaignName"
                          ? customCampaignName
                          : undefined
                      }
                      width={column.width}
                      title={getTranslation(column.title, t)}
                      filterable={column.filter !== "numeric"}
                      filter={column.filter}
                      filterCell={column.filter === "date" ? DateFilterCell : undefined}
                      format={
                        column.filter === "date" ? "{0:dd/MM/yyyy}" : undefined
                      }
                    />
                  ) : null;
                }
              })
            ) : (
              <Column filterable={false} cell={sampleAltViewCell} />
            )}
          </Grid>
        </div>
      </div>
      {showColumnHider && (
        <ColumnHider
          closeHider={() => setShowColumnHider(false)}
          columns={columns}
          setColumns={setColumns}
        />
      )}
      {showDuplicateCampaignModal && (
        <DuplicateCampaignModal
          setShowDuplicateCampaignModal={setShowDuplicateCampaignModal}
          campaignId={campaignId}
          setSuccessModalValues={setSuccessModalValues}
          getCampaignList={getCampaignList}
          queryBuilder={queryBuilder}
        />
      )}
      {showDeleteCamapaignModal && (
        <DeleteCampaignModal
          setShowDeleteCampaignModal={setShowDeleteCampaignModal}
          campaignId={campaignId}
          setSuccessModalValues={setSuccessModalValues}
          getCampaignList={getCampaignList}
          queryBuilder={queryBuilder}
        />
      )}
    </div>
  );
};

export default Campaigns;
