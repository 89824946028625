import { getIcon } from "../../utils/iconUtils";

const SlideNextButton = ({ mode }) => {
  return mode === "next" ? (
    <img
      className="slider-btn-prev"
      src={getIcon("caret-arrow-left.svg")}
      alt="previous car icon"
    />
  ) : (
    <img
      className="slider-btn-next"
      src={getIcon("caret-arrow-right.svg")}
      alt="next car icon"
    />
  );
};

export default SlideNextButton;
