import React from "react";
import "../../styles/shared/modal.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useNavigate } from "react-router-dom";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const SavingModal = ({
  message,
  messageTwo,
  onclickHandleNo,
  onclickHandleYes,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog>
        <div className="saving-modal">
          <span>{getTranslation(message, t)}</span>
          <div className="modal-btn">
            <button className="primary-btn" onClick={onclickHandleNo}>
              {getTranslation("No", t)}
            </button>
            <button className="secondary-btn" onClick={onclickHandleYes}>
              {getTranslation("Yes", t)}
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SavingModal;
