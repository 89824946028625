import React, { useEffect, useState } from "react";
import "../../styles/serviceAlerts/serviceAlerts.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TextArea } from "@progress/kendo-react-inputs";
import { getIcon } from "../../utils/iconUtils";
import ServiceAlertsTable from "./ServiceAlertsTable";
import fetchClient from "../../api/fetch";
import { useLocation, useNavigate } from "react-router-dom";
import { validateUserPermission } from "../../utils/helpers";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const ServiceAlerts = ({
  getServiceAlertsList,
  serviceAlertsList,
  serviceAlertsListPage,
  setSuccessModalValues,
  setLoadingCount,
  setLoading,
  marketSettings,
  serviceAlertsFilters,
  setServiceAlertsFilters,
  currentUser
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const location = useLocation();
  const searchBy = [
    {
      searchTypeName: getTranslation("VIN Number", t),
      searchType: 1
    },
    {
      searchTypeName: getTranslation("License Plate", t),
      searchType: 2
    }
  ];

  //useStates
  const [showData, setShowData] = useState(false);
  const [alertsModal, setAlertsModal] = useState(false);
  const [searchTypeValue, setSearchTypeValue] = useState({
    value: {
      searchTypeName: getTranslation("VIN Number", t),
      searchType: 1
    }
  });
  const [inputValue, setInputValue] = useState([]);
  const [referenceKey, setReferenceKey] = useState("");
  const [payload, setPayload] = useState({
    searchType: 1,
    items: []
  });
  const [params, setParams] = useState({
    sort: "",
    filter: ""
  });

  const [gridSetting, setGridSetting] = useState({
    sort: [],
    filter: undefined,
    pageSizeValue: 10,
    page: {
      skip: 0,
      take: 10
    }
  });
  const [selectedIvIds, setSelectedIvIds] = useState([""]);

  useEffect(() => {
    if (referenceKey) {
      getServiceAlertsList(queryBuilder());
    }
  }, [params, gridSetting.page]);

  useEffect(() => {
    if (
      location.state?.prevPage === "customer-record" &&
      Object.keys(serviceAlertsFilters).length > 0
    ) {
      setReferenceKey(serviceAlertsFilters.referenceKey);
      setSelectedIvIds(serviceAlertsFilters.selectedIvIds);
      setGridSetting({
        ...gridSetting,
        page: serviceAlertsFilters.page,
        sort: serviceAlertsFilters.sort,
        filter: serviceAlertsFilters.filter
      });
      setShowData(serviceAlertsFilters.showData);
      setInputValue(serviceAlertsFilters.inputValue);
      setPayload({ ...payload, searchType: serviceAlertsFilters.searchType });
    }
    setServiceAlertsFilters({});
  }, []);

  const handleRowClick = ({ dataItem }) => {
    setServiceAlertsFilters({
      sort: gridSetting.sort,
      page: gridSetting.page,
      filter: gridSetting.filter,
      selectedIvIds: selectedIvIds,
      inputValue: inputValue,
      payload: payload.searchType,
      referenceKey: referenceKey,
      showData: showData
    });
    if (validateUserPermission(currentUser.permissions, "canView", "Leads"))
      Navigate(
        dataItem.leadId !== null
          ? `/customer-record/${dataItem.leadId}`
          : `/customer-record/${dataItem.ivId}`,
        {
          state: { prevPage: "service-alerts" }
        }
      );
  };

  const handlePreviewClick = () => {
    setLoading(true);
    const items = inputValue
      .split(/(\s+)/)
      .map((item) => item.replace(/\s/g, ""))
      .filter((item) => item.trim());

    fetchClient()
      .post("/v1/servicealerts/find", {
        ...payload,
        items
      })
      .then((res) => {
        setReferenceKey(res.data.data.referenceKey);
        getServiceAlertsList(`?referenceKey=${res.data.data.referenceKey}`);

        setShowData(true);
        setSelectedIvIds([""]);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setLoadingCount(1);
      });
  };

  const dropdownHandleChange = (e) => {
    setSearchTypeValue({ value: e.target.value });
    setPayload({ ...payload, searchType: e.target.value.searchType });
    setInputValue("");
    setShowData(false);
  };

  const inputHandleChange = (e) => {
    setInputValue(e.target.value);
  };

  const queryBuilder = () => {
    const refKey = `referenceKey=${referenceKey}`;
    const filter = `filters=[${params.filter}]`;

    return `?${refKey}&page=${JSON.stringify(gridSetting.page)}&${filter}&${
      params.sort
    }`;
  };

  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.take;
    if (targetEvent.value) {
      setGridSetting({ ...gridSetting, pageSizeValue: targetEvent.value });
    }
    setGridSetting((prevState) => ({
      ...prevState,
      page: {
        ...prevState.page,
        skip: e.skip,
        take
      }
    }));
  };

  const handleSort = (e) => {
    setGridSetting({
      ...gridSetting,
      sort: e.sort
    });
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `&sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  const handleFilterChange = (e) => {
    setGridSetting({ ...gridSetting, filter: e.dataState.filter });
    if (e.dataState.filter !== undefined) {
      setParams({
        ...params,
        filter: e.dataState.filter.filters.map((item) => {
          let obj = { ...item };
          obj["condition"] = obj["operator"];
          delete obj["operator"];
          obj = JSON.stringify(obj);
          return obj;
        })
      });
      setGridSetting((prevState) => ({
        ...prevState,
        page: { ...prevState.page, skip: 0 }
      }));
    } else {
      setParams({ ...params, filter: "" });
    }
  };

  const createAftersalesLeads = () => {
    fetchClient()
      .post(`/v1/servicealerts`, { referenceKey, ivIds: selectedIvIds })
      .then((res) => {
        setSuccessModalValues({
          message: "Aftersales leads made successfully",
          isVisible: true
        });
        setTimeout(() => {
          setSuccessModalValues({ message: "", isVisible: false });
          setAlertsModal(false);
          setShowData(false);
          setInputValue("");
        }, 2000);
        setSelectedIvIds([""]);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="service-alerts-container">
      <div className="d-flex gap-2 align-items-center mb-3">
        <p className="h1">{getTranslation("Service Alerts", t)}</p>
      </div>
      <div className="service-alerts-contents">
        <div className="service-alerts-box d-flex mx-0 justify-content-between">
          <div className="d-flex flex-column justify-content-between service-alerts-components">
            <div>
              <p>{getTranslation("Search by", t)}</p>
              <DropDownList
                data={searchBy}
                name="searchTypeName"
                textField="searchTypeName"
                dataItemKey="searchType"
                value={searchTypeValue.value}
                onChange={dropdownHandleChange}
              />
            </div>
            <TextArea
              rows={5}
              value={inputValue}
              onChange={inputHandleChange}
            />
          </div>
          <div className="align-self-end">
            <button
              className="primary-btn"
              onClick={handlePreviewClick}
              disabled={
                searchTypeValue.value.searchType === 1
                  ? inputValue.length < 16
                  : inputValue.length < 5
              }
            >
              <img src={getIcon("searchInput.png")} alt="search icon" />
              <span>{getTranslation("Preview", t)}</span>
            </button>
          </div>
        </div>
      </div>
      {showData && (
        <ServiceAlertsTable
          data={serviceAlertsList}
          serviceAlertsListPage={serviceAlertsListPage}
          gridSetting={gridSetting}
          pageChange={pageChange}
          handleSort={handleSort}
          handleFilterChange={handleFilterChange}
          createAftersalesLeads={createAftersalesLeads}
          alertsModal={alertsModal}
          selectedIvIds={selectedIvIds}
          setSelectedIvIds={setSelectedIvIds}
          marketSettings={marketSettings}
          handleRowClick={handleRowClick}
          setServiceAlertsFilters={setServiceAlertsFilters}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};

export default ServiceAlerts;
