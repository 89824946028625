import {
  SET_CAMPAIGN_LIST,
  SET_LOADING,
  SET_AUTH,
  SET_USERS_LIST,
  SET_USERS_LIST_PAGE,
  SET_CAMPAIGN_LIST_PAGE,
  SET_CAMPAIGN_LEADS_LIST_PAGE,
  SET_CAMPAIGN_LEADS_LIST,
  SET_MARKET_SETTINGS,
  SET_LEADS_LIST,
  SET_LEADS_LIST_PAGE,
  SET_LEADS_COUNT,
  SET_LEADS_REFERENCE_KEY,
  SET_HEADER_USER_MARKETS,
  SET_USER_BRANCHES,
  SET_PORTFOLIO_LIST,
  SET_PORTFOLIO_LIST_PAGE,
  SET_PORTFOLIO_FILTERS,
  SET_LOADING_COUNT,
  SET_DASHBOARD_LOAD_TYPE,
  SET_SERVICE_ALERTS_LIST,
  SET_SERVICE_ALERTS_LIST_PAGE,
  SET_SERVICE_ALERTS_FILTERS,
  SET_SEARCH_MODULE_LIST,
  SET_SEARCH_MODULE_LIST_PAGE,
  SET_SEARCH_MODULE_PARAMS,
  SET_SUCCESS_MODAL_VALUES,
  SET_REMOVE_OPPORTUNITY_DATA,
  SET_CUSTOMER_RECORD_DATA,
  SET_SPECIALISTS,
  SET_CLIENT_POSITION_DATA,
  SET_FINANCE_RECORD_DATA,
  SET_ACTIVITY_RECORD,
  UPDATE_ACTIVITY_RECORD,
  SET_REPORTS_USAGE_GRID_LIST,
  SET_REPORTS_USAGE_GRID_LIST_PAGE,
  SET_REPORTS_CONVERSION_GRID_LIST,
  SET_REPORTS_CONVERSION_GRID_LIST_PAGE,
  SET_VEHICLES_LIST,
  SET_VEHICLES_LIST_PAGE,
  SET_REMOVE_VEHICLE,
  SET_VEHICLE,
  SET_LANGUAGES,
  SET_VEHICLE_LANGUAGE_ID,
  SET_IMAGE_UPLOAD_ERROR,
} from "../actions/types";

const initialState = {
  isLoading: false,
  loadingCount: 0,
  dashboardType: null,
  campaignList: [],
  campaignListPage: {
    totalRecords: 0,
    totalPages: 0,
  },
  campaignLeadsList: [],
  campaignLeadsListPage: {
    totalRecords: 0,
    totalPages: 0,
  },
  vehiclesList: [],
  vehiclesListPage: {
    totalRecords: 0,
    totalPages: 0,
  },
  languages: [],
  vehicleLanguageId: 0,
  removeVehicle: null,
  vehicleData: {},
  usersList: [],
  usersListPage: {
    totalRecords: 0,
    totalPages: 0,
  },
  marketSettings: {},
  leadsCount: 0,
  leadsReferenceKey: "",
  leadsList: [],
  removeOpportunityData: {},
  customerRecordData: {},
  clientPositionData: {},
  financeRecordData: {},
  specialists: [],
  portfolioList: [],
  portfolioListPage: {
    totalRecords: 0,
    totalPages: 0,
  },
  portfolioFilters: {},
  leadsListPage: {
    totalRecords: 0,
    totalPages: 0,
  },
  serviceAlertsList: [],
  serviceAlertsListPage: {
    totalRecords: 0,
    totalPages: 0,
  },
  serviceAlertsFilters: {},
  searchModuleList: [],
  searchModuleListPage: {
    totalRecords: 0,
    totalPages: 0,
  },
  searchModuleParams: {
    sort: "",
    filters: [],
    statusFilters: "",
    leadType: "",
    name: "",
    bank: "",
    postalCode: "",
    currentVehicle: "",
    contractId: "",
    phoneNumber: "",
    licensePlate: "",
    vinNumber: "",
  },
  reportsUsageGridList: [],
  reportsUsageGridListPage: {
    totalRecords: 0,
    totalPages: 0,
  },
  reportsConversionGridList: [],
  reportsConversionGridListPage: {
    totalRecords: 0,
    totalPages: 0,
  },
  activityRecord: [],
  updateActivityRecordData: {
    specialistsId: "",
    status: 0,
    duesDate: "",
    description: "",
    specialistName: "",
    statusDescription: "",
  },
  successModalValues: {
    isVisible: false,
    message: "",
  },
  imageUploadError: null,
  headerUserMarkets: [],
  userBranches: [],
  activePage: "Dashboard",
  isAuth: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case SET_CAMPAIGN_LIST:
      return {
        ...state,
        campaignList: action.campaignList,
      };
    case SET_AUTH:
      return {
        ...state,
        isAuth: action.isAuth,
      };
    case SET_USERS_LIST:
      return {
        ...state,
        usersList: action.usersList,
      };
    case SET_USERS_LIST_PAGE:
      return {
        ...state,
        usersListPage: action.usersListPage,
      };
    case SET_CAMPAIGN_LIST_PAGE:
      return {
        ...state,
        campaignListPage: action.campaignListPage,
      };
    case SET_CAMPAIGN_LEADS_LIST:
      return {
        ...state,
        campaignLeadsList: action.campaignLeadsList,
      };
    case SET_CAMPAIGN_LEADS_LIST_PAGE:
      return {
        ...state,
        campaignLeadsListPage: action.campaignLeadsListPage,
      };

    case SET_MARKET_SETTINGS:
      return {
        ...state,
        marketSettings: action.marketSettings,
      };
    case SET_LEADS_LIST:
      return {
        ...state,
        leadsList: action.leadsList,
      };
    case SET_LEADS_LIST_PAGE:
      return {
        ...state,
        leadsListPage: action.leadsListPage,
      };
    case SET_LEADS_COUNT:
      return {
        ...state,
        leadsCount: action.leadsCount,
      };
    case SET_LEADS_REFERENCE_KEY:
      return {
        ...state,
        leadsReferenceKey: action.leadsReferenceKey,
      };
    case SET_HEADER_USER_MARKETS:
      return {
        ...state,
        headerUserMarkets: action.headerUserMarkets,
      };
    case SET_USER_BRANCHES:
      return {
        ...state,
        userBranches: action.userBranches,
      };
    case SET_PORTFOLIO_LIST:
      return { ...state, portfolioList: action.portfolioList };
    case SET_PORTFOLIO_LIST_PAGE:
      return { ...state, portfolioListPage: action.portfolioListPage };
    case SET_PORTFOLIO_FILTERS:
      return { ...state, portfolioFilters: action.filters };
    case SET_LOADING_COUNT:
      return { ...state, loadingCount: action.count };
    case SET_DASHBOARD_LOAD_TYPE:
      return { ...state, dashboardType: action.dashboardType };
    case SET_SERVICE_ALERTS_LIST:
      return { ...state, serviceAlertsList: action.serviceAlertsList };
    case SET_SERVICE_ALERTS_LIST_PAGE:
      return { ...state, serviceAlertsListPage: action.serviceAlertsListPage };
    case SET_SERVICE_ALERTS_FILTERS:
      return { ...state, serviceAlertsFilters: action.filters };
    case SET_SEARCH_MODULE_LIST:
      return {
        ...state,
        searchModuleList: action.searchModuleList,
      };
    case SET_SEARCH_MODULE_LIST_PAGE:
      return {
        ...state,
        searchModuleListPage: action.searchModuleListPage,
      };
    case SET_SEARCH_MODULE_PARAMS:
      return {
        ...state,
        searchModuleParams: action.searchModuleParams,
      };
    case SET_SUCCESS_MODAL_VALUES:
      return {
        ...state,
        successModalValues: action.successModalValues,
      };
    case SET_REMOVE_OPPORTUNITY_DATA:
      return {
        ...state,
        removeOpportunityData: action.removeOpportunityData,
      };
    case SET_CUSTOMER_RECORD_DATA:
      return {
        ...state,
        customerRecordData: action.customerRecordData,
      };
    case SET_CLIENT_POSITION_DATA:
      return {
        ...state,
        clientPositionData: action.clientPositionData,
      };
    case SET_FINANCE_RECORD_DATA:
      return {
        ...state,
        financeRecordData: action.financeRecordData,
      };
    case SET_SPECIALISTS:
      return {
        ...state,
        specialists: action.specialists,
      };
    case SET_ACTIVITY_RECORD:
      return {
        ...state,
        activityRecord: action.activityRecord,
      };
    case UPDATE_ACTIVITY_RECORD:
      return {
        ...state,
        updateActivityRecordData: action.updateActivityRecordData,
      };
    case SET_REPORTS_USAGE_GRID_LIST:
      return { ...state, reportsUsageGridList: action.reportsUsageGridList };
    case SET_REPORTS_USAGE_GRID_LIST_PAGE:
      return {
        ...state,
        reportsUsageGridListPage: action.reportsUsageGridListPage,
      };
    case SET_REPORTS_CONVERSION_GRID_LIST:
      return {
        ...state,
        reportsConversionGridList: action.reportsConversionGridList,
      };
    case SET_REPORTS_CONVERSION_GRID_LIST_PAGE:
      return {
        ...state,
        reportsConversionGridListPage: action.reportsConversionGridListPage,
      };
    case SET_VEHICLES_LIST:
      return {
        ...state,
        vehiclesList: action.vehiclesList,
      };
    case SET_VEHICLES_LIST_PAGE:
      return {
        ...state,
        vehiclesListPage: action.vehiclesListPage,
      };
    case SET_VEHICLE:
      return {
        ...state,
        vehicleData: action.vehicleData,
      };
    case SET_REMOVE_VEHICLE:
      return {
        ...state,
        removeVehicle: action.removeVehicle,
      };
    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.languages,
      };
    case SET_VEHICLE_LANGUAGE_ID:
      return {
        ...state,
        vehicleLanguageId: action.vehicleLanguageId,
      };
    case SET_IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        imageUploadError: action.imageUploadError,
      };
    default:
      return state;
  }
};

export default reducer;
