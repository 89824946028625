import React, { Suspense, useEffect } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import "@progress/kendo-theme-default/dist/all.css";
import {
  loadCampaignList,
  loadCampaignLeadsList,
  loadUsersList,
  loadMarketSettings,
  loadLeadsList,
  setAuth,
  setCampaignList,
  setCampaignLeadsList,
  setCurrentUser,
  setLoading,
  loadVehicleSelectorList,
  setLeadsList,
  setLeadsReferenceKey,
  loadLeadsCount,
  setLeadsCount,
  loadHeaderUserMarkets,
  updateUserSettings,
  saveCampaignLeads,
  loadUserBranches,
  loadPortfolioList,
  setPortfolioFilters,
  setDashboardLoadType,
  setLoadingCount,
  loadServiceAlertsList,
  loadSearchModuleList,
  setSearchModuleList,
  setSearchModuleParams,
  setSuccessModalValues,
  exportCampaign,
  removeOpportunity,
  loadCustomerRecordData,
  loadSpecialists,
  setServiceAlertsFilters,
  loadActivityRecord,
  updateActivityRecord,
  loadReportsUsageGridList,
  exportUsageReport,
  loadReportsConversionGridList,
  exportConversionReport,
  loadVehiclesList,
  setVehiclesList,
  saveVehicle,
  deleteVehicle,
  loadVehicleData,
  saveExistingVehicle,
  saveDefaultLanguage,
  loadLanguages,
  setVehicleLanguageId,
  setImageUploadError,
} from "./actions";
import {
  getAuth,
  getCampaignListSelector,
  getCampaignListPageSelector,
  getCampaignLeadsListSelector,
  getCampaignLeadsListPageSelector,
  getCurrentUserSelector,
  getLoading,
  getUsersListPageSelector,
  getUsersListSelector,
  getMarketSettingsSelector,
  getVehicleListSelector,
  getLeadsListPageSelector,
  getLeadsListSelector,
  getLeadsReferenceKeySelector,
  getLeadsCountSelector,
  getHeaderUserMarketsSelector,
  getUserBranchesSelector,
  getPortfolioListSelector,
  getPortfolioListPageSelector,
  getPortfolioFiltersSelector,
  getLoadingCountSelector,
  getDashboardLoadType,
  getServiceAlertsListSelector,
  getServiceAlertsListPageSelector,
  getSearchModuleListSelector,
  getSearchModuleListPageSelector,
  getSearchModuleParams,
  getSuccessModalValues,
  getCustomerRecordDataSelector,
  getSpecialistsSelector,
  getClientPositionDataSelector,
  getFinanceRecordDataSelector,
  getServiceAlertsFiltersSelector,
  getActivityRecordSelector,
  getReportsUsageGridListSelector,
  getReportsUsageGridListPageSelector,
  getReportsConversionGridListSelector,
  getReportsConversionGridListPageSelector,
  getVehiclesListPageSelector,
  getVehiclesListSelector,
  getVehicleDataSelector,
  getLanguagesSelector,
  getVehicleLanguageIdSelector,
  getImageUploadError,
} from "./selectors";
import Campaigns from "./components/campaigns/Campaigns";
import CampaignLeads from "./components/campaigns/CampaignLeads";
import Sidebar from "./components/shared/Sidebar";
import Dashboard from "./components/dashboard/Dashboard";
import Landing from "./components/loginPage/components/landingPage/Landing";
import InvalidPage from "./components/loginPage/components/invalidPage/InvalidPage";
import SearchModule from "./components/searchModule/SearchModule";
import CustomerRecord from "./components/customerRecord/CustomerRecord";
import EditProposedDeal from "./components/editProposedDeal/EditProposedDeal";
import UserManagement from "./components/userManagement/UserManagement";
import Header from "./components/shared/Header";
import { Routes, Route } from "react-router-dom";
import UserForm from "./components/userManagement/UserForm";
import axios from "axios";
import fetchClient, { getchToken } from "./api/fetch";
import Cookie from "./api/Cookies";
import PingLogin from "./components/loginPage/components/mockUpLogin/PingLogin";
import ServiceAlerts from "./components/serviceAlerts/ServiceAlerts";
import Reports from "./components/reports/Reports";
import ConversionReport from "./components/reports/ConversionReport";
import UsageReport from "./components/reports/UsageReport";
import Portfolio from "./components/portfolio/Portfolio";
import CampaignBuilder from "./components/campaigns/CampaignBuilder";
import LoadingPage from "./components/shared/LoadingPage";
import IdleTimeOutHandler from "./components/shared/IdleTimeoutHandler";
import SuccessModal from "./components/shared/SuccessModal";
import AccessControl from "./components/userManagement/AccessControl";
import Settings from "./components/settings/Settings";
import Vehicles from "./components/vehicles/Vehicles";
import VehiclesForm from "./components/vehicles/VehiclesForm";

const App = (props) => {
  const isAuth = props.isAuth;
  const Navigation = useNavigate();
  let location = useLocation();

  const checkAuth = (sessionId) => {
    console.log("Check auth");
    if (!Cookie.get("token")) {
      getchToken()
        .post(`/v1/auth/validate?samlSessionId=${sessionId}`)
        .then((response) => {
          // Save response token to cookies
          Cookie.set("token", response.data.token);
          Cookie.set("rftoken", response.data.refreshtoken);
          props.setAuth(true);

          // - call user info - save info to redux
          axios
            .all([
              // TODO: Comment out incase we need user permissions
              // // - call user permissions - save info to redux
              // fetchClient().get(`/v1/permissions`),
              // // - call user menu - save info to redux
              // fetchClient().get(`/v1/permissions/menu`),

              // - call user info - save info to redux
              fetchClient().get("/v1/users/info"),
              // - call user settings - save info to redux (userId still static)
              fetchClient().get(`/v1/usersettings`),
            ])
            .then(
              // TODO: Uncomment if we need user info and permissions
              // axios.spread((userInfo, permissions, menu, settings) => {

              axios.spread((userInfo, settings) => {
                props.setCurrentUser({
                  userInfo: userInfo.data.data,
                  permissions: [],
                  menu: [],
                  settings: settings.data,
                });
              })
            );

          // Call MarketSettings API
          props.getMarketSettings();

          // Call Languages API
          props.getLanguages();
        })
        .catch((err) => {
          Navigation("/invalid-identity");
        });
    }
  };

  useEffect(() => {
    const sessionId = location.search.split("=")[1];
    // NOT VALID FOR NOW
    // const isInvalidMarketPath = location.pathname === "/invalid-market";
    if (sessionId) {
      checkAuth(sessionId);
    } else if (Cookie.get("token") && !isAuth) {
      // Navigate to invalid identity for now

      // prevent user from going through the app once logged out
      // - call user info - save info to redux
      axios
        .all([
          // TODO: Comment out incase we need user permissions
          // // - call user permissions - save info to redux
          // fetchClient().get(`/v1/permissions`),
          // // - call user menu - save info to redux
          // fetchClient().get(`/v1/permissions/menu`),

          // - call user info - save info to redux
          fetchClient().get("/v1/users/info"),
          // - call user settings - save info to redux (userId still static)
          fetchClient().get(`/v1/usersettings`),
        ])
        .then(
          // TODO: Uncomment if we need user info and permissions
          // axios.spread((userInfo, permissions, menu, settings) => {

          axios.spread((userInfo, settings) => {
            props.setCurrentUser({
              userInfo: userInfo.data.data,
              permissions: [],
              menu: [],
              settings: settings.data,
            });
            props.setAuth(true);
          })
        );

      // Call MarketSettings API
      props.getMarketSettings();

      // Call Languages API
      props.getLanguages();

      // redirect dashboard (currently redirects to last visited page)
      Navigation(location.pathname);
    }
  }, [location.search, isAuth]);

  return (
    <div className="App container-fluid">
      {/* Idle handler */}
      {isAuth && <IdleTimeOutHandler />}

      {isAuth ? (
        <Suspense>
          <div className="main-container d-flex">
            <Sidebar {...props} />
            <div className="main-contents w-100">
              {props.isLoading && <LoadingPage {...props} />}
              {props.successModalValues.isVisible && (
                <SuccessModal
                  successModalValues={props.successModalValues}
                  setSuccessModalValues={props.setSuccessModalValues}
                />
              )}
              <Header {...props} />
              <Routes>
                <Route path="/dashboard" element={<Dashboard {...props} />} />
                <Route path="/campaigns" element={<Campaigns {...props} />} />
                {/** Route for campaign builder */}
                <Route
                  path="/campaigns/new"
                  element={<CampaignBuilder {...props} />}
                />
                {/** Route for campaign leads */}
                <Route
                  path="/campaigns/:id/leads"
                  element={<CampaignLeads {...props} />}
                />
                {/** Route for edit campaign */}
                <Route
                  path="/campaigns/:id"
                  element={<CampaignBuilder {...props} />}
                />
                <Route
                  path="/service-alerts"
                  element={<ServiceAlerts {...props} />}
                />
                <Route path="/portfolio" element={<Portfolio {...props} />} />
                <Route
                  path="/search-module"
                  element={<SearchModule {...props} />}
                />
                <Route
                  path="/customer-record/:id"
                  element={<CustomerRecord {...props} />}
                />
                <Route
                  path="/customer-record/:id/edit-proposed-deal"
                  element={<EditProposedDeal {...props} />}
                />
                <Route
                  path="/marketing-tools"
                  element={<Campaigns {...props} />}
                />
                <Route path="/reports" element={<Reports {...props} />} />
                <Route
                  path="/reports/conversion-report"
                  element={<ConversionReport {...props} />}
                />
                <Route
                  path="/reports/usage-report"
                  element={<UsageReport {...props} />}
                />
                <Route
                  path="/user-management"
                  element={<UserManagement {...props} />}
                />
                <Route
                  path="/user-management/new"
                  element={<UserForm {...props} />}
                />
                <Route
                  path="/user-management/:id"
                  element={<UserForm {...props} />}
                />
                <Route
                  path="/access-control"
                  element={<AccessControl {...props} />}
                />
                <Route path="/settings" element={<Settings {...props} />} />
                <Route path="/vehicles" element={<Vehicles {...props} />} />
                <Route
                  path="/vehicles/create-vehicle"
                  element={<VehiclesForm {...props} />}
                />
                <Route
                  path="/vehicles/edit-vehicle/:id"
                  element={<VehiclesForm {...props} />}
                />
                <Route
                  path="/*"
                  element={<Navigate to="/vehicles" {...props} />}
                />
              </Routes>
            </div>
          </div>
        </Suspense>
      ) : (
        <Routes>
          <Route path="/" element={<Landing {...props} />} />
          <Route path="/login" element={<Landing {...props} />} />
          <Route path="/pinglogin" element={<PingLogin {...props} />} />
          <Route
            path="/inactive"
            element={<InvalidPage {...props} status="100" />}
          />
          <Route
            path="/invalid-identity"
            element={<InvalidPage {...props} status="401" />}
          />
          <Route
            path="/system-error"
            element={<InvalidPage {...props} status="300" />}
          />
          <Route
            path="/invalid-market"
            element={<InvalidPage {...props} status="403" />}
          />
        </Routes>
      )}
    </div>
  );
};
// Global state
const mapStateToProps = (state) => ({
  isLoading: getLoading(state),
  isAuth: getAuth(state),
  campaignList: getCampaignListSelector(state),
  currentUser: getCurrentUserSelector(state),
  usersList: getUsersListSelector(state),
  usersListPage: getUsersListPageSelector(state),
  campaignListPage: getCampaignListPageSelector(state),
  campaignLeadsList: getCampaignLeadsListSelector(state),
  campaignLeadsListPage: getCampaignLeadsListPageSelector(state),
  marketSettings: getMarketSettingsSelector(state),
  vehicleSelectorList: getVehicleListSelector(state),
  leadsCount: getLeadsCountSelector(state),
  leadsList: getLeadsListSelector(state),
  leadsListPage: getLeadsListPageSelector(state),
  leadsReferenceKey: getLeadsReferenceKeySelector(state),
  vehiclesList: getVehiclesListSelector(state),
  vehiclesListPage: getVehiclesListPageSelector(state),
  vehicleData: getVehicleDataSelector(state),
  languages: getLanguagesSelector(state),
  vehicleLanguageId: getVehicleLanguageIdSelector(state),
  headerUserMarkets: getHeaderUserMarketsSelector(state),
  userBranches: getUserBranchesSelector(state),
  portfolioList: getPortfolioListSelector(state),
  portfolioListPage: getPortfolioListPageSelector(state),
  portfolioFilters: getPortfolioFiltersSelector(state),
  loadingCount: getLoadingCountSelector(state),
  dashboardLoadType: getDashboardLoadType(state),
  serviceAlertsList: getServiceAlertsListSelector(state),
  serviceAlertsListPage: getServiceAlertsListPageSelector(state),
  serviceAlertsFilters: getServiceAlertsFiltersSelector(state),
  searchModuleList: getSearchModuleListSelector(state),
  searchModuleListPage: getSearchModuleListPageSelector(state),
  searchModuleParams: getSearchModuleParams(state),
  successModalValues: getSuccessModalValues(state),
  customerRecordData: getCustomerRecordDataSelector(state),
  clientPositionData: getClientPositionDataSelector(state),
  financeRecordData: getFinanceRecordDataSelector(state),
  specialists: getSpecialistsSelector(state),
  activityRecord: getActivityRecordSelector(state),
  reportsUsageGridList: getReportsUsageGridListSelector(state),
  reportsUsageGridListPage: getReportsUsageGridListPageSelector(state),
  reportsConversionGridList: getReportsConversionGridListSelector(state),
  reportsConversionGridListPage:
    getReportsConversionGridListPageSelector(state),
  imageUploadError: getImageUploadError(state),
});

// Global props
const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => {
    dispatch(setLoading(isLoading));
  },
  getCampaignList: (args) => {
    dispatch(loadCampaignList(args));
  },
  setCampaignList: (campaignList) => {
    dispatch(setCampaignList(campaignList));
  },
  getCampaignLeadsList: (campaignId, args) => {
    dispatch(loadCampaignLeadsList(campaignId, args));
  },
  setCampaignLeadsList: (campaignLeadsList) => {
    dispatch(setCampaignLeadsList(campaignLeadsList));
  },
  setAuth: (isAuth) => {
    dispatch(setAuth(isAuth));
  },
  setCurrentUser: (currentUser) => {
    dispatch(setCurrentUser(currentUser));
  },
  getUsersList: (args) => {
    dispatch(loadUsersList(args));
  },
  getMarketSettings: () => {
    dispatch(loadMarketSettings());
  },
  getVehicleSelectorList: (args) => {
    dispatch(loadVehicleSelectorList(args));
  },
  getLeadsList: (args) => {
    dispatch(loadLeadsList(args));
  },
  setLeadsList: (leadsList) => {
    dispatch(setLeadsList(leadsList));
  },
  getVehiclesList: (args) => {
    dispatch(loadVehiclesList(args));
  },
  setVehiclesList: (vehicleList) => {
    dispatch(setVehiclesList(vehicleList));
  },
  saveVehicle: (data, primaryImageIndex, imageFiles) => {
    dispatch(saveVehicle(data, primaryImageIndex, imageFiles));
  },
  saveExistingVehicle: (
    data,
    id,
    primaryImageIndex,
    imageFiles,
    isUpdatePrimaryImage,
    newPrimaryImage,
    isNew,
    deletedImages
  ) => {
    dispatch(
      saveExistingVehicle(
        data,
        id,
        primaryImageIndex,
        imageFiles,
        isUpdatePrimaryImage,
        newPrimaryImage,
        isNew,
        deletedImages
      )
    );
  },
  setImageUploadError: (imageUploadError) => {
    dispatch(setImageUploadError(imageUploadError));
  },
  deleteVehicle: (id) => {
    dispatch(deleteVehicle(id));
  },
  getVehicleData: (args) => {
    dispatch(loadVehicleData(args));
  },
  getLanguages: () => {
    dispatch(loadLanguages());
  },
  setVehicleLanguageId: (languageId) => {
    dispatch(setVehicleLanguageId(languageId));
  },
  saveDefaultLanguage: (data) => {
    dispatch(saveDefaultLanguage(data));
  },
  setLeadsReferenceKey: (refKey) => {
    dispatch(setLeadsReferenceKey(refKey));
  },
  getLeadsCount: (payload) => {
    dispatch(loadLeadsCount(payload));
  },
  setLeadsCount: (leadsCount) => {
    dispatch(setLeadsCount(leadsCount));
  },
  getHeaderUserMarkets: () => {
    dispatch(loadHeaderUserMarkets());
  },
  updateUserSettings: (userSettings) => {
    dispatch(updateUserSettings(userSettings));
  },
  saveCampaignLeads: (data) => {
    dispatch(saveCampaignLeads(data));
  },
  getUserBranches: (marketId) => {
    dispatch(loadUserBranches(marketId));
  },
  getPortfolioList: (args) => {
    dispatch(loadPortfolioList(args));
  },
  setPortfolioFilters: (filters) => {
    dispatch(setPortfolioFilters(filters));
  },
  setLoadingCount: (count) => {
    dispatch(setLoadingCount(count));
  },
  setDashboardLoadType: (type) => {
    dispatch(setDashboardLoadType(type));
  },
  getServiceAlertsList: (args) => {
    dispatch(loadServiceAlertsList(args));
  },
  setServiceAlertsFilters: (filters) => {
    dispatch(setServiceAlertsFilters(filters));
  },
  getSearchModuleList: (args) => {
    dispatch(loadSearchModuleList(args));
  },
  setSearchModuleList: (searchModuleList) => {
    dispatch(setSearchModuleList(searchModuleList));
  },
  setSearchModuleParams: (searchModuleParams) => {
    dispatch(setSearchModuleParams(searchModuleParams));
  },
  setSuccessModalValues: (successModalValues) => {
    dispatch(setSuccessModalValues(successModalValues));
  },
  exportCampaignToExcel: (campaignId) => {
    dispatch(exportCampaign(campaignId));
  },
  removeOpportunity: (removeOpportunityData) => {
    dispatch(removeOpportunity(removeOpportunityData));
  },
  getCustomerRecordData: (id) => {
    dispatch(loadCustomerRecordData(id));
  },
  getSpecialists: () => {
    dispatch(loadSpecialists());
  },
  getActivityRecord: (id) => {
    dispatch(loadActivityRecord(id));
  },
  updateActivityRecord: (updateActivityRecordData, id) => {
    dispatch(updateActivityRecord(updateActivityRecordData, id));
  },
  getReportsUsageGridList: (args) => {
    dispatch(loadReportsUsageGridList(args));
  },
  getReportsConversionGridList: (args) => {
    dispatch(loadReportsConversionGridList(args));
  },
  exportUsageReportToExcel: (filters) => {
    dispatch(exportUsageReport(filters));
  },
  exportConversionReportToExcel: (filters) => {
    dispatch(exportConversionReport(filters));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
