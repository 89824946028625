/* Declare action types and import to index */

export const SET_LOADING = "IS_LOADING";
export const SET_AUTH = "SET_AUTH";
export const GET_MARKET_SETTINGS = "GET_MARKET_SETTINGS";
export const SET_MARKET_SETTINGS = "SET_MARKET_SETTINGS";
export const GET_HEADER_USER_MARKETS = "GET_HEADER_USER_MARKETS";
export const SET_HEADER_USER_MARKETS = "SET_HEADER_USER_MARKETS";
export const GET_USER_BRANCHES = "GET_USER_BRANCHES";
export const SET_USER_BRANCHES = "SET_USER_BRANCHES";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";

export const SET_CAMPAIGN_LIST = "SET_CAMPAIGN_LIST";
export const GET_CAMPAIGN_LIST = "GET_CAMPAIGN_LIST";

export const SET_CAMPAIGN_LEADS_LIST = "SET_CAMPAIGN_LEADS_LIST";
export const GET_CAMPAIGN_LEADS_LIST = "GET_CAMPAIGN_LEADS_LIST";
export const SET_CAMPAIGN_LEADS_LIST_PAGE = "SET_CAMPAIGN_LEADS_LIST_PAGE";

export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const GET_USERS_LIST = "GET_USERS_LIST";
export const SET_USERS_LIST = "SET_USERS_LIST";
export const SET_USERS_LIST_PAGE = "SET_USERS_LIST_PAGE";
export const SET_CAMPAIGN_LIST_PAGE = "SET_CAMPAIGN_LIST_PAGE";

export const GET_VEHICLE_SELECTOR_LIST = "GET_VEHICLE_SELECTOR_DATA";
export const SET_VEHICLE_SELECTOR_LIST = "SET_VEHICLE_SELECTOR_DATA";
export const SET_VEHICLE_SELECTOR_LIST_PAGE = "SET_VEHICLE_SELECTOR_LIST_PAGE";
export const GET_LEADS_COUNT = "GET_LEADS_COUNT";
export const SET_LEADS_COUNT = "SET_LEADS_COUNT";
export const GET_LEADS_LIST = "GET_LEADS_LIST";
export const SET_LEADS_LIST = "SET_LEADS_LIST";
export const SET_LEADS_LIST_PAGE = "SET_LEADS_LIST_PAGE";
export const GET_LEADS_REFERENCE_KEY = "GET_LEADS_REFERENCE_KEY";
export const SET_LEADS_REFERENCE_KEY = "SET_LEADS_REFERENCE_KEY";
export const SET_CAMPAIGN_LEADS = "SET_CAMPAIGN_LEADS";
export const GET_PORTFOLIO_LIST = "GET_PORTFOLIO_LIST";
export const SET_PORTFOLIO_LIST = "SET_PORTFOLIO_LIST";
export const SET_PORTFOLIO_LIST_PAGE = "SET_PORTFOLIO_LIST_PAGE";
export const SET_PORTFOLIO_FILTERS = "SET_PORTFOLIO_FILTERS";
export const SET_LOADING_COUNT = "SET_LOADING_COUNT";
export const SET_DASHBOARD_LOAD_TYPE = "SET_DASHBOARD_LOAD_TYPE";
export const GET_SERVICE_ALERTS_LIST = "GET_SERVICE_ALERTS_LIST";
export const SET_SERVICE_ALERTS_LIST = "SET_SERVICE_ALERTS_LIST";
export const SET_SERVICE_ALERTS_LIST_PAGE = "SET_SERVICE_ALERTS_LIST_PAGE";
export const SET_SERVICE_ALERTS_FILTERS = "SET_SERVICE_ALERTS_FILTERS";

export const GET_SEARCH_MODULE_LIST = "GET_SEARCH_MODULE_LIST";
export const SET_SEARCH_MODULE_LIST = "SET_SEARCH_MODULE_LIST";
export const SET_SEARCH_MODULE_LIST_PAGE = "SET_SEARCH_MODULE_LIST_PAGE";
export const GET_SEARCH_MODULE_PARAMS = "GET_SEARCH_MODULE_PARAMS";
export const SET_SEARCH_MODULE_PARAMS = "SET_SEARCH_MODULE_PARAMS";

export const GET_SUCCESS_MODAL_VALUES = "GET_SUCCESS_MODAL_VALUES";
export const SET_SUCCESS_MODAL_VALUES = "SET_SUCCESS_MODAL_VALUES";

export const SET_REMOVE_OPPORTUNITY_DATA = "SET_REMOVE_OOPORTUNITY_DATA";
export const SET_CUSTOMER_RECORD_DATA = "SET_CUSTOMER_RECORD_DATA";
export const GET_CUSTOMER_RECORD_DATA = "GET_CUSTOMER_RECORD_DATA";
export const SET_CLIENT_POSITION_DATA = "SET_CLIENT_POSITION_DATA";
export const GET_CLIENT_POSITION_DATA = "GET_CLIENT_POSITION_DATA";
export const SET_FINANCE_RECORD_DATA = "SET_FINANCE_RECORD_DATA";
export const GET_FINANCE_RECORD_DATA = "GET_FINANCE_RECORD_DATA";
export const SET_CUSTOMER_RECORD_USER_LIST = "SET_CUSTOMER_RECORD_USER_LIST";
export const GET_CUSTOMER_RECORD_USER_LIST = "GET_CUSTOMER_RECORD_USER_LIST";
export const GET_ACTIVITY_RECORD = "GET_ACTIVITY_RECORD";
export const SET_ACTIVITY_RECORD = "SET_ACTIVITY_RECORD";
export const UPDATE_ACTIVITY_RECORD = "UPDATE_ACTIVITY_RECORD";

export const GET_REPORTS_USAGE_GRID_LIST = "GET_REPORTS_USAGE_GRID_LIST";
export const SET_REPORTS_USAGE_GRID_LIST = "SET_REPORTS_USAGE_GRID_LIST";
export const SET_REPORTS_USAGE_GRID_LIST_PAGE =
  "SET_REPORTS_USAGE_GRID_LIST_PAGE";
export const GET_REPORTS_CONVERSION_GRID_LIST =
  "GET_REPORTS_CONVERSION_GRID_LIST";
export const SET_REPORTS_CONVERSION_GRID_LIST =
  "SET_REPORTS_CONVERSION_GRID_LIST";
export const SET_REPORTS_CONVERSION_GRID_LIST_PAGE =
  "SET_REPORTS_CONVERSION_GRID_LIST_PAGE";

export const SET_SPECIALISTS = "SET_SPECIALISTS";
export const GET_SPECIALISTS = "GET_SPECIALISTS";

export const EXPORT_CAMPAIGN = "EXPORT_CAMPAIGN";
export const EXPORT_USAGE_REPORT = "EXPORT_USAGE_REPORT";
export const EXPORT_CONVERSION_REPORT = "EXPORT_CONVERSION_REPORT";

export const GET_VEHICLES_LIST = "GET_VEHICLES_LIST";
export const SET_VEHICLES_LIST = "SET_VEHICLES_LIST";
export const SET_VEHICLES_LIST_PAGE = "SET_VEHICLES_LIST_PAGE";
export const SET_REMOVE_VEHICLE = "SET_REMOVE_VEHICLE";
export const GET_VEHICLE = "GET_VEHICLE";
export const SET_VEHICLE = "SET_VEHICLE";
export const SAVE_VEHICLE = "SAVE_VEHICLE";
export const SAVE_EXISTING_VEHICLE = "SAVE_EXISTING_VEHICLE";
export const UPLOAD_IMAGES = "UPLOAD_IMAGES";
export const DELETE_IMAGES = "DELETE_IMAGES";

export const SAVE_DEFAULT_LANGUAGE = "SAVE_DEFAULT_LANGUAGE";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const SET_LANGUAGES = "SET_LANGUAGES";

export const SET_VEHICLE_LANGUAGE_ID = "SET_VEHICLE_LANGUAGE_ID";

export const GET_IMAGE_ERROR = "GET_IMAGE_ERROR";
export const SET_IMAGE_UPLOAD_ERROR = "SET_IMAGE_UPLOAD_ERROR";