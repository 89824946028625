const BASE_URL =
  "https://azweuglostdevgsl01.blob.core.windows.net/vms-paul-local";
// https://azweuglostdevreng01.blob.core.windows.net/azweuglocndevreng01/uploads/Brunei_images

const BASE_URL_BLOB_STORAGE = `https://azweuglostdevgsl01.blob.core.windows.net/vms-paul-local/assets/images/`;

export const getIcon = (name) => {
  return `${BASE_URL}/assets/icons/${name}`;
};

export const fetchImg = (name) => {
  return BASE_URL_BLOB_STORAGE + name;
};
