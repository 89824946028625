/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../styles/vehicles/vehiclesForm.css";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const MainForm = ({
  selectedLanguage,
  commonForm,
  handleCommonInputChange,
  mainForm,
  handleMainInputChange,
  isLive,
  handleIsLiveChange,
}) => {
  const { t } = useTranslation();
  const vehicleType = [
    { id: "CAR", text: "Cars" },
    { id: "TRUCK", text: "Trucks" },
    { id: "VAN", text: "Vans" },
    { id: "FREIGHT", text: "Freight" },
    { id: "TRAILER", text: "Trailers" },
    { id: "OTHER", text: "Others" },
  ];
  const stockType = [
    { id: "USED", text: "Used vehicle" },
    { id: "NEW", text: "Brand new vehicle" },
  ];

  return (
    <div className="main-form">
      <div className="d-flex">
        <div className="forms-input">
          <div className="vehicles-form-inputs">
            <label>{getTranslation("Public Status", t)}*</label>
            <div>
              <Switch onChange={handleIsLiveChange} checked={isLive} />
            </div>
          </div>
          {commonForm.map((item) => {
            return (
              <>
                {item.column === 1 && item.form === "main" && (
                  <div className="vehicles-form-inputs">
                    <label>
                      {getTranslation(item.label, t)}
                      {item.isRequired && "*"}
                    </label>
                    {item.type === "input" ? (
                      <Input
                        className={`k-input-flat ${
                          item.isRequired && item.hasError ? "error-input " : ""
                        }`}
                        type={
                          ["modelYear"].includes(item.name) ? "number" : "input"
                        }
                        step={["modelYear"].includes(item.name) ? "0.01" : ""}
                        disabled={item.isDisabled}
                        name={item.name}
                        value={item.value}
                        onChange={handleCommonInputChange}
                      />
                    ) : (
                      <DropDownList
                        className={`k-input-flat ${
                          item.isRequired && item.hasError ? "error-input " : ""
                        }`}
                        data={
                          item.name === "stockTypeId" ? stockType : vehicleType
                        }
                        textField="text"
                        disabled={item.isDisabled}
                        dataItemKey="id"
                        name={item.name}
                        value={item.value}
                        onChange={handleCommonInputChange}
                      />
                    )}
                    {item.isRequired && item.hasError && (
                      <span className="error-text">
                        <p>{getTranslation("This field is required.", t)}</p>
                      </span>
                    )}
                  </div>
                )}
              </>
            );
          })}
          {mainForm.map((form) => {
            if (selectedLanguage === form.language)
              return form.data.map((item) => {
                return (
                  <>
                    {item.column === 1 && (
                      <div className="vehicles-form-inputs">
                        <label>
                          {getTranslation(item.label, t)}
                          {item.isRequired && "*"}
                        </label>
                        {item.type === "input" ? (
                          <Input
                            className={`k-input-flat ${
                              item.isRequired && item.hasError
                                ? "error-input "
                                : ""
                            }`}
                            disabled={item.isDisabled}
                            name={item.name}
                            value={item.value}
                            onChange={handleMainInputChange}
                          />
                        ) : (
                          <DropDownList
                            className={`k-input-flat ${
                              item.isRequired && item.hasError
                                ? "error-input "
                                : ""
                            }`}
                            data={
                              item.name === "stockTypeId"
                                ? stockType
                                : vehicleType
                            }
                            textField="text"
                            dataItemKey="id"
                            disabled={item.isDisabled}
                            name={item.name}
                            value={item.value}
                            onChange={handleMainInputChange}
                          />
                        )}
                        {item.isRequired && item.hasError && (
                          <span className="error-text">
                            <p>
                              {getTranslation("This field is required.", t)}
                            </p>
                          </span>
                        )}
                      </div>
                    )}
                  </>
                );
              });
          })}
        </div>
        <div className="forms-input">
          {commonForm.map((item) => {
            return (
              <>
                {item.column === 2 && item.form === "main" && (
                  <div className="vehicles-form-inputs">
                    <label>
                      {getTranslation(item.label, t)}
                      {item.isRequired && "*"}
                    </label>
                    {item.type === "input" ? (
                      <Input
                        className={`k-input-flat ${
                          item.isRequired && item.hasError ? "error-input " : ""
                        }`}
                        type={
                          ["registrationFee", "odometerReading"].includes(
                            item.name
                          )
                            ? "number"
                            : "input"
                        }
                        step={
                          ["registrationFee", "odometerReading"].includes(
                            item.name
                          )
                            ? "0.01"
                            : ""
                        }
                        name={item.name}
                        value={item.value}
                        onChange={handleCommonInputChange}
                      />
                    ) : (
                      <DatePicker
                        className={`k-input-flat ${
                          item.isRequired && item.hasError ? "error-input " : ""
                        }`}
                        format={"dd/MM/yyyy"}
                        disabled={item.isDisabled}
                        name={item.name}
                        value={item.value}
                        onChange={handleCommonInputChange}
                      />
                    )}
                    {item.isRequired && item.hasError && (
                      <span className="error-text">
                        <p>{getTranslation("This field is required.", t)}</p>
                      </span>
                    )}
                  </div>
                )}
              </>
            );
          })}
          {mainForm.map((form) => {
            if (selectedLanguage === form.language)
              return form.data.map((item) => {
                return (
                  <>
                    {item.column === 2 && (
                      <div className="vehicles-form-inputs">
                        <label>
                          {getTranslation(item.label, t)}
                          {item.isRequired && "*"}
                        </label>
                        <Input
                          className={`k-input-flat ${
                            item.isRequired && item.hasError
                              ? "error-input "
                              : ""
                          }`}
                          disabled={item.isDisabled}
                          name={item.name}
                          value={item.value}
                          onChange={handleMainInputChange}
                        />
                        {item.isRequired && item.hasError && (
                          <span className="error-text">
                            <p>
                              {getTranslation("This field is required.", t)}
                            </p>
                          </span>
                        )}
                      </div>
                    )}
                  </>
                );
              });
          })}
        </div>
        <div className="forms-input">
          {mainForm.map((form) => {
            if (selectedLanguage === form.language)
              return form.data.map((item) => {
                return (
                  <>
                    {item.column === 3 && (
                      <div className="vehicles-form-inputs">
                        <label>
                          {getTranslation(item.label, t)}
                          {item.isRequired && "*"}
                        </label>
                        {item.type === "input" ? (
                          <Input
                            className={`k-input-flat ${
                              item.isRequired && item.hasError
                                ? "error-input "
                                : ""
                            }`}
                            disabled={item.isDisabled}
                            name={item.name}
                            value={item.value}
                            onChange={handleMainInputChange}
                          />
                        ) : (
                          <DropDownList
                            className={`k-input-flat ${
                              item.isRequired && item.hasError
                                ? "error-input "
                                : ""
                            }`}
                            data={
                              item.name === "stockTypeId"
                                ? stockType
                                : vehicleType
                            }
                            disabled={item.isDisabled}
                            name={item.name}
                            value={item.value}
                            onChange={handleMainInputChange}
                          />
                        )}
                        {item.isRequired && item.hasError && (
                          <span className="error-text">
                            <p>
                              {getTranslation("This field is required.", t)}
                            </p>
                          </span>
                        )}
                      </div>
                    )}
                  </>
                );
              });
          })}
          {commonForm.map((item) => {
            return (
              <>
                {item.column === 3 && item.form === "main" && (
                  <div className="vehicles-form-inputs">
                    <label>
                      {getTranslation(item.label, t)}
                      {item.isRequired && "*"}
                    </label>
                    {item.type === "input" ? (
                      <Input
                        className={`k-input-flat ${
                          item.isRequired && item.hasError ? "error-input " : ""
                        }`}
                        type={
                          ["roadTax", "originalPrice", "priceForTax"].includes(
                            item.name
                          )
                            ? "number"
                            : "input"
                        }
                        step={
                          ["roadTax", "originalPrice", "priceForTax"].includes(
                            item.name
                          )
                            ? "0.01"
                            : ""
                        }
                        disabled={item.isDisabled}
                        name={item.name}
                        value={item.value}
                        onChange={handleCommonInputChange}
                      />
                    ) : (
                      <DatePicker
                        className={`k-input-flat ${
                          item.isRequired && item.hasError ? "error-input " : ""
                        }`}
                        disabled={item.isDisabled}
                        format={"dd/MM/yyyy"}
                        name={item.name}
                        value={item.value}
                        onChange={handleCommonInputChange}
                      />
                    )}
                    {item.isRequired && item.hasError && (
                      <span className="error-text">
                        <p>{getTranslation("This field is required.", t)}</p>
                      </span>
                    )}
                  </div>
                )}
              </>
            );
          })}
        </div>
        <div className="forms-input">
          {mainForm.map((form) => {
            if (selectedLanguage === form.language)
              return form.data.map((item) => {
                return (
                  <>
                    {item.column === 4 && (
                      <div className="vehicles-form-inputs">
                        <label>
                          {getTranslation(item.label, t)}
                          {item.isRequired && "*"}
                        </label>
                        {item.type === "input" ? (
                          <Input
                            className={`k-input-flat ${
                              item.isRequired && item.hasError
                                ? "error-input "
                                : ""
                            }`}
                            disabled={item.isDisabled}
                            name={item.name}
                            value={item.value}
                            onChange={handleMainInputChange}
                          />
                        ) : (
                          <DatePicker
                            className={`k-input-flat ${
                              item.isRequired && item.hasError
                                ? "error-input "
                                : ""
                            }`}
                            disabled={item.isDisabled}
                            format={"dd/MM/yyyy"}
                            name={item.name}
                            value={item.value}
                            onChange={handleMainInputChange}
                          />
                        )}
                        {item.isRequired && item.hasError && (
                          <span className="error-text">
                            <p>
                              {getTranslation("This field is required.", t)}
                            </p>
                          </span>
                        )}
                      </div>
                    )}
                  </>
                );
              });
          })}
          {commonForm.map((item) => {
            return (
              <>
                {item.column === 4 && item.form === "main" && (
                  <div className="vehicles-form-inputs">
                    <label>
                      {getTranslation(item.label, t)}
                      {item.isRequired && "*"}
                    </label>
                    {item.type === "input" ? (
                      <Input
                        className={`k-input-flat ${
                          item.isRequired && item.hasError ? "error-input " : ""
                        }`}
                        type={
                          ["deliveryPrice", "price"].includes(item.name)
                            ? "number"
                            : "input"
                        }
                        step={
                          ["deliveryPrice", "price"].includes(item.name)
                            ? "0.01"
                            : ""
                        }
                        disabled={item.isDisabled}
                        name={item.name}
                        value={item.value}
                        onChange={handleCommonInputChange}
                      />
                    ) : (
                      <DatePicker
                        className={`k-input-flat ${
                          item.isRequired && item.hasError ? "error-input " : ""
                        }`}
                        disabled={item.isDisabled}
                        format={"dd/MM/yyyy"}
                        name={item.name}
                        value={item.value}
                        onChange={handleCommonInputChange}
                      />
                    )}
                    {item.isRequired && item.hasError && (
                      <span className="error-text">
                        <p>{getTranslation("This field is required.", t)}</p>
                      </span>
                    )}
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
      <div className="vehicles-form-textarea">
        <label>{getTranslation("Comments", t)}</label>
        {mainForm.map((form) => {
          if (selectedLanguage === form.language)
            return form.data.map((item) => {
              if (item.name === "comment")
                return (
                  <TextArea
                    rows={5}
                    onChange={handleMainInputChange}
                    name={item.name}
                    value={item.value}
                  />
                );
            });
        })}
      </div>
    </div>
  );
};

export default MainForm;
