/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog } from "@progress/kendo-react-dialogs";
import { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Pager } from "@progress/kendo-react-data-tools";
import FilterDropdown from "./VSFilterDropdown";
import "../../styles/campaigns/vehicleSelector.css";
import { Checkbox } from "@progress/kendo-react-inputs";
import fetchClient from "../../api/fetch";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const VehicleSelector = ({
  vsType,
  handleShowVS,
  uncheckedIds,
  setUncheckedIds,
  totalRecords,
  setTotalRecords,
  getVehicleSelectorList,
  vehicleSelectorList,
  payload,
  setPayload,
  initialDropdownValues
}) => {
  const { t } = useTranslation();
  const listMode = vsType === "replacementVS" ? "replacement" : "existing";
  const vehicleList =
    vsType === "replacementVS" ? "replacementVehicles" : "existingVehicles";
  const itemId = vsType === "replacementVS" ? "replacementVehicleId" : "ivId";
  const filterDSType =
    vsType === "replacementVS"
      ? "campaignReplacementDealFilter"
      : "campaignExistingDealFilter";
  const vehicleIdsKey =
    vsType === "replacementVS"
      ? "campaignReplacementVehicles"
      : "campaignExistingVehicles";
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState(undefined);
  const [page, setPage] = useState({
    skip: 0,
    take: 10
  });

  const [dropdownValues, setDropdownValues] = useState(payload[filterDSType]);
  const [open, setOpen] = useState({});
  const [idsArray, setIdsArray] = useState(payload[vehicleIdsKey]);
  const [unchecked, setUnchecked] = useState(uncheckedIds[vsType]);
  const [dropdownData, setDropdownData] = useState({
    brand: [],
    model: [],
    version: [],
    fuelType: [],
    transmission: []
  });
  const [params, setParams] = useState({
    sort: "",
    filter: ""
  });

  useEffect(() => {
    const param = queryBuilder();
    if (vsType === "replacementVS") {
      getVehicleSelectorList({
        mode: "replacement",
        param
      });
    } else {
      getVehicleSelectorList({
        mode: "existing",
        param
      });
    }
  }, [page, params, dropdownValues]);

  useEffect(() => {
    // uncheck select all when filters are cleared or changed
    setPage({
      ...page,
      skip: 0
    });
  }, [dropdownValues]);

  const getFilters = () => {
    let filterDropdowns = [];
    for (const value in dropdownValues) {
      if (dropdownValues[value] === "All") {
        continue;
      } else {
        filterDropdowns.push(
          JSON.stringify({
            field: value,
            value: dropdownValues[value],
            condition: "eq"
          })
        );
      }
    }

    return `filters=[${params.filter.concat(filterDropdowns)}]`;
  };

  const queryBuilder = () => {
    const filter = `&${getFilters()}`;
    return `?page=${JSON.stringify(page)}${filter}${params.sort}`;
  };

  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...e.page,
      skip: e.skip,
      take
    });
  };

  const handleSort = (e) => {
    setSort(e.sort);
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `&sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.dataState.filter);
    if (e.dataState.filter !== undefined) {
      setParams({
        ...params,
        filter: e.dataState.filter.filters.map((item) => {
          let obj = { ...item };
          obj["condition"] = obj["operator"];
          delete obj["operator"];
          obj = JSON.stringify(obj);
          return obj;
        })
      });
      setPage({
        ...page,
        skip: 0
      });
    } else {
      setParams({ ...params, filter: "" });
    }
  };

  const handleOpenDropdown = (filter) => {
    const route =
      vsType === "replacementVS" ? "replacementvehicles" : "existingvehicles";
    let URL = `/v1/${route}/${filter}s?`;
    for (const value in dropdownValues) {
      if (value === filter) {
        continue;
      } else {
        URL +=
          dropdownValues[value] === "All"
            ? ""
            : `${URL[URL.length - 1] === "?" ? "" : "&"}${value}=${
                dropdownValues[value]
              }`;
      }
    }

    fetchClient()
      .get(URL)
      .then((res) =>
        setDropdownData({
          ...dropdownData,
          [filter]: res.data.data[0]
            ? [getTranslation("All", t)].concat(res.data.data)
            : []
        })
      )
      .catch((err) => console.log(err))
      .finally(() => setOpen({ ...open, [filter]: true }));
  };

  const handleCloseDropdown = (e) =>
    setOpen({ ...open, [e.target.name]: false });

  const handleChange = (e) =>
    setDropdownValues({ ...dropdownValues, [e.target.name]: e.target.value });

  const handleClearFilters = () => setDropdownValues(initialDropdownValues);

  const handleSelectAll = () => {
    if (idsArray.length > 0 || unchecked.length > 0) {
      setIdsArray([]);
    } else {
      setIdsArray([""]);
    }
    setUnchecked([]);
  };

  const handleSelect = (dataItem) => {
    if (idsArray.length === 0) {
      if (unchecked.includes(dataItem[itemId])) {
        setUnchecked(unchecked.filter((item) => item !== dataItem[itemId]));
      } else {
        setUnchecked(unchecked.concat(dataItem[itemId]));
      }
    } else {
      if (idsArray.includes(dataItem[itemId])) {
        // check first if idsArray is empty
        const holderArr = idsArray.filter((item) => item !== dataItem[itemId]);

        // remove id from selected
        setIdsArray(holderArr.length > 0 ? holderArr : [""]);
      } else {
        // remove empty string if present then add selected id
        setIdsArray(
          idsArray.includes("")
            ? [dataItem[itemId]]
            : idsArray.concat(dataItem[itemId])
        );
      }
    }
  };

  const handleCancel = () => handleShowVS(vsType);

  const handleSave = () => {
    setUncheckedIds({
      ...uncheckedIds,
      [vsType]: unchecked
    });
    setPayload({
      ...payload,
      [filterDSType]: dropdownValues,
      [vehicleIdsKey]: idsArray
    });
    setTotalRecords({
      ...totalRecords,
      [vsType]: vehicleSelectorList.listPage[listMode].totalRecords
    });
    handleShowVS(vsType);
  };

  const selectAllCell = ({ dataItem }) => {
    return (
      <div
        className="d-flex align-items-center justify-content-center gap-2"
        onClick={handleSelectAll}
      >
        <Checkbox value={idsArray.length === 0 && unchecked.length === 0} />
        <span className="k-cell-inner">
          <span className="k-column-title">
            {getTranslation("Select All", t)}
          </span>
        </span>
      </div>
    );
  };

  const checkboxCell = ({ dataItem }) => {
    return (
      <td>
        <Checkbox
          onChange={() => handleSelect(dataItem)}
          value={
            idsArray.length === 0
              ? !unchecked.includes(dataItem[itemId])
              : idsArray.includes(dataItem[itemId])
          }
        />
      </td>
    );
  };

  const customPager = (props) => {
    return (
      <div className="custom-pager">
        <Pager
          skip={props.skip}
          take={props.take}
          type="numeric"
          total={props.total}
          buttonCount={props.buttonCount}
          previousNext={true}
          pageSizes={props.pageSizes}
          pageSizeValue={props.pageSizeValue}
          onPageChange={pageChange}
        />
        <div className="d-flex gap-3 align-items-center">
          <button className="bg-none-btn" onClick={handleCancel}>
            {getTranslation("Cancel", t)}
          </button>
          <button
            disabled={idsArray[0] === ""}
            className="btn primary-btn"
            onClick={handleSave}
          >
            {getTranslation("Save", t)}
          </button>
        </div>
      </div>
    );
  };

  return (
    <Dialog className="vehicle-selector">
      <h1 className="h1 mx-3 mt-3 mb-4">
        {getTranslation("Select vehicle specifications", t)}
      </h1>
      <div className="filters px-4 pt-2">
        <FilterDropdown
          value={dropdownValues.brand}
          name="brand"
          open={open.brand}
          data={dropdownData.brand}
          label="Brand"
          handleChange={handleChange}
          handleClose={handleCloseDropdown}
          handleOpen={() => handleOpenDropdown("brand")}
        />
        <FilterDropdown
          value={dropdownValues.model}
          name="model"
          open={open.model}
          data={dropdownData.model}
          label="Model"
          handleChange={handleChange}
          handleClose={handleCloseDropdown}
          handleOpen={() => handleOpenDropdown("model")}
        />
        <FilterDropdown
          value={dropdownValues.version}
          name="version"
          open={open.version}
          data={dropdownData.version}
          label="Version"
          handleChange={handleChange}
          handleClose={handleCloseDropdown}
          handleOpen={() => handleOpenDropdown("version")}
        />
        <FilterDropdown
          value={dropdownValues.fuelType}
          name="fuelType"
          open={open.fuelType}
          data={dropdownData.fuelType}
          label="Fuel"
          handleChange={handleChange}
          handleClose={handleCloseDropdown}
          handleOpen={() => handleOpenDropdown("fuelType")}
        />
        <FilterDropdown
          value={dropdownValues.transmission}
          name="transmission"
          open={open.transmission}
          data={dropdownData.transmission}
          label="Transmission"
          handleChange={handleChange}
          handleClose={handleCloseDropdown}
          handleOpen={() => handleOpenDropdown("transmission")}
        />
      </div>
      <button onClick={handleClearFilters} className="secondary-btn ms-4 mb-4">
        {getTranslation("Clear filters", t)}
      </button>
      <Grid
        data={vehicleSelectorList[vehicleList]}
        skip={page.skip}
        take={page.take}
        total={vehicleSelectorList.listPage[listMode].totalRecords}
        filterable={true}
        filter={filter}
        sortable={true}
        sort={sort}
        onSortChange={handleSort}
        onDataStateChange={handleFilterChange}
        pager={customPager}
        pageable={{
          buttonCount:
            vehicleSelectorList.listPage[listMode].totalPages > 10
              ? 10
              : vehicleSelectorList.listPage[listMode].totalPages,
          pageSizes: [5, 10, 15],
          pageSizeValue
        }}
        onRowClick={({ dataItem }) => handleSelect(dataItem)}
      >
        <Column
          width="110px"
          filterable={false}
          cell={checkboxCell}
          headerCell={selectAllCell}
        />
        {vsType === "existingVS" && (
          <Column
            width="200px"
            title={getTranslation("Dealer", t)}
            field="servicingDealer"
          />
        )}
        {vsType === "existingVS" && (
          <Column
            width="150px"
            title={getTranslation("License plate", t)}
            field="licensePlate"
          />
        )}
        {vsType === "existingVS" && (
          <Column
            width="180px"
            title={getTranslation("Kilometers", t)}
            field="mileageAtStart"
          />
        )}
        <Column
          width="300px"
          title={getTranslation("Description", t)}
          field={vsType === "replacementVS" ? "modelFullName" : "version"}
        />
        {vsType === "replacementVS" && (
          <Column
            width="200px"
            title={getTranslation("Dealer", t)}
            field="brand"
          />
        )}
        <Column
          width="180px"
          title={getTranslation("Brand", t)}
          field="brand"
        />
        <Column
          width="300px"
          title={getTranslation("Version", t)}
          field="version"
        />
        <Column width="130px" title={getTranslation("Trim", t)} field="model" />
        <Column
          width="170px"
          title={getTranslation("Fuel", t)}
          field="fuelType"
        />
        <Column
          width="130px"
          title={getTranslation("Engine size", t)}
          field="engineSize"
        />
        <Column
          width="180px"
          title={getTranslation("Body", t)}
          field="bodyType"
        />
        <Column
          width="180px"
          title={getTranslation("Transmission", t)}
          field="transmission"
        />
        {vsType === "existingVS" && (
          <Column
            width="200px"
            title={getTranslation("Source", t)}
            filterable={false}
            field="source"
          />
        )}
      </Grid>
    </Dialog>
  );
};

export default VehicleSelector;
