/* eslint-disable react-hooks/exhaustive-deps */
import "../../styles/portfolio/portfolio.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { getIcon } from "../../utils/iconUtils";
import StatusMultiselect from "../shared/StatusMultiselect";
import { useEffect, useState } from "react";
import {
  leadData,
  activityData,
  financeData,
  newOrUsedData
} from "./FilterDropdownData";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const FilterModal = ({
  closeDialog,
  filters,
  setFilters,
  gridSetting,
  setGridSetting
}) => {
  const { t } = useTranslation();
  const dropdownInitialValues = {
    financeType: {
      textField: getTranslation("Finance type", t)
    },
    newOrUsed: {
      newOrUsed: getTranslation("New or used", t)
    },
    activityType: {
      activityType: getTranslation("Activity type", t)
    },
    leadType: {
      leadType: getTranslation("Select", t)
    }
  };
  const [dropdownValues, setDropdownValues] = useState(dropdownInitialValues);
  const [modalFilters, setModalFilters] = useState({
    this: filters.modal,
    status: filters.status
  });
  const [dropdownData, setDropdownData] = useState({
    financeType: financeData,
    newOrUsed: newOrUsedData,
    activityType: activityData,
    leadType: leadData
  });

  const handleChange = (e) => {
    switch (e.target.name) {
      case "overdue":
        setModalFilters({
          ...modalFilters,
          this: { ...modalFilters.this, overdue: !modalFilters.this.overdue }
        });
        break;
      case "status":
        setModalFilters({
          ...modalFilters,
          status: e.target.value
        });
        break;
      case "financeType":
        setDropdownValues({
          ...dropdownValues,
          financeType: e.target.value
        });
        setModalFilters({
          ...modalFilters,
          this: {
            ...modalFilters.this,
            financeType:
              e.target.value.financeType === "All"
                ? ""
                : e.target.value.financeType
          }
        });
        break;
      case "activityType":
      case "noOfDays":
        break;
      case "newOrUsed":
      case "leadType":
        setDropdownValues({
          ...dropdownValues,
          [e.target.name]: e.target.value
        });
        setModalFilters({
          ...modalFilters,
          this: { ...modalFilters.this, [e.target.name]: e.target.value.value }
        });
        break;
      default:
        setModalFilters({
          ...modalFilters,
          this: {
            ...modalFilters.this,
            [e.target.name]: e.target.value
          }
        });
        break;
    }
  };

  const handleApply = () => {
    closeDialog();
    setFilters({
      ...filters,
      modal: modalFilters.this,
      status: modalFilters.status
    });
    setGridSetting({ ...gridSetting, page: { ...gridSetting.page, skip: 0 } });
  };

  const handleClear = () => {
    setModalFilters({
      status: [],
      this: {}
    });
    setDropdownValues(dropdownInitialValues);
  };

  useEffect(() => {
    setDropdownValues({
      financeType: modalFilters.this.financeType
        ? dropdownData.financeType.find(
            (item) => item.financeType === modalFilters.this.financeType
          )
        : modalFilters.this.financeType === ""
        ? dropdownData.financeType[0]
        : dropdownInitialValues.financeType,
      newOrUsed: modalFilters.this.newOrUsed
        ? dropdownData.newOrUsed.find(
            (item) => item.value === modalFilters.this.newOrUsed
          )
        : dropdownInitialValues.newOrUsed,
      leadType: modalFilters.this.leadType
        ? dropdownData.leadType.find(
            (item) => item.value === modalFilters.this.leadType
          )
        : dropdownInitialValues.leadType,
      activityType: modalFilters.this.activityType
        ? dropdownData.activityType.find(
            (item) => item.value === modalFilters.this.activityType
          )
        : dropdownInitialValues.activityType
    });
  }, [dropdownData]);

  useEffect(() => {
    setDropdownData({
      financeType: financeData.map((item) => ({
        ...item,
        textField: getTranslation(item.textField, t)
      })),
      newOrUsed: newOrUsedData.map((item) => ({
        ...item,
        newOrUsed: getTranslation(item.newOrUsed, t)
      })),
      activityType: activityData.map((item) => ({
        ...item,
        activityType: getTranslation(item.activityType, t)
      })),
      leadType: leadData.map((item) => ({
        ...item,
        leadType: getTranslation(item.leadType, t)
      }))
    });
  }, [t]);

  return (
    <Dialog className="portfolio-filter">
      <div className="header">
        <h1 className="h1">{t("filters")}</h1>
        <img
          onClick={closeDialog}
          src={getIcon("close.svg")}
          alt="close icon"
        />
      </div>
      <div className="row mb-0 cont">
        <div className="col-12 col-md-6 d-flex columns">
          <StatusMultiselect
            value={modalFilters.status}
            name="status"
            handleChange={handleChange}
            placeholder={getTranslation("Status", t)}
          />
          <div className="d-flex align-items-center gap-2 overdue">
            <Checkbox
              name="overdue"
              onChange={handleChange}
              value={modalFilters.this?.overdue || false}
            />
            <p>{getTranslation("Overdue only", t)}</p>
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex columns">
          <DropDownList
            value={dropdownValues.financeType}
            data={dropdownData.financeType}
            onChange={handleChange}
            name="financeType"
            textField="textField"
          />
          <DropDownList
            value={dropdownValues.newOrUsed}
            data={dropdownData.newOrUsed}
            onChange={handleChange}
            name="newOrUsed"
            textField="newOrUsed"
          />
        </div>
        <div className="col-12 col-md-6 columns">
          <label>{getTranslation("Contract term", t)}</label>
          <Input
            onChange={handleChange}
            value={modalFilters.this.financeTerm}
            name="financeTerm"
            type="number"
            placeholder={getTranslation("Contract term in numbers", t)}
          />
        </div>
        <div className="col-12 col-md-6 columns">
          <label>{getTranslation("Months remaining", t)}</label>
          <Input
            onChange={handleChange}
            value={modalFilters.this.monthsRemaining}
            name="monthsRemaining"
            type="number"
            placeholder={getTranslation("Months remaining in numbers", t)}
          />
        </div>
        <div className="col-12 col-md-6 columns">
          <label>{getTranslation("Equity", t)}</label>
          <Input
            onChange={handleChange}
            name="equity"
            value={modalFilters.this.equity}
            type="number"
            placeholder={getTranslation("Equity number", t)}
          />
        </div>
        <div className="col-12 col-md-6 columns">
          <label>{getTranslation("Activity types", t)}</label>
          <div className="d-flex activity-type last-spacing">
            <p>{getTranslation("Last", t)}</p>
            <Input
              onChange={handleChange}
              value={modalFilters.this.noOfDays}
              name="noOfDays"
              type="number"
              placeholder={getTranslation("N° of days", t)}
            />
            <DropDownList
              value={dropdownValues.activityType}
              onChange={handleChange}
              name="activityType"
              data={dropdownData.activityType}
              textField="activityType"
            />
          </div>
        </div>

        <div className="col-12 col-md-6 columns">
          <label>{getTranslation("Months elapsed", t)}</label>
          <Input
            name="monthsElapsed"
            value={modalFilters.this.monthsElapsed}
            onChange={handleChange}
            type="number"
            placeholder={getTranslation("Months elapsed in numbers", t)}
          />
        </div>
        <div className="col-12 col-md-6 columns lead-type">
          <label>{getTranslation("Lead type", t)}</label>
          <DropDownList
            value={dropdownValues.leadType}
            data={dropdownData.leadType}
            name="leadType"
            onChange={handleChange}
            textField="leadType"
          />
        </div>
      </div>
      <div className="btn-group">
        <button onClick={handleApply} className="primary-btn">
          {getTranslation("Apply", t)}
        </button>
        <button onClick={handleClear} className="bg-none-btn">
          {getTranslation("Clear", t)}
        </button>
      </div>
    </Dialog>
  );
};

export default FilterModal;
