/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../styles/dashboard/dashboard.css";
import starIcon from "../../assets/icons/star.svg";
import starFillIcon from "../../assets/icons/star-fill.svg";
import { v4 as uuidv4 } from "uuid";
import { getIcon } from "../../utils/iconUtils";
import { Input } from "@progress/kendo-react-inputs";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const ImageUpload = ({
  vehicleImages,
  files,
  setFiles,
  displayImagefiles,
  setDisplayImagefiles,
  deletedImages,
  setDeletedImages,
  selectedThumbnail,
  setSelectedThumbnail,
  handleVideoLinkChange,
  videoLink,
  setIsUpdatePrimaryImage,
  setIsNew,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef();

  const handleDrop = (e) => {
    e.preventDefault();
    const targetFiles = e.dataTransfer.files;
    let uploadedFiles = [];
    let imageExist = [];
    Array.from(targetFiles).forEach((tf) => {
      imageExist = displayImagefiles.filter((image) =>
        image.name.includes(tf.name.substr(0, tf.name.length - 4))
      );

      if (!imageExist.length) {
        uploadedFiles.push({
          imageId: uuidv4(),
          name: tf.name,
          uploadedImg: URL.createObjectURL(tf),
          isNew: true,
        });
      }
    });

    if (!selectedThumbnail.imageId) {
      setSelectedThumbnail(uploadedFiles);
    }

    setDisplayImagefiles(displayImagefiles.concat(uploadedFiles));

    if (!imageExist.length) {
      setFiles(targetFiles);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleChanges = (e) => {
    const targetFiles = e.target.files;
    let uploadedFiles = [];
    let imageExist = [];
    Array.from(targetFiles).forEach((tf) => {
      imageExist = displayImagefiles.filter((image) =>
        image.name.includes(tf.name.substr(0, tf.name.length - 4))
      );

      if (!imageExist.length) {
        uploadedFiles.push({
          imageId: uuidv4(),
          name: tf.name,
          uploadedImg: URL.createObjectURL(tf),
          isNew: true,
        });
      }
    });

    if (!selectedThumbnail.imageId) {
      setSelectedThumbnail(uploadedFiles);
    }

    setDisplayImagefiles(displayImagefiles.concat(uploadedFiles));

    if (!imageExist.length) {
      setFiles(targetFiles);
    }
  };

  const handleStarClick = (file) => {
    if (file.imageId !== selectedThumbnail.imageId) {
      setIsUpdatePrimaryImage(true);
      if (file.isNew) {
        setIsNew(file.isNew);
      }
    } else {
      setIsUpdatePrimaryImage(false);
      setIsNew(false);
    }
    setSelectedThumbnail(file);
  };

  const handleDelete = (file) => {
    const displayedImageFiles = displayImagefiles.filter(
      (f) => f.imageId !== file.imageId
    );
    setDisplayImagefiles(displayedImageFiles);
    setFiles([...files].filter((f) => f.name !== file.name));
    setDeletedImages([
      ...vehicleImages
        ?.map((i) => {
          if (i.image === file.name) {
            return i.image;
          }
        })
        .filter((f) => f),
      ...deletedImages,
    ]);

    if (
      displayedImageFiles.length &&
      file?.imageId === selectedThumbnail.imageId
    ) {
      setSelectedThumbnail(displayedImageFiles[0]?.imageId);
    }
  };

  return (
    <div className="image-form-container">
      <div
        className="image-form-content"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        htmlFor="imgId"
      >
        <div className="image-container">
          {displayImagefiles?.map((file, idx) => {
            return (
              <div className="uploaded-image">
                <span>
                  <img
                    style={{ width: "200px", height: "200px" }}
                    src={file.uploadedImg}
                  />
                </span>
                <span>
                  <img
                    className="star-icon"
                    src={
                      selectedThumbnail.imageId === file.imageId ||
                      (!selectedThumbnail.imageId && idx === 0)
                        ? starFillIcon
                        : starIcon
                    }
                    alt={`${
                      selectedThumbnail.imageId === file.imageId
                        ? "Primary photo"
                        : "photo"
                    }`}
                    onClick={() => handleStarClick(file)}
                  />
                </span>
                <span>
                  <img
                    className="delete-icon-img"
                    src={getIcon("trash.svg")}
                    alt="delete-icon"
                    onClick={() => handleDelete(file)}
                  />
                </span>
              </div>
            );
          })}
        </div>
        <div className="uploader-container">
          <h3>{getTranslation("Select files or Drop here", t)}</h3>
          <input
            name="imgSrc"
            type="file"
            multiple="true"
            accept="image/png, image/jpeg, image/jpg"
            id="imgId"
            onChange={handleChanges}
            hidden
            ref={inputRef}
          />
          <button
            className="d-flex justify-content-center align-items-center gap-2 secondary-btn select-files-btn"
            onClick={() => inputRef.current.click()}
          >
            <label>{getTranslation("Select Files", t)}</label>
          </button>
        </div>
      </div>
      <div className="video-link">
        <label>{getTranslation("Video link", t)}</label>
        <Input
          className="k-input-flat"
          value={videoLink}
          onChange={handleVideoLinkChange}
        />
      </div>
    </div>
  );
};

export default ImageUpload;
