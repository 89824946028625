/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getIcon } from "../../utils/iconUtils";
import "../../styles/userManagement/userForm.css";
import UserInput from "./UserInput";
import UserDropdown from "./UserDropdown";
import fetchClient from "../../api/fetch";
import axios from "axios";
import SavingModal from "../shared/SavingModal";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const UserForm = ({
  currentUser,
  setAuth,
  setLoading,
  setSuccessModalValues,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const mode = location.pathname === "/user-management/new" ? "create" : "edit";
  const submitCount = useRef(0);
  const initialPayload = {
    name: "",
    surname: "",
    email: "",
    createdBy: "",
    marketId: [],
    branchId: [],
  };
  const [dialogValues, setDialogValues] = useState({
    success: false,
    back: false,
  });
  const [dropdownData, setDropdownData] = useState({
    marketId: [],
    branchId: [],
  });
  const [payload, setPayload] = useState(initialPayload);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (mode === "edit") {
      setLoading(true);
      fetchClient()
        .get(`/v1/users/${id}`)
        .then((res) => {
          const data = res.data.data;
          const userId = currentUser.settings.userId;
          const marketIds = data.marketId.toString();
          axios
            .all([
              fetchClient().get(`/v1/users/${userId}/markets`),
              fetchClient().get(
                `/v1/users/${userId}/branches?markets=${marketIds}`
              ),
            ])
            .then(
              axios.spread((market, branch) => {
                const getValue = (name, response) => {
                  return name !== "role"
                    ? response.data.length > 0
                      ? [
                          {
                            id: 0,
                            [`${name}Description`]: getTranslation(
                              "Select All",
                              t
                            ),
                          },
                        ].concat(response.data)
                      : []
                    : response.data;
                };
                setDropdownData({
                  ...dropdownData,
                  marketId: getValue("market", market),
                  branchId: getValue("branch", branch),
                });
                setPayload({
                  ...data,
                  marketId: market.data.filter((market) =>
                    data.marketId.includes(market.marketId)
                  ),
                  branchId: branch.data.filter((branch) =>
                    data.branchId.includes(branch.branchId)
                  ),
                });
              })
            )
            .catch((err) => console.log(err))
            .finally(() => {
              setLoading(false);
              submitCount.current++;
            });
        });
    }
  }, []);

  useEffect(() => {
    // if a market is removed, values in branch should only include those in remaining markets
    const currentMarkets = payload.marketId.map((market) => market.marketId);
    setPayload({
      ...payload,
      branchId: payload.branchId.filter((branch) =>
        currentMarkets.includes(branch.marketId)
      ),
    });
  }, [payload.marketId]);

  useEffect(() => {
    if (submitCount.current > 0) {
      validate();
    }
  }, [payload]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      submitRequest();
    } else {
      setIsSubmit(false);
    }
  }, [isSubmit]);

  const handleChange = (e) => {
    switch (e.target.name) {
      case "marketId":
      case "branchId":
        // check if selectAll option is included in the multiselect values
        const selectAll = e.target.value.some((item) => item.id === 0);
        // get name of array without "Id"
        const index = e.target.name.indexOf("Id");
        if (selectAll) {
          // if payload array is equal to the available options minus one,
          // meaning all of the options are selected
          if (
            payload[e.target.name].length ===
            dropdownData[e.target.name].length - 1
          ) {
            // deselect all if select all option is clicked again
            setPayload({ ...payload, [e.target.name]: [] });
          } else {
            // select all options but remove the "select all" object
            setPayload({
              ...payload,
              [e.target.name]: dropdownData[e.target.name].filter(
                (item) =>
                  getTranslation(
                    item[`${e.target.name.slice(0, index)}Description`],
                    t
                  ) !== getTranslation("Select All", t)
              ),
            });
          }
        } else {
          // if not all options are selected, only store the checked ones
          setPayload({ ...payload, [e.target.name]: e.target.value });
        }
        break;
      default:
        setPayload({ ...payload, [e.target.name]: e.target.value });
        break;
    }
  };

  const handleBack = () => {
    if (JSON.stringify(payload) === JSON.stringify(initialPayload)) {
      Navigate("/user-management");
    } else {
      setDialogValues({ ...dialogValues, back: true });
    }
  };

  const handleOpenDropdown = (dependencyName, name) => {
    const userId = currentUser.settings.userId;
    let ids;
    if (dependencyName) {
      ids = payload[dependencyName]
        .map((item) => item[dependencyName])
        .toString();
    }
    const endpoints = {
      marketId: `/v1/users/${userId}/markets`,
      branchId: `/v1/users/${userId}/branches?markets=${ids}`,
    };

    const index = name.indexOf("Id");
    fetchClient()
      .get(endpoints[name])
      .then((res) => {
        setDropdownData({
          ...dropdownData,
          [name]:
            name !== "roleId"
              ? res.data.length > 0
                ? [
                    {
                      id: 0,
                      [name.slice(0, index) + "Description"]: getTranslation(
                        "Select All",
                        t
                      ),
                    },
                  ].concat(res.data)
                : []
              : res.data,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setOpen({ ...open, [name]: true }));
  };
  const handleCloseDropdown = (e) =>
    setOpen({ ...open, [e.target.name]: false });

  const validate = () => {
    const err = {};
    if (!payload.name) {
      err.name = true;
    }
    if (!payload.surname) {
      err.surname = true;
    }
    const regex = new RegExp(/^[a-z0-9._]{1,64}@[a-z0-9.]+?\.[a-zA-Z]{2,3}$/i);
    if (!payload.email.match(regex)) {
      err.email = true;
    }
    if (payload.marketId.length === 0) {
      err.marketId = true;
    }
    if (payload.branchId.length === 0) {
      err.branchId = true;
    }
    setErrors(err);
  };

  const handleSave = () => {
    submitCount.current++;
    setIsSubmit(true);
    validate();
  };

  const submitRequest = () => {
    // configure payload arrays to only send ids
    const data = {
      ...payload,
      createdBy:
        mode === "create" ? currentUser.userInfo.userId : payload.createdBy,
      marketId: payload.marketId.map((item) => item.marketId),
      branchId: payload.branchId.map((item) => item.branchId),
    };

    (mode === "create" ? fetchClient().post : fetchClient().put)(
      "/v1/users",
      data
    )
      .then((res) => {
        setSuccessModalValues({
          message: `User ${
            mode === "create" ? "created" : "saved"
          } successfully`,
          isVisible: true,
        });
        setTimeout(() => {
          setSuccessModalValues({ message: "", isVisible: false });
          Navigate("/user-management");
        }, 3000);
      })
      .catch((err) => {
        if (err.response.data === "Email already exists.") {
          setErrors({ ...errors, emailExists: true });
          setIsSubmit(false);
        } else {
          setAuth(false);
          setTimeout(() => {
            Navigate("/system-error");
          }, 100);
          console.log(err);
        }
      });
  };

  return (
    <div className="user-form">
      <div className="d-flex gap-5 align-items-center mb-4 header">
        <img
          onClick={handleBack}
          src={getIcon("arrow-back.svg")}
          alt="back icon"
        />
        {mode === "create" && (
          <p className="h1">{getTranslation("Create User", t)}</p>
        )}
        {mode === "edit" && (
          <p className="h1">{getTranslation("Edit User", t)}</p>
        )}
      </div>
      <div className="module-cont row gx-5">
        <h2 className="mb-5">{getTranslation("User Table", t)}</h2>
        <UserInput
          handleChange={handleChange}
          label="Name"
          value={payload.name}
          name="name"
          error={errors.name}
        />
        <UserInput
          handleChange={handleChange}
          label="Surname"
          value={payload.surname}
          name="surname"
          error={errors.surname}
        />
        <UserInput
          handleChange={handleChange}
          label="Email"
          value={payload.email}
          name="email"
          errors={errors}
          error={errors.email || errors.emailExists}
        />
        <UserDropdown
          label="Market"
          name="marketId"
          error={errors.marketId}
          value={payload.marketId}
          open={open.marketId}
          dataItemKey="marketId"
          handleOpen={(e) => handleOpenDropdown("", "marketId")}
          handleClose={handleCloseDropdown}
          textField="marketDescription"
          data={dropdownData.marketId}
          handleChange={handleChange}
        />
        <UserDropdown
          label="Branch"
          name="branchId"
          error={errors.branchId}
          value={payload.branchId}
          dataItemKey="branchId"
          handleChange={handleChange}
          handleOpen={(e) => handleOpenDropdown("marketId", "branchId")}
          disabled={payload.marketId.length === 0}
          textField="branchDescription"
          data={dropdownData.branchId}
        />
        <div>
          <button onClick={handleSave} className="primary-btn mt-3">
            {getTranslation("Save", t)}
          </button>
        </div>
      </div>

      {dialogValues.back && (
        <SavingModal
          message={"Do you want to leave without saving?"}
          onclickHandleNo={() =>
            setDialogValues({ ...dialogValues, back: false })
          }
          onclickHandleYes={() => Navigate("/user-management")}
        />
      )}
    </div>
  );
};

export default UserForm;
