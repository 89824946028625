import { cloneElement } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getIcon } from "../../utils/iconUtils";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getStatusClass } from "../../utils/statusUtil";
import "../../styles/portfolio/dialogs.css";
import { useState } from "react";
import statusData from "../shared/statusData";
import fetchClient from "../../api/fetch";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const BulkActions = ({
  setLoading,
  closeDialog,
  leadIds,
  filters,
  selectedCar,
  setSelectedLeads,
  setFilters,
  queryBuilder,
  getPortfolioList,
  getModalFilters
}) => {
  const { t } = useTranslation();
  const [specialists, setSpecialists] = useState([]);
  const [payload, setPayload] = useState({
    leadIds,
    assignTo: null,
    status: null
  });

  const [dropdownValues, setDropdownValues] = useState({
    assignTo: {
      userid: 5,
      userFullName: getTranslation("Select", t)
    },
    status: {
      status: getTranslation("Select", t),
      value: 0
    }
  });

  const handleOpenSpecialists = () => {
    fetchClient()
      .get("/v1/users/specialists")
      .then((res) => setSpecialists(res.data.data))
      .catch((err) => console.log(err));
  };

  const handleChange = (e, id) => {
    setPayload({ ...payload, [e.target.name]: e.target.value[id] });
    setDropdownValues({ ...dropdownValues, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    const car = selectedCar.isBrand ? selectedCar.brand : selectedCar.model;
    let params = `?${getModalFilters().slice(1)}&isBrand=${
      selectedCar.isBrand
    }&brandOrModel=${car}`;

    if (
      payload.leadIds.length === 0 &&
      (filters.grid.length > 0 || filters.unselected.length > 0)
    ) {
      params += `&filters=[${filters.grid}]&leadId=[${filters.unselected.map(
        (item) => '"' + item + '"'
      )}]`;
    }

    setLoading(true);
    fetchClient()
      .put("/v1/leads/bulkaction" + params, payload)
      .then((res) => getPortfolioList(queryBuilder()))
      .catch((err) => console.log(err))
      .finally(() => {
        setSelectedLeads([""]);
        setFilters({
          ...filters,
          unselected: []
        });
        setLoading(false);
      });
    closeDialog();
  };

  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <p
        className={`mb-0 ${getStatusClass(
          itemProps.dataItem.classKey
        )} bulk-status`}
      >
        {getTranslation(itemProps.dataItem.status, t)}
      </p>
    );

    return cloneElement(li, li.props, itemChildren);
  };

  return (
    <Dialog className="bulk-actions">
      <div className="d-flex flex-column gap-4 p-3">
        <img
          className="align-self-end"
          onClick={closeDialog}
          src={getIcon("close.svg")}
          alt="close icon"
        />
        <h1 className="h1 mb-4 text-center">
          {getTranslation("Bulk actions", t)}
        </h1>
        <div className="d-flex flex-column inputs">
          <div className="d-flex flex-column gap-2">
            <label>{getTranslation("Assign to", t)}</label>
            <DropDownList
              name="assignTo"
              value={dropdownValues.assignTo}
              onOpen={handleOpenSpecialists}
              data={specialists}
              onChange={(e) => handleChange(e, "userId")}
              textField="userFullName"
            />
          </div>
          <div>
            <label>{getTranslation("Set status to", t)}</label>
            <DropDownList
              name="status"
              data={statusData}
              textField="status"
              value={dropdownValues.status}
              onChange={(e) => handleChange(e, "value")}
              itemRender={itemRender}
            />
          </div>
        </div>
        <div className="btn-group d-flex justify-content-center gap-3 mt-2">
          <button onClick={closeDialog} className="bg-none-btn">
            {getTranslation("Cancel", t)}
          </button>
          <button
            onClick={handleSave}
            disabled={!payload.assignTo && !payload.status}
            className="primary-btn"
          >
            {getTranslation("Accept", t)}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default BulkActions;
