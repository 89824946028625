import { SET_CURRENT_USER } from "../actions/types";

const initialState = {
  userInfo: {},
  permission: [],
  menu: [],
  settings: {}
};

const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return action.currentUser;
    default:
      return state;
  }
};

export default currentUserReducer;
