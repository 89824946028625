import { Input } from "@progress/kendo-react-inputs";
import "../../styles/settings/settings.css";
import { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../common/translation";

const Settings = ({
  marketSettings,
  currentUser,
  saveDefaultLanguage,
  languages,
}) => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState({
    languageName: null,
    languageCode: null,
    languageId: null,
  });
  const [marketId, setMarketId] = useState("");
  const [showError, setShowError] = useState(false);

  const handleLanguageChange = (e) => {
    if (e.value) {
      setSelectedLanguage(e.value);
      setMarketId(currentUser.settings.marketId);
      setShowError(false);
    } else {
      setSelectedLanguage();
      setShowError(true);
    }
  };

  const handleSave = () => {
    const payload = {
      languageId: selectedLanguage.languageId,
      marketId: marketId,
    };
    if (!showError) {
      saveDefaultLanguage(payload);
    }
  };

  useEffect(() => {
    setSelectedLanguage({
      languageName: marketSettings.defaultLanguage,
      languageCode: marketSettings.defaultLanguage?.substr(0, 3).toLowerCase(),
      languageId: marketSettings.languageId,
    });
  }, [marketSettings]);

  return (
    <div className="settings">
      <p className="h1 mb-4">{getTranslation("Settings", t)}</p>
      <div className="module-cont">
        <div
          className={`settings-language-dropdown ${showError ? "error " : ""}`}
        >
          <label>{getTranslation("Default languages", t)}</label>
          <DropDownList
            className="k-input-flat"
            textField="languageName"
            dataItemKey="languageId"
            data={languages}
            name="languages"
            value={selectedLanguage}
            onChange={handleLanguageChange}
          />
          {showError && <p>{getTranslation("Please add a languages", t)}.</p>}
        </div>
        <button className="primary-btn" onClick={() => handleSave()}>
          {getTranslation("Save", t)}
        </button>
      </div>
    </div>
  );
};

export default Settings;
