import React, { useState } from "react";
import "../../styles/shared/header.css";
import { getIcon } from "../../utils/iconUtils";
import LanguageDialog from "./LanguageDialog";
import MarketDialog from "./MarketDialog";
import SavingModal from "./SavingModal";
import { useTranslation } from "react-i18next";

const Header = ({
  getHeaderUserMarkets,
  headerUserMarkets,
  getUserBranches,
  userBranches,
  currentUser,
  updateUserSettings,
  isLoading,
}) => {
  // Set selected language from LocalStorage
  let selectedLng = localStorage.getItem("lng") || "en";
  let selectedLanguage = "";
  switch (selectedLng) {
    case "lva":
      selectedLanguage = "Latvian";
      break;
    case "est":
      selectedLanguage = "Estonian";
      break;
    case "ltu":
      selectedLanguage = "Lithuanian";
      break;
    case "en":
      selectedLanguage = "English";
      break;
    default:
      selectedLanguage = "English";
      break;
  }
  const { t, i18n } = useTranslation();

  //useStates
  const [languageVisible, setLanguageVisible] = useState(false);
  const [marketVisible, setMarketVisible] = useState(false);
  const [selectedHeader, setSelectedHeader] = useState("");
  const [dropdownState, setDropdownState] = useState({
    language: selectedLanguage,
  });
  const [selectedMarket, setSelectedMarket] = useState({
    marketDescription: currentUser.settings.marketDescription,
    marketId: currentUser.settings.marketId,
  });
  const [selectedBranch, setSelectedBranch] = useState({
    branchDescription: currentUser.settings.branchDescription,
    branchId: currentUser.settings.branchId,
  });
  const [dialogValues, setDialogValues] = useState({
    back: false,
  });

  //Language Dummy Data
  const languageData = [
    {
      language: "English",
      id: 1,
    },
    {
      language: "Latvian",
      id: 2,
    },
    {
      language: "Lithuanian",
      id: 3,
    },
    {
      language: "Estonian",
      id: 4,
    },
  ];

  //Handle Language Toggle Dialog
  const toggleLanguageDialog = () => {
    setLanguageVisible(!languageVisible);
  };
  //Handle Market Toggle Dialog
  const toggleMarketDialog = () => {
    setMarketVisible(!marketVisible);
    setSelectedMarket({
      marketDescription: currentUser.settings.marketDescription,
      marketId: currentUser.settings.marketId,
    });
  };
  //Handle changing language value
  const handleLanguageChange = (e) => {
    setDropdownState({
      ...dropdownState,
      language: e.target.value.language,
    });
  };

  //Handle accept language value
  const handleAcceptLanguage = () => {
    toggleLanguageDialog();
    let lng = "";
    switch (dropdownState.language) {
      case "Latvian":
        lng = "lva";
        break;
      case "Estonian":
        lng = "est";
        break;
      case "Lithuanian":
        lng = "ltu";
        break;
      default:
        lng = "en";
        break;
    }
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  //handle Market modal Cancel button
  const handleMarketCancel = () => {
    setSelectedMarket({
      marketDescription: currentUser.settings.marketDescription,
      marketId: currentUser.settings.marketId,
    });
    setSelectedBranch({
      branchDescription: currentUser.settings.branchDescription,
      branchId: currentUser.settings.branchId,
    });

    toggleMarketDialog();
  };

  //handle Market modal Apply button
  const handleMarketApply = (e) => {
    setMarketVisible(!marketVisible);
    setDialogValues({ ...dialogValues, back: true });
  };

  //Handle No button in Modal Saving
  const handleModalNo = () => {
    setDialogValues({ ...dialogValues, back: false });
    setSelectedMarket({
      marketDescription: currentUser.settings.marketDescription,
      marketId: currentUser.settings.marketId,
    });
  };

  //Handle Yes button in Modal Saving
  const handleModalSaving = () => {
    const settings = currentUser.settings;
    let payload = {
      userSettingsId: settings.userSettingsId,
      userId: currentUser.userId,
    };

    if (selectedMarket.marketId !== currentUser.settings.marketId) {
      payload.marketId = selectedMarket.marketId;
    }
    if (selectedBranch.branchId !== currentUser.settings.branchId) {
      payload.branchId = selectedBranch.branchId;
    }

    setDialogValues({ ...dialogValues, back: false });
    updateUserSettings(JSON.stringify(payload));
    if (!isLoading) {
      const newPath = `${window.location.origin}/vehicles`;
      window.location.replace(newPath);
    }
  };

  const handleMarketDropdownOpen = (e) => {
    getHeaderUserMarkets();
  };

  const handleBranchDropdownOpen = (e) => {
    getUserBranches(selectedMarket.marketId);
  };

  //Handle changing Market value
  const handleMarketChange = (e) => {
    if (e.target.name === "market") {
      setSelectedMarket(e.target.value);
      setSelectedBranch({
        branchDescription: "Select",
        branchId: null,
      });
    }
  };

  const handleBranchChange = (e) => {
    if (e.target.name === "branch") {
      setSelectedBranch(e.target.value);
    }
  };

  return (
    <div className={"header-container d-flex justify-content-end"}>
      {/* LANGUAGE SELECT */}
      <button onClick={toggleLanguageDialog} className="language-wrapper">
        <span className="header-label">
          {dropdownState.language === "Select"
            ? "English"
            : dropdownState.language}
        </span>
        <img src={getIcon("language.svg")} alt="languageIcon" />
      </button>
      {languageVisible && (
        <LanguageDialog
          toggleLanguageDialog={toggleLanguageDialog}
          handleAcceptLanguage={handleAcceptLanguage}
          handleLanguageChange={handleLanguageChange}
          languageData={languageData}
          dropdownState={dropdownState}
          selectedLanguage={selectedLanguage}
        />
      )}
      {/* MARKET SELECT */}
      <button
        onClick={() => {
          setSelectedHeader("market");
          toggleMarketDialog();
        }}
        className={"country-wrapper"}
      >
        <span className={"header-label"}>
          {currentUser.settings.marketDescription}
        </span>
        <img src={getIcon("country.svg")} alt="marketIcon" />
      </button>
      {/* BRANCH SELECT */}
      <button
        onClick={() => {
          setSelectedHeader("branch");
          toggleMarketDialog();
        }}
        className={"branch-wrapper"}
      >
        <span className={"header-label"}>
          {currentUser.settings.branchDescription}
        </span>
        <img src={getIcon("brand.svg")} alt="branchIcon" />
      </button>
      {marketVisible && (
        <MarketDialog
          toggleMarketDialog={toggleMarketDialog}
          handleMarketChange={handleMarketChange}
          handleMarketDropdownOpen={handleMarketDropdownOpen}
          handleBranchChange={handleBranchChange}
          handleBranchDropdownOpen={handleBranchDropdownOpen}
          marketData={headerUserMarkets}
          branchData={userBranches}
          currentSettings={currentUser.settings}
          selectedMarket={selectedMarket}
          selectedBranch={selectedBranch}
          selectedHeader={selectedHeader}
          onCancel={handleMarketCancel}
          onApply={handleMarketApply}
          dialogValues={dialogValues}
          setDialogValues={dialogValues}
        />
      )}
      {["market", "branch"].includes(selectedHeader) && dialogValues.back && (
        <SavingModal
          message={
            "Changing the market will reset the application. Any unsaved changes will be lost."
          }
          onclickHandleNo={handleModalNo}
          onclickHandleYes={handleModalSaving}
        />
      )}
    </div>
  );
};

export default Header;
