/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../styles/vehicles/vehicles.css";
import { useNavigate } from "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import pencil from "../../assets/icons/pencil.svg";
import { getIcon } from "../../utils/iconUtils";
import ConfirmationModal from "./ConfirmationModal";
import ImportVehicleDialog from "./ImportVehicleDialog";
import fetchClient from "../../api/fetch";
import ImportModalSuccess from "./ImportModalSuccess";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const Vehicles = ({
  getVehiclesList,
  vehiclesList,
  vehiclesListPage,
  deleteVehicle,
  setSuccessModalValues,
  getVehicleData,
  vehicleData,
  marketSettings,
  setVehicleLanguageId,
  languages,
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const [languageId, setLanguageId] = useState(null);
  const [countryCodeId, setCountryCodeId] = useState(null);
  const [page, setPage] = useState({
    skip: 0,
    take: 10,
  });
  const [params, setParams] = useState({
    sort: "",
  });
  const [sort, setSort] = useState([]);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showImportVehicleModal, setShowImportVehicleModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadEvent, setUploadEvent] = useState({
    success: false,
    error: false,
    message: "",
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (marketSettings) {
      setLanguageId(marketSettings.languageId);
      setCountryCodeId(
        marketSettings.defaultLanguage?.substr(0, 3).toUpperCase()
      );
    }

    getVehiclesList(queryBuilder());
  }, [page, params, marketSettings]);

  const queryBuilder = () => {
    return `?countryCodeId=${countryCodeId}&languageId=${languageId}&page=${JSON.stringify(
      page
    )}`;
  };

  const handleSort = (e) => {
    setSort(e.sort);
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `&sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      skip: e.page.skip,
      take,
    });
  };

  const handleDelete = (dataItem) => {
    setSelectedVehicle(dataItem);
    setShowConfirmationModal(true);
  };

  const handleEdit = (dataItem) => {
    setVehicleLanguageId(dataItem.languageId);
    localStorage.setItem(
      "vehicleLanguage",
      JSON.stringify(
        languages.filter((lang) => lang.languageId === dataItem.languageId)[0]
      )
    );
    Navigate(`/vehicles/edit-vehicle/${dataItem.vehicleId}`);
  };

  const actionCell = ({ dataItem }) => {
    return (
      <td>
        <div className="actions-cell">
          <div>
            <img
              className="edit-icon"
              src={pencil}
              alt="category-edit"
              onClick={() => handleEdit(dataItem)}
            />
          </div>
          <div>
            <img
              className="delete-icon"
              src={getIcon("trash.svg")}
              alt="category-delete"
              onClick={() => handleDelete(dataItem)}
            />
          </div>
        </div>
      </td>
    );
  };

  const toggleImportModal = () => {
    setShowImportVehicleModal(!showImportVehicleModal);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setMsg("");
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);

    setMsg("Uploading...");
    setUploading(true); // Start uploading
    setUploadProgress(0); // Reset progress

    fetchClient("formData")
      .post("v1/import", formData, {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgress(progress);
        },
      })
      .then((res) => {
        setMsg("Upload Successful");
        setUploading(false); // Upload complete
        setShowImportVehicleModal(false);
        setUploadEvent({
          ...uploadEvent,
          success: true,
          message: getTranslation("Import successful!", t),
        });

        setTimeout(() => {
          setUploadEvent({
            ...uploadEvent,
            success: false,
            message: "",
          });
          getVehiclesList(queryBuilder());
        }, 3000);
      })
      .catch((err) => {
        setMsg("Upload Error");
        setUploading(false); // Upload failed
        setShowImportVehicleModal(false);
        setUploadEvent({
          ...uploadEvent,
          error: true,
          message: getTranslation("Import failed! Please try again.", t),
        });
      })
      .finally(() => {
        setMsg("");
      });
  };

  const handleClose = () => {
    setShowImportVehicleModal(false);
    setSelectedFile(null);
    setMsg("");
    setUploading(false); // Upload failed
  };

  const handleCloseSuccess = () => {
    setUploadEvent({
      ...uploadEvent,
      success: false,
      error: false,
    });
    setPage({ ...page, skip: 0 });
  };

  return (
    <div className="vehicles-container">
      {showConfirmationModal && (
        <ConfirmationModal
          setShowConfirmationModal={setShowConfirmationModal}
          deleteVehicle={deleteVehicle}
          selectedVehicle={selectedVehicle}
        />
      )}
      {showImportVehicleModal && (
        <ImportVehicleDialog
          toggleImportModal={toggleImportModal}
          setShowImportVehicleModal={setShowImportVehicleModal}
          handleFileUpload={handleFileUpload}
          handleFileChange={handleFileChange}
          selectedFile={selectedFile}
          uploading={uploading}
          uploadProgress={uploadProgress}
          msg={msg}
          handleClose={handleClose}
        />
      )}

      {uploadEvent.success && (
        <ImportModalSuccess
          handleCloseSuccess={handleCloseSuccess}
          uploadEvent={uploadEvent}
          setUploadEvent={setUploadEvent}
        />
      )}
      {uploadEvent.error && (
        <ImportModalSuccess
          handleCloseSuccess={handleCloseSuccess}
          uploadEvent={uploadEvent}
          setUploadEvent={setUploadEvent}
        />
      )}
      <p className="h1 mb-4">{getTranslation("Vehicles", t)}</p>
      <div className="module-cont">
        <div className="header d-flex">
          <button
            onClick={() => Navigate(`/vehicles/create-vehicle`)}
            className="d-flex justify-content-center align-items-center gap-2 primary-btn"
          >
            <p className="m-0">{getTranslation("Create Vehicle", t)}</p>
          </button>
          <button
            className="offset-1 d-flex justify-content-center align-items-center gap-2 primary-btn"
            // disabled
            onClick={toggleImportModal}
          >
            <p className="m-0">{getTranslation("Import Vehicles", t)}</p>
          </button>
        </div>
        <div className="vehicles-table-grid">
          <Grid
            data={vehiclesList}
            skip={page.skip}
            take={page.take}
            total={vehiclesListPage.totalRecords}
            pageable={{
              buttonCount:
                vehiclesListPage.totalPages > 10
                  ? 10
                  : vehiclesListPage.totalPages,
              pageSizes: [5, 10, 15],
              pageSizeValue,
            }}
            onPageChange={pageChange}
          >
            <GridColumn field="vrm" title={getTranslation("VRM", t)} />
            <GridColumn
              field="makeTranslation"
              title={getTranslation("Make", t)}
            />
            <GridColumn field="model" title={getTranslation("Model", t)} />
            <GridColumn field="modelYear" title={getTranslation("Year", t)} />
            <GridColumn field="color" title={getTranslation("Color", t)} />
            <GridColumn
              field="location"
              title={getTranslation("Location", t)}
            />
            <GridColumn field="" title="" cell={actionCell} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Vehicles;
