import { all } from "redux-saga/effects";
import campaignSaga from "./campaigns";
import usersSaga from "./users";
import marketSettingsSaga from "./marketSettings";
import leadsSaga from "./leads";
import commonSagas from "./common";
import vehicleSelectorSaga from "./vehicleSelector";
import portfolioSaga from "./portfolio";
import serviceAlertsSaga from "./serviceAlerts";
import searchModuleSaga from "./searchModule";
import exportSaga from "./export";
import specialistsSaga from "./specialists";
import reportsSaga from "./reports";
import vehiclesSaga from "./vehicles";

const rootSagas = function* rootSagas() {
  yield all([
    campaignSaga(),
    usersSaga(),
    marketSettingsSaga(),
    leadsSaga(),
    commonSagas(),
    vehicleSelectorSaga(),
    portfolioSaga(),
    serviceAlertsSaga(),
    searchModuleSaga(),
    exportSaga(),
    specialistsSaga(),
    reportsSaga(),
    vehiclesSaga()
  ]);
};

export default rootSagas;
