import React, { cloneElement } from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import "../../styles/reports/conversionReport.css";
import { Checkbox } from "@progress/kendo-react-inputs";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const ReportsDropdown = ({
  name,
  textfield,
  data,
  dataItemKey,
  value,
  handleOpen,
  handleChange
}) => {
  const { t } = useTranslation();
  //Custom Multiselect
  const itemRender = (li, itemProps) => {
    const valueIds = value.map((item) => item[name]);

    const checkboxValue = () => {
      // if length of selected is equal to all options minus one,
      // then all area area selected
      return value?.length === data?.length - 1
        ? true
        : valueIds.includes(itemProps.dataItem[name]);
    };

    const itemChildren = (
      <div className="reports-dropdown d-flex gap-2 option m-1">
        <Checkbox value={checkboxValue()} />
        <p className="mb-0">{li.props.children}</p>
      </div>
    );
    return cloneElement(li, li.props, itemChildren);
  };

  return (
    <>
      <MultiSelect
        autoClose={false}
        placeholder={getTranslation("Select", t)}
        itemRender={itemRender}
        name={name}
        textField={textfield}
        data={data}
        dataItemKey={dataItemKey}
        value={value}
        onOpen={handleOpen}
        onChange={handleChange}
        tags={
          value?.length >= 1
            ? value.length === data.length - 1
              ? [{ text: getTranslation("All", t), data: [...value] }]
              : [
                  {
                    text: value.map((item) => item[textfield]).join(", "),
                    data: [...value]
                  }
                ]
            : undefined
        }
      />
    </>
  );
};

export default ReportsDropdown;
