import axios from "axios";
import Cookie from "./Cookies";
import store from "../store";

const BASE_URL = `${window.location.origin}/api`;

const fetchClient = (contentType) => {
  const client = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type":
        contentType === "formData" ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${Cookie.get("token")}`,
    },
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      switch (error.response.status) {
        case 401:
          getchToken()
            .post(`${BASE_URL}/api/v1/auth/refresh"`)
            .then((res) => {
              Cookie.set("token", res.data.token);
              Cookie.set("rftoken", res.data.refreshtoken);

              error.config.headers[
                "Authorization"
              ] = `Bearer ${res.data.token}`;

              return axios.request(error.config);
            })
            .catch((err) => {
              if (err.response.status === 401) {
                store.dispatch({ type: "SET_AUTH", action: false });
                Cookie.remove("token");
                Cookie.remove("rftoken");
                window.location.href = `${window.location.origin}/invalid-identity`;
              }
            });

          break;

        case 403:
          window.location.href = `${window.location.origin}/invalid-market`;
          break;

        case 405:
          window.location.href = `${window.location.origin}/invalid-identity`;
          break;

        case 404:
          // NOT FOUND

          break;

        default:
          break;
      }
      return Promise.reject(error);
    }
  );
  return client;
};

export const getchToken = () => {
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookie.get("rftoken")}`,
    },
  });
};

export default fetchClient;
