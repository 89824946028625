import React from "react";
import "../../styles/shared/header.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const MarketDialog = ({
  handleMarketChange,
  handleMarketDropdownOpen,
  handleBranchChange,
  handleBranchDropdownOpen,
  marketData,
  branchData,
  selectedMarket,
  selectedBranch,
  selectedHeader,
  currentSettings,
  onCancel,
  onApply,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog>
        <div className="market-dialog-box">
          <span className="dialog-title">
            {getTranslation("Change market and branch", t)}
          </span>
          <div className="market-dropdown">
            <p>{getTranslation("Market", t)}</p>
            <DropDownList
              className="market-dropdown-list"
              name="market"
              data={marketData}
              textField="marketDescription"
              dataItemKey="marketId"
              value={selectedMarket}
              onChange={(e) => {
                handleMarketChange(e);
              }}
              onOpen={handleMarketDropdownOpen}
            />
          </div>
          <div className="branch-dropdown">
            <p>{getTranslation("Branch", t)}</p>
            <DropDownList
              className="branch-dropdown-list"
              name="branch"
              data={branchData}
              dataItemKey="branchId"
              textField="branchDescription"
              value={selectedBranch}
              onChange={(e) => {
                handleBranchChange(e);
              }}
              onOpen={handleBranchDropdownOpen}
            />
          </div>
          <div className="dialog-footer">
            <div>
              <button className="dialog-btn bg-none-btn" onClick={onCancel}>
                {getTranslation("Cancel", t)}
              </button>
            </div>
            <div>
              <button
                className="dialog-btn primary-btn"
                onClick={onApply}
                disabled={
                  (selectedHeader === "market"
                    ? currentSettings.marketId ===
                      selectedMarket.marketId
                    : selectedHeader === "branch"
                    ? currentSettings.branchId ===
                      selectedBranch.branchId
                    : false) || selectedBranch.branchDescription === "Select"
                }
              >
                {getTranslation("Accept", t)}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default MarketDialog;
