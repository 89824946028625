const columns = [
  {
    width: "115px",
    title: "Select All",
    isShown: true,
    canBeHidden: false
  },
  {
    column: "statusName",
    width: "180px",
    title: "Status",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "typeName",
    width: "150px",
    title: "Lead Type",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "specialist",
    width: "220px",
    title: "Specialist",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "lastActivity",
    width: "180px",
    title: "Last Activity",
    filter: "date",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "financeType",
    width: "150px",
    title: "Finance Type",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "customerName",
    width: "200px",
    title: "Customer Name",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "bankOrLender",
    width: "180px",
    title: "Bank/Seller",
    filter: "text",
    isShown: true,
    canBeHidden: true
  }
];

export default columns;
