/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { getIcon } from "../../utils/iconUtils";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import "../../styles/vehicles/vehiclesForm.css";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const TechSpecsFeaturesForm = ({
  specsCategories,
  features,
  specsValues,
  addRow,
  deleteRow,
  handleSpecsCategoriesChange,
  handleFeaturesChange,
  handleSpecsValuesChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="tech-specs-featurees-form-container">
      <div className="d-flex gap-5">
        <div className="specifications">
          <div>
            <label>
              {getTranslation("Technical Specifications Categories", t)}
            </label>
            <br />
            <button
              className="primary-btn add-row "
              onClick={() => addRow("specsCategories")}
            >
              <label>{getTranslation("Add Row", t)}</label>
            </button>
            <div className="d-flex">
              <div className="specs-values-label">
                <label>{getTranslation("Label", t)}*</label>
              </div>
            </div>
          </div>
          <div className="category-items mb-4">
            {specsCategories.map((spec, i) => {
              if (spec)
                return (
                  <div className="d-flex categories">
                    <div className="category-input">
                      <Input
                        className={`k-input-flat ${
                          spec.hasError ? "error-input " : ""
                        }`}
                        key={spec.name}
                        name={spec.name}
                        value={spec.value}
                        onChange={handleSpecsCategoriesChange}
                      />
                      {spec.hasError && (
                        <span className="error-text">
                          <p>{getTranslation("This field is required.", t)}</p>
                        </span>
                      )}
                    </div>
                    <div className="category-delete">
                      <img
                        className="delete-icon"
                        key={spec.name}
                        src={getIcon("trash.svg")}
                        alt="category-delete"
                        onClick={() => deleteRow("specsCategories", i)}
                      />
                    </div>
                  </div>
                );
            })}
          </div>
          <div>
            <label>Features</label>
            <br />
            <button
              className="primary-btn add-row"
              onClick={() => addRow("features")}
            >
              <label>{getTranslation("Add Row", t)}</label>
            </button>
            <div className="d-flex">
              <div className="features-values-label">
                <label>{getTranslation("Label", t)}*</label>
              </div>
              <div className="features-values-value">
                <label>{getTranslation("Value", t)}*</label>
              </div>
            </div>
          </div>
          <div className="category-items">
            {features.map((feature, i) => {
              if (feature)
                return (
                  <div className="d-flex categories">
                    <div className="category-input">
                      <Input
                        className={`k-input-flat ${
                          feature.label.hasError ? "error-input " : ""
                        }`}
                        key={feature.label.name}
                        name={feature.label.name}
                        value={feature.label.value}
                        onChange={handleFeaturesChange}
                      />
                      {feature.label.hasError && (
                        <span className="error-text">
                          <p>{getTranslation("This field is required.", t)}</p>
                        </span>
                      )}
                    </div>
                    <div className="category-input">
                      <Input
                        className={`k-input-flat ${
                          feature.value.hasError ? "error-input " : ""
                        }`}
                        key={feature.value.name}
                        name={feature.value.name}
                        value={feature.value.value}
                        onChange={handleFeaturesChange}
                      />
                      {feature.value.hasError && (
                        <span className="error-text">
                          <p>{getTranslation("This field is required.", t)}</p>
                        </span>
                      )}
                    </div>
                    <div className="category-delete">
                      <img
                        className="delete-icon"
                        key={feature.name}
                        src={getIcon("trash.svg")}
                        alt="category-delete"
                        onClick={() => deleteRow("features", i)}
                      />
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
        <div className="specifications-values">
          <label>{getTranslation("Technical Specifications Values", t)}</label>
          <br />
          <button
            className="primary-btn add-row"
            onClick={() => addRow("specsValues")}
          >
            <label>{getTranslation("Add Row", t)}</label>
          </button>
          <div className="d-flex">
            <div className="specifications-values-label">
              <label>{getTranslation("Label", t)}*</label>
            </div>
            <div className="specifications-values-value">
              <label>{getTranslation("Value", t)}*</label>
            </div>
            <div className="specifications-values-category">
              <label>{getTranslation("Category", t)}*</label>
            </div>
          </div>
          <div className="specs-values-category-items">
            {specsValues?.map((spec, i) => {
              if (spec)
                return (
                  <div className="d-flex categories">
                    <div className="d-flex category-input gap-5">
                      <div className="specifications-values-inputs">
                        <Input
                          className={`k-input-flat ${
                            spec.label.hasError ? "error-input " : ""
                          }`}
                          key={spec.label.name}
                          name={spec.label.name}
                          value={spec.label.value}
                          onChange={(e) => handleSpecsValuesChange(e, i)}
                        />
                        {spec.label.hasError && (
                          <span className="error-text">
                            <p>
                              {getTranslation("This field is required.", t)}
                            </p>
                          </span>
                        )}
                      </div>
                      <div className="specifications-values-inputs">
                        <Input
                          className={`k-input-flat ${
                            spec.value.hasError ? "error-input " : ""
                          }`}
                          key={spec.value.name}
                          name={spec.value.name}
                          value={spec.value.value}
                          onChange={(e) => handleSpecsValuesChange(e, i)}
                        />
                        {spec.value.hasError && (
                          <span className="error-text">
                            <p>
                              {getTranslation("This field is required.", t)}
                            </p>
                          </span>
                        )}
                      </div>
                      <div className="specifications-values-inputs">
                        <DropDownList
                          data={specsCategories.filter(
                            (spec) => spec.value !== ""
                          )}
                          className={`k-input-flat ${
                            spec.category.hasError ? "error-input " : ""
                          }`}
                          value={spec.category}
                          name={spec.category.name}
                          key={spec.category.name}
                          textField="value"
                          dataItemKey="id"
                          onChange={(e) => handleSpecsValuesChange(e, i)}
                        />
                        {spec.category.hasError && (
                          <span className="error-text">
                            {specsCategories.length > 0 &&
                            specsCategories[0].value !== "" ? (
                              <p>
                                {getTranslation("This field is required.", t)}
                              </p>
                            ) : (
                              <p>
                                Please add a Technical Specifications Categories
                              </p>
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="category-delete">
                      <img
                        className="delete-icon"
                        key={spec.name}
                        src={getIcon("trash.svg")}
                        alt="category-delete"
                        onClick={() => deleteRow("specsValues", i)}
                      />
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechSpecsFeaturesForm;
