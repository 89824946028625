import React, { useEffect, useState } from "react";
import "../../styles/reports/conversionReport.css";
import { getIcon } from "../../utils/iconUtils";
import { useNavigate } from "react-router-dom";
import chartIcon from "../../assets/icons/chart-icon.png";
import chartIdleIcon from "../../assets/icons/chart-idle.png";
import gridIcon from "../../assets/icons/grid-icon.png";
import gridIdleIcon from "../../assets/icons/grid-idle.png";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTooltip
} from "@progress/kendo-react-charts";
import samplePerformanceData from "../dashboard/samplePerformanceData";
import "hammerjs";
import { DateInput, DateRangePicker } from "@progress/kendo-react-dateinputs";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import sampleConversionData from "./sampleConversionData";
import ReportsDropdown from "./ReportsDropdown";
import fetchClient from "../../api/fetch";
import { conversionReportGrid } from "./gridColumn";
import { format } from "date-fns";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { DateFilterCell } from "../shared/DateFilterCell";

const ConversionReport = ({
  currentUser,
  setLoading,
  reportsConversionGridList,
  getReportsConversionGridList,
  reportsConversionGridListPage,
  exportConversionReportToExcel
}) => {
  const Navigate = useNavigate();
  const { t } = useTranslation();

  // Dropdown initial payload
  const initialPayload = {
    regionId: [],
    marketId: [],
    brandId: [],
    branchId: [],
    campaignId: []
  };

  const initialDateValue = {
    start: null,
    end: null
  };

  const getFunnelColor = (type) => {
    switch (type) {
      case "Opportunities":
        return "#16BAE7";
      case "Leads":
        return "#B7CD00";
      case "Appointed":
        return "#9E61A4";
      case "Attended":
        return "#7887fb";
      case "Sold":
        return "#00233a";
      default:
        return;
    }
  };

  const getStatusChart = (status) => {
    switch (status) {
      case "Opportunities":
        return "blue-status";
      case "Leads":
        return "green-status";
      case "Appointed":
        return "purple-status";
      case "Attended":
        return "indigo-status";
      case "Sold":
        return "dark-status";
      default:
        return;
    }
  };

  //useStates
  const [conversionView, setConversionView] = useState("chart");
  const [conversionDateValue, setConversionDateValue] =
    useState(initialDateValue);
  const [payload, setPayload] = useState(initialPayload);
  const [dropdownData, setDropdownData] = useState(initialPayload);
  const [isChanged, setIsChanged] = useState(false);
  const [funnelData, setFunnelDate] = useState([]);
  const [conversionGridSetting, setConversionGridSetting] = useState({
    sort: [],
    filter: undefined,
    pageSizeValue: 10,
    page: {
      skip: 0,
      take: 10
    }
  });
  const [params, setParams] = useState({
    sort: "",
    filter: ""
  });

  useEffect(() => {
    if (conversionView === "chart") {
      let params = "";
      for (const item in payload) {
        const tempData = dropdownData[item];
        const index = item.indexOf("Id");
        if (payload[item].length === 0 && tempData.length === 0) {
          continue;
        } else if (payload[item].length === 0 && tempData.length !== 0) {
          params += `${params ? "&" : ""}${item.slice(0, index)}=NA`;
        } else {
          const ids = payload[item].map((i) => i[item]).toString();
          params += `${params ? "&" : ""}${item.slice(0, index)}=${ids}`;
        }
      }
      if (conversionDateValue.start) {
        params += `${params ? "&" : ""}startDate=${format(
          conversionDateValue.start,
          "yyyy-MM-dd HH:mm:ss.SSS"
        )}`;
      }
      if (conversionDateValue.end) {
        params += `${params ? "&" : ""}endDate=${format(
          conversionDateValue.end,
          "yyyy-MM-dd HH:mm:ss.SSS"
        )}`;
      }
      setLoading(true);
      fetchClient()
        .get("/v1/reports/conversion/graph?" + params)
        .then((res) => {
          const data = res.data.data.map((e) => ({
            statusName: e.statusName,
            status: e.status,
            value: e.rawValue,
            percent: e.formatted,
            color: getFunnelColor(e.statusName)
          }));
          setFunnelDate(data);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    } else {
      getReportsConversionGridList(queryBuilder());
    }
  }, [
    isChanged,
    conversionView,
    conversionDateValue,
    conversionGridSetting.page,
    params
  ]);

  const handleBack = () => {
    Navigate("/reports");
  };

  const tooltipRender = (props) => {
    if (props.point) {
      return props.point.dataItem.statusName;
    }
  };

  //Query builder for grid data
  const queryBuilder = () => {
    let dropdownParams = "";
    for (const item in payload) {
      const tempData = dropdownData[item];
      const index = item.indexOf("Id");
      if (payload[item].length === 0 && tempData.length === 0) {
        continue;
      } else if (payload[item].length === 0 && tempData.length !== 0) {
        dropdownParams += `&${item.slice(0, index)}=NA`;
      } else {
        const ids = payload[item].map((i) => i[item]).toString();
        dropdownParams += `&${item.slice(0, index)}=${ids}`;
      }
    }
    if (conversionDateValue.start) {
      dropdownParams += `&startDate=${format(
        conversionDateValue.start,
        "yyyy-MM-dd HH:mm:ss.SSS"
      )}`;
    }
    if (conversionDateValue.end) {
      dropdownParams += `&endDate=${format(
        conversionDateValue.end,
        "yyyy-MM-dd HH:mm:ss.SSS"
      )}`;
    }
    return `?filters=[${params.filter}]${params.sort}&page=${JSON.stringify(
      conversionGridSetting.page
    )}${dropdownParams}`;
  };

  //Query builder for exporting without page params
  const filterDownloadBuilder = () => {
    let dropdownParams = "";
    for (const item in payload) {
      const tempData = dropdownData[item];
      const index = item.indexOf("Id");
      if (payload[item].length === 0 && tempData.length === 0) {
        continue;
      } else if (payload[item].length === 0 && tempData.length !== 0) {
        dropdownParams += `&${item.slice(0, index)}=NA`;
      } else {
        const ids = payload[item].map((i) => i[item]).toString();
        dropdownParams += `&${item.slice(0, index)}=${ids}`;
      }
    }
    if (conversionDateValue.start) {
      dropdownParams += `&startDate=${format(
        conversionDateValue.start,
        "yyyy-MM-dd HH:mm:ss.SSS"
      )}`;
    }
    if (conversionDateValue.end) {
      dropdownParams += `&endDate=${format(
        conversionDateValue.end,
        "yyyy-MM-dd HH:mm:ss.SSS"
      )}`;
    }
    return `&filters=[${params.filter}]&${params.sort}${dropdownParams}`;
  };

  //Handle dropdown change
  const handleConversionDropdown = (e) => {
    setIsChanged(!isChanged);
    switch (e.target.name) {
      case "campaignId":
      case "regionId":
      case "marketId":
      case "brandId":
      case "branchId":
        // check if selectAll option is included in the multiselect values
        const selectAll = e.target.value.some((item) => item.id === 0);
        console.log(e.target.value);
        // get name of array without "Id"
        const index = e.target.name.indexOf("Id");
        if (selectAll) {
          // if payload array is equal to the available options minus one,

          // meaning all of the options are selected
          if (
            payload[e.target.name].length ===
            dropdownData[e.target.name].length - 1
          ) {
            // deselect all if select all option is clicked again
            setPayload({ ...payload, [e.target.name]: [] });
          } else {
            // select all options but remove the "select all" object
            setPayload({
              ...payload,
              [e.target.name]: dropdownData[e.target.name].filter(
                (item) =>
                  item[
                    `${
                      e.target.name === "campaignId"
                        ? "campaign"
                        : e.target.name.slice(0, index) + "Description"
                    }`
                  ] !== "All"
              )
            });
          }
        } else {
          // if not all options are selected, only store the checked ones
          setPayload({ ...payload, [e.target.name]: e.target.value });
        }
        break;
      default:
        setPayload({ ...payload, [e.target.name]: e.target.value });
        break;
    }
  };

  //Handle open dropdown change
  const handleOpenConversionDropdown = (name, route) => {
    const userId = currentUser.userInfo.userId;
    let tempData = "";
    let params = "";
    for (const item in payload) {
      tempData = dropdownData[item];
      const index = item.indexOf("Id");
      if (payload[item].length === 0 && tempData.length === 0) {
        continue;
      } else if (payload[item].length === 0 && tempData.length !== 0) {
        params += `${params ? "&" : ""}${item}=NA`;
      } else {
        const ids = payload[item].map((i) => i[item]).toString();
        params += `${params ? "&" : ""}${item}=${ids}`;
      }
    }
    const index = name.indexOf("Id");
    fetchClient()
      .get(
        name === "campaignId"
          ? `/v1/users/${userId}/campaigns?` + params
          : `/v1/users/${userId}/${route}?` + params
      )
      .then((res) => {
        tempData = dropdownData[name];
        setDropdownData({
          ...dropdownData,
          [name]:
            res.data.length > 0
              ? [
                  {
                    id: 0,
                    [name.slice(0, index) +
                    (name === "campaignId" ? "" : "Description")]: "All"
                  }
                ].concat(res.data)
              : []
        });
        if (tempData.length === payload[name].length) {
          setPayload({ ...payload, [name]: res.data });
        }
      })
      .catch((err) => console.log(err));
  };

  //Date handleChange
  const handleDate = (e) => {
    setConversionDateValue(e.target.value);
  };

  //Onclick for exporting excel
  const handleExportConversionReport = async () => {
    try {
      exportConversionReportToExcel(filterDownloadBuilder());
    } catch (error) {
      console.error("Export error", error);
    }
  };

  //Grid pageChange
  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.page.take;
    if (targetEvent.value) {
      setConversionGridSetting({
        ...conversionGridSetting,
        pageSizeValue: targetEvent.value
      });
    }
    setConversionGridSetting((prevState) => ({
      ...prevState,
      page: {
        ...prevState.page,
        skip: e.page.skip,
        take
      }
    }));
  };

  //Sort handlechange
  const handleSort = (e) => {
    setConversionGridSetting({
      ...conversionGridSetting,
      sort: e.sort
    });
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      console.log(obj);
      setParams({ ...params, sort: `&sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  //filter handlechange
  const handleFilterChange = (e) => {
    console.log(e.dataState.filter);
    setConversionGridSetting({
      ...conversionGridSetting,
      filter: e.dataState.filter
    });
    if (e.dataState.filter !== undefined) {
      setParams({
        ...params,
        filter: e.dataState.filter.filters.map((item) => {
          let obj = { ...item };
          obj["condition"] = obj["operator"];
          delete obj["operator"];
          obj = JSON.stringify(obj);
          return obj;
        })
      });
      setConversionGridSetting((prevState) => ({
        ...prevState,
        page: { ...prevState.page, skip: 0 }
      }));
    } else {
      setParams({ ...params, filter: "" });
    }
  };

  //Made custom start date for translation
  const customStartDateInput = (props) => {
    return (
      <label>
        <DateInput {...props} label={"Start"} />
      </label>
    );
  };

  //Made custom end date for translation
  const customEndDateInput = (props) => {
    return (
      <label>
        <DateInput {...props} label={"End"} />
      </label>
    );
  };

  return (
    <div className="conversion-report">
      <div className="d-flex gap-5 align-items-center mb-4 header">
        <img
          onClick={handleBack}
          src={getIcon("arrow-back.svg")}
          alt="back icon"
        />
        <p className="h1">{getTranslation("Conversion report", t)}</p>
      </div>
      <div className="conversion-report-cont w-100">
        <div className="conversion-report-header">
          <button
            onClick={() => {
              setConversionView("chart");
              setPayload(initialPayload);
              setConversionDateValue(initialDateValue);
              setDropdownData(initialPayload);
            }}
            className={`${
              conversionView === "chart" ? "primary-btn" : "btn-idle"
            }`}
          >
            {conversionView === "chart" ? (
              <img src={chartIcon} alt="chart icon" />
            ) : (
              <img src={chartIdleIcon} alt="chart idle icon" />
            )}
          </button>
          <button
            onClick={() => {
              setConversionView("grid");
              setPayload(initialPayload);
              setConversionDateValue(initialDateValue);
              setDropdownData(initialPayload);
            }}
            className={`${
              conversionView === "grid" ? "primary-btn" : "btn-idle"
            }`}
          >
            {conversionView === "grid" ? (
              <img src={gridIcon} alt="grid icon" />
            ) : (
              <img src={gridIdleIcon} alt="grid idle icon" />
            )}
          </button>
          <button
            className="primary-btn"
            onClick={handleExportConversionReport}
          >
            <span>{getTranslation("Export to excel", t)}</span>
          </button>
        </div>
        <div className="conversion-grid-header">
          <div>
            <p>{getTranslation("Region", t)}</p>
            <ReportsDropdown
              name="regionId"
              dataItemKey="regionId"
              data={dropdownData.regionId}
              textfield="regionDescription"
              value={payload.regionId}
              handleOpen={() =>
                handleOpenConversionDropdown("regionId", "regions")
              }
              handleChange={handleConversionDropdown}
            />
          </div>
          <div>
            <p>{getTranslation("Market", t)}</p>
            <ReportsDropdown
              name="marketId"
              dataItemKey="marketId"
              data={dropdownData.marketId}
              textfield="marketDescription"
              value={payload.marketId}
              handleOpen={() =>
                handleOpenConversionDropdown("marketId", "markets")
              }
              handleChange={handleConversionDropdown}
            />
          </div>
          <div>
            <p>{getTranslation("Brand", t)}</p>
            <ReportsDropdown
              name="brandId"
              dataItemKey="brandId"
              data={dropdownData.brandId}
              textfield="brandDescription"
              value={payload.brandId}
              handleOpen={() =>
                handleOpenConversionDropdown("brandId", "brands")
              }
              handleChange={handleConversionDropdown}
            />
          </div>
          <div>
            <p>{getTranslation("Branch", t)}</p>
            <ReportsDropdown
              name="branchId"
              dataItemKey="branchId"
              data={dropdownData.branchId}
              textfield="branchDescription"
              value={payload.branchId}
              handleOpen={() =>
                handleOpenConversionDropdown("branchId", "branches")
              }
              handleChange={handleConversionDropdown}
            />
          </div>
          <div>
            <p>{getTranslation("Campaign", t)}</p>
            <ReportsDropdown
              name="campaignId"
              dataItemKey="campaignId"
              data={dropdownData.campaignId}
              textfield="campaign"
              value={payload.campaignId}
              handleOpen={() =>
                handleOpenConversionDropdown("campaignId", "campaign")
              }
              handleChange={handleConversionDropdown}
            />
          </div>
          <div className="daterange-picker">
            <DateRangePicker
              format={"dd/MM/yyyy"}
              value={conversionDateValue}
              onChange={handleDate}
              startDateInput={customStartDateInput}
              endDateInput={customEndDateInput}
            />
          </div>
        </div>
        {conversionView === "chart" ? (
          <div className="conversion-report-chart">
            <div className="conversion-stat-holder">
              {funnelData?.map((item) => {
                return (
                  <div
                    className={`${getStatusChart(
                      item.statusName
                    )} chart-status d-flex gap-2`}
                  >
                    <span>{getTranslation(item.statusName, t)}</span>
                    {item.statusName !== "Opportunities" && (
                      <span>{`${item.percent}%`}</span>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="conversion-chart-holder">
              <Chart>
                <ChartSeries>
                  <ChartSeriesItem
                    type="funnel"
                    data={funnelData}
                    categoryField="statusName"
                    field="value"
                    colorField="color"
                    dynamicSlope={true}
                    dynamicHeight={false}
                  >
                    <ChartSeriesLabels background="none" format="N0" />
                  </ChartSeriesItem>
                </ChartSeries>
                <ChartTooltip render={tooltipRender} />
                <ChartLegend visible={false} />
              </Chart>
            </div>
          </div>
        ) : (
          <div className="conversion-grid-content">
            <div className="conversion-grid">
              <Grid
                data={reportsConversionGridList}
                skip={conversionGridSetting.page.skip}
                take={conversionGridSetting.page.take}
                total={reportsConversionGridListPage?.totalRecords}
                filter={conversionGridSetting.filter}
                sort={conversionGridSetting.sort}
                filterable={true}
                sortable={true}
                onSortChange={handleSort}
                onDataStateChange={handleFilterChange}
                pageable={{
                  buttonCount:
                    reportsConversionGridListPage?.totalPages > 10
                      ? 10
                      : reportsConversionGridListPage?.totalPages,
                  info: true,
                  pageSizes: [5, 10, 20],
                  pageSizeValue: conversionGridSetting.pageSizeValue
                }}
                onPageChange={pageChange}
              >
                {conversionReportGrid.map((column, idx) => {
                  return column.isShown ? (
                    <Column
                      key={idx}
                      field={column.column}
                      width={column.width}
                      title={column.title}
                      filterable={column.filter}
                      filter={column.filter}
                    />
                  ) : null;
                })}
              </Grid>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversionReport;
