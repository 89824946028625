import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getIcon } from "../../utils/iconUtils";
import "../../styles/vehicles/importModal.css";
import { BsCloudUpload } from "react-icons/bs";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const ImportVehicleDialog = ({
  handleFileUpload,
  handleFileChange,
  uploading,
  uploadProgress,
  msg,
  handleClose,
  selectedFile,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p className="m-0 import-vehicle-title">
            {getTranslation("Import Vehicle", t)}
          </p>
          <img
            className={uploading ? "close-icon-disabled" : "close-icon"}
            onClick={handleClose}
            src={getIcon("close.svg")}
            alt="close icon"
          />
        </div>
        <div className="import-vehicle-box d-flex flex-column align-items-center">
          <BsCloudUpload className="py-3" />
          <input
            className="mb-3 import-input"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
          />
          {uploading && <progress max="100" value={uploadProgress}></progress>}
          {msg && (
            <span
              className={`${
                msg === "Upload Error"
                  ? "msg-error"
                  : msg === "Upload Successful"
                  ? "msg-success"
                  : "msg"
              }`}
            >
              {msg}
            </span>
          )}

          <div className="d-flex align-items-end gap-5 mt-4">
            <a href="https://azeauglocdnepdnbvoa.azureedge.net/az-eau-glo-cn-pdn-bvoa/gsl/templates/raw_stock.csv">
              <span className="template">
                {getTranslation("Download Template", t)}
              </span>
            </a>
            <div className="d-flex gap-2">
              <button
                className="bg-none-btn cancelled-btn"
                onClick={handleClose}
                disabled={uploading}
              >
                {getTranslation("Cancel", t)}
              </button>
              <button
                className="primary-btn"
                onClick={handleFileUpload}
                disabled={selectedFile === null || uploading}
              >
                {getTranslation("Upload", t)}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ImportVehicleDialog;
