/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../styles/vehicles/vehiclesForm.css";
import { Input } from "@progress/kendo-react-inputs";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const SpecificationsForm = ({
  selectedLanguage,
  commonForm,
  handleCommonInputChange,
  specsForm,
  handleSpecsInputChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex specs-form">
      <div className="forms-input">
        {specsForm.map((form) => {
          if (selectedLanguage === form.language)
            return form.data.map((item) => {
              return (
                <>
                  {item.column === 1 && (
                    <div className="vehicles-form-inputs">
                      <label>
                        {getTranslation(item.label, t)}
                        {item.isRequired && "*"}
                      </label>
                      <Input
                        className={`k-input-flat ${
                          item.isRequired && item.hasError ? "error-input " : ""
                        }`}
                        disabled={item.isDisabled}
                        name={item.name}
                        value={item.value}
                        onChange={handleSpecsInputChange}
                      />
                      {item.isRequired && item.hasError && (
                        <span className="error-text">
                          <p>{getTranslation("This field is required.", t)}</p>
                        </span>
                      )}
                    </div>
                  )}
                </>
              );
            });
        })}
        {commonForm.map((item) => {
          return (
            <>
              {item.column === 1 && item.form === "specs" && (
                <div className="vehicles-form-inputs">
                  <label>
                    {getTranslation(item.label, t)}
                    {item.isRequired && "*"}
                  </label>
                  <Input
                    className={`k-input-flat ${
                      item.isRequired && item.hasError ? "error-input " : ""
                    }`}
                    type={
                      ["door", "c02Emission"].includes(item.name)
                        ? "number"
                        : "input"
                    }
                    step={
                      ["door", "c02Emission"].includes(item.name) ? "0.01" : ""
                    }
                    disabled={item.isDisabled}
                    name={item.name}
                    value={item.value}
                    onChange={handleCommonInputChange}
                  />
                  {item.isRequired && item.hasError && (
                    <span className="error-text">
                      <p>{getTranslation("This field is required.", t)}</p>
                    </span>
                  )}
                </div>
              )}
            </>
          );
        })}
      </div>
      <div className="forms-input">
        {specsForm.map((form) => {
          if (selectedLanguage === form.language)
            return form.data.map((item) => {
              return (
                <>
                  {item.column === 2 && (
                    <div className="vehicles-form-inputs">
                      <label>
                        {getTranslation(item.label, t)}
                        {item.isRequired && "*"}
                      </label>
                      <Input
                        className={`k-input-flat ${
                          item.isRequired && item.hasError ? "error-input " : ""
                        }`}
                        disabled={item.isDisabled}
                        name={item.name}
                        value={item.value}
                        onChange={handleSpecsInputChange}
                      />
                      {item.isRequired && item.hasError && (
                        <span className="error-text">
                          <p>{getTranslation("This field is required.", t)}</p>
                        </span>
                      )}
                    </div>
                  )}
                </>
              );
            });
        })}
        {commonForm.map((item) => {
          return (
            <>
              {item.column === 2 && item.form === "specs" && (
                <div className="vehicles-form-inputs">
                  <label>
                    {getTranslation(item.label, t)}
                    {item.isRequired && "*"}
                  </label>
                  <Input
                    className={`k-input-flat ${
                      item.isRequired && item.hasError ? "error-input " : ""
                    }`}
                    type={
                      ["combineFuelConsumption"].includes(item.name)
                        ? "number"
                        : "input"
                    }
                    step={
                      ["combineFuelConsumption"].includes(item.name)
                        ? "0.01"
                        : ""
                    }
                    disabled={item.isDisabled}
                    name={item.name}
                    value={item.value}
                    onChange={handleCommonInputChange}
                  />
                  {item.isRequired && item.hasError && (
                    <span className="error-text">
                      <p>{getTranslation("This field is required.", t)}</p>
                    </span>
                  )}
                </div>
              )}
            </>
          );
        })}
      </div>
      <div className="forms-input">
        {commonForm.map((item) => {
          return (
            <>
              {item.column === 3 && item.form === "specs" && (
                <div className="vehicles-form-inputs">
                  <label>
                    {getTranslation(item.label, t)}
                    {item.isRequired && "*"}
                  </label>
                  <Input
                    className={`k-input-flat ${
                      item.isRequired && item.hasError ? "error-input " : ""
                    }`}
                    type={
                      ["engineSize"].includes(item.name) ? "number" : "input"
                    }
                    step={["engineSize"].includes(item.name) ? "0.01" : ""}
                    disabled={item.isDisabled}
                    name={item.name}
                    value={item.value}
                    onChange={handleCommonInputChange}
                  />
                  {item.isRequired && item.hasError && (
                    <span className="error-text">
                      <p>{getTranslation("This field is required.", t)}</p>
                    </span>
                  )}
                </div>
              )}
            </>
          );
        })}
        {specsForm.map((form) => {
          if (selectedLanguage === form.language)
            return form.data.map((item) => {
              return (
                <>
                  {item.column === 3 && (
                    <div className="vehicles-form-inputs">
                      <label>
                        {getTranslation(item.label, t)}
                        {item.isRequired && "*"}
                      </label>

                      <Input
                        className={`k-input-flat ${
                          item.isRequired && item.hasError ? "error-input " : ""
                        }`}
                        disabled={item.isDisabled}
                        name={item.name}
                        value={item.value}
                        onChange={handleSpecsInputChange}
                      />
                      {item.isRequired && item.hasError && (
                        <span className="error-text">
                          <p>{getTranslation("This field is required.", t)}</p>
                        </span>
                      )}
                    </div>
                  )}
                </>
              );
            });
        })}
      </div>
      <div className="forms-input">
        {commonForm.map((item) => {
          return (
            <>
              {item.column === 4 && item.form === "specs" && (
                <div className="vehicles-form-inputs">
                  <label>
                    {getTranslation(item.label, t)}
                    {item.isRequired && "*"}
                  </label>
                  <Input
                    className={`k-input-flat ${
                      item.isRequired && item.hasError ? "error-input " : ""
                    }`}
                    type={
                      ["numberOfGear", "seat"].includes(item.name)
                        ? "number"
                        : "input"
                    }
                    step={
                      ["numberOfGear", "seat"].includes(item.name) ? "0.01" : ""
                    }
                    disabled={item.isDisabled}
                    name={item.name}
                    value={item.value}
                    onChange={handleCommonInputChange}
                  />
                  {item.isRequired && item.hasError && (
                    <span className="error-text">
                      <p>{getTranslation("This field is required.", t)}</p>
                    </span>
                  )}
                </div>
              )}
            </>
          );
        })}
        {specsForm.map((form) => {
          if (selectedLanguage === form.language)
            return form.data.map((item) => {
              return (
                <>
                  {item.column === 4 && (
                    <div className="vehicles-form-inputs">
                      <label>
                        {getTranslation(item.label, t)}
                        {item.isRequired && "*"}
                      </label>
                      <Input
                        className={`k-input-flat ${
                          item.isRequired && item.hasError ? "error-input " : ""
                        }`}
                        disabled={item.isDisabled}
                        name={item.name}
                        value={item.value}
                        onChange={handleSpecsInputChange}
                      />
                      {item.isRequired && item.hasError && (
                        <span className="error-text">
                          <p>{getTranslation("This field is required.", t)}</p>
                        </span>
                      )}
                    </div>
                  )}
                </>
              );
            });
        })}
      </div>
    </div>
  );
};

export default SpecificationsForm;
