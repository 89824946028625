export const getImageSize = (size) => {
  switch (size) {
    case 1:
      return "original";
    case 2:
      return "large";
    case 3:
      return "medium";
    case 4:
      return "small";
  }
};
