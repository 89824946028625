export const commonFormData = [
  {
    name: "vin",
    isRequired: true,
    type: "input",
    form: "main",
    hasError: false,
    value: "",
    label: "VIN",
    column: 2,
  },
  {
    name: "vrm",
    isRequired: true,
    type: "input",
    form: "main",
    hasError: false,
    value: "",
    label: "VRM",
    column: 2,
  },
  {
    name: "modelYear",
    isRequired: true,
    type: "input",
    form: "main",
    hasError: false,
    value: "",
    label: "Model Year",
    column: 1,
  },
  {
    name: "odometerReading",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "main",
    hasError: false,
    value: "",
    label: "Odometer Reading",
    column: 2,
  },
  {
    name: "registrationFee",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "main",
    hasError: false,
    value: "",
    label: "Registration Fee",
    column: 2,
  },
  {
    name: "registrationDate",
    isRequired: true,
    hasError: false,
    type: "date",
    form: "main",
    value: "",
    label: "Registration Date",
    column: 3,
  },
  {
    name: "originalPrice",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "main",
    hasError: false,
    value: "",
    label: "Original Price",
    column: 3,
  },
  {
    name: "soldDate",
    isRequired: false,
    isDisabled: true,
    hasError: false,
    type: "date",
    form: "main",
    value: "",
    label: "Sold Date",
    column: 4,
  },
  {
    name: "price",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "main",
    hasError: false,
    value: "",
    label: "Price",
    column: 4,
  },
  {
    name: "roadTax",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "main",
    hasError: false,
    value: "",
    label: "Road Tax",
    column: 3,
  },
  {
    name: "door",
    isRequired: true,
    type: "input",
    form: "specs",
    hasError: false,
    value: "",
    label: "Doors",
    column: 1,
  },
  {
    name: "c02Emission",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "specs",
    hasError: false,
    value: "",
    label: "cO2 Emissions",
    column: 1,
  },
  {
    name: "locationId",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "main",
    hasError: false,
    label: "Location ID",
    value: "",
    column: 1,
  },
  {
    name: "stockTypeId",
    isRequired: true,
    type: "dropdown",
    form: "main",
    hasError: false,
    value: "",
    label: "Stock Type",
    column: 1,
  },
  {
    name: "deliveryPrice",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "main",
    hasError: false,
    value: "",
    label: "Delivery Price",
    column: 4,
  },
  {
    name: "dataSource",
    isRequired: false,
    isDisabled: true,
    type: "input",
    form: "main",
    hasError: false,
    value: "",
    label: "Data Source",
    column: 4,
  },
  {
    name: "priceForTax",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "main",
    hasError: false,
    value: "",
    label: "Price for Tax",
    column: 3,
  },
  {
    name: "externalStockNumber",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "main",
    hasError: false,
    value: "",
    label: "Ext. Stock #",
    column: 2,
  },
  {
    name: "engineSize",
    isRequired: true,
    type: "dropdown",
    form: "specs",
    hasError: false,
    value: "",
    label: "Engine Size",
    column: 3,
  },
  {
    name: "numberOfGear",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "specs",
    hasError: false,
    value: "",
    label: "Gears Count",
    column: 4,
  },
  {
    name: "combineFuelConsumption",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "specs",
    hasError: false,
    value: "",
    label: "Combined Fuel Consumption",
    column: 2,
  },
  {
    name: "seat",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "specs",
    hasError: false,
    value: "",
    label: "Number of seats",
    column: 4,
  },
];

export const extraFormData = [
  {
    name: "extraData1",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData1",
  },
  {
    name: "extraData2",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData2",
  },
  {
    name: "extraData3",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData3",
  },
  {
    name: "extraData4",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData4",
  },
  {
    name: "extraData5",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData5",
  },
  {
    name: "extraData6",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData6",
  },
  {
    name: "extraData7",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData7",
  },
  {
    name: "extraData8",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData8",
  },
  {
    name: "extraData9",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData9",
  },
  {
    name: "extraData10",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData10",
  },
  {
    name: "extraData11",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData11",
  },
  {
    name: "extraData12",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData12",
  },
  {
    name: "extraData13",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData13",
  },
  {
    name: "extraData14",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData14",
  },
  {
    name: "extraData15",
    isRequired: false,
    isDisabled: false,
    type: "input",
    form: "extraData",
    hasError: false,
    value: "",
    label: "ExtraData15",
  },
];

export const mainFormData = [
  {
    name: "vehicleType",
    isRequired: true,
    type: "dropdown",
    form: "main",
    hasError: false,
    value: "",
    label: "Vehicle Type",
    column: 1,
  },
  {
    name: "displayVehicleDescription",
    isRequired: true,
    type: "input",
    hasError: false,
    value: "",
    label: "Display Desc.",
    column: 1,
  },
  {
    name: "shortDerivative",
    isRequired: false,
    isDisabled: false,
    hasError: false,
    type: "input",
    form: "main",
    value: "",
    label: "Short Derivative",
    column: 2,
  },
  {
    name: "make",
    isRequired: true,
    type: "input",
    hasError: false,
    value: "",
    label: "Make",
    column: 3,
  },
  {
    name: "model",
    isRequired: true,
    type: "input",
    form: "main",
    hasError: false,
    value: "",
    label: "Model",
    column: 4,
  },
  {
    name: "comment",
    isRequired: false,
    isDisabled: false,
    type: "input",
    hasError: false,
    value: "",
    label: "Comments",
    column: 0,
  },
];

export const specsFormData = [
  {
    name: "colourGroup",
    isRequired: true,
    type: "input",
    hasError: false,
    value: "",
    label: "Color Group",
    column: 1,
  },
  {
    name: "interiorTrim",
    isRequired: false,
    isDisabled: false,
    type: "input",
    hasError: false,
    value: "",
    label: "Int. Trim",
    column: 1,
  },
  {
    name: "bodyStyle",
    isRequired: true,
    type: "input",
    hasError: false,
    value: "",
    label: "Body Style",
    column: 2,
  },
  {
    name: "transmission",
    isRequired: true,
    hasError: false,
    type: "input",
    value: "",
    label: "Transmission",
    column: 2,
  },
  {
    name: "driveTrain",
    isRequired: true,
    type: "input",
    hasError: false,
    value: "",
    label: "Drive Train",
    column: 2,
  },
  {
    name: "fuelType",
    isRequired: true,
    type: "input",
    hasError: false,
    value: "",
    label: "Fuel Type",
    column: 3,
  },
  {
    name: "emmissionStandard",
    isRequired: true,
    type: "input",
    hasError: false,
    value: "",
    label: "Emissions Std.",
    column: 3,
  },
  {
    name: "exteriorColour",
    isRequired: false,
    isDisabled: false,
    type: "input",
    hasError: false,
    value: "",
    label: "Ext. Color",
    column: 4,
  },
];
