import { Input } from "@progress/kendo-react-inputs";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const UserInput = ({ label, name, handleChange, value, error, errors }) => {
  const { t } = useTranslation();

  return (
    <div className={`${error ? "error" : ""} col-12 col-md-6 mb-4`}>
      <label className="mb-2">{getTranslation(label, t)}</label>
      <Input
        className="k-input-flat"
        name={name}
        onChange={handleChange}
        value={value}
      />
      {error && (
        <p>
          {name === "email"
            ? errors.email
              ? `Please add an email.`
              : `Email already exists.`
            : `Please add a ${name}. `}
        </p>
      )}
    </div>
  );
};

export default UserInput;
