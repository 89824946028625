import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import { cloneElement } from "react";
import "../../styles/userManagement/userInputs.css";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const UserDropdown = ({
  label,
  name,
  data,
  handleOpen,
  handleClose,
  handleChange,
  textField,
  disabled,
  error,
  open,
  value,
  dataItemKey,
}) => {
  const { t } = useTranslation();
  const itemRender = (li, itemProps) => {
    const valueIds = value.map((item) => item[name]);
    const checkboxValue = () => {
      // if length of selected is equal to all options minus one,
      // then all area area selected
      return value.length === data?.length - 1
        ? true
        : valueIds.includes(itemProps.dataItem[name]);
    };

    const itemChildren = (
      <div className="d-flex gap-2 option">
        <Checkbox value={checkboxValue()} />
        <p className="mb-0">{li.props.children}</p>
      </div>
    );
    return cloneElement(li, li.props, itemChildren);
  };
  return (
    <div className={`${error ? "error" : ""} col-12 col-md-6 mb-4`}>
      <label className={`mb-2 `}>{getTranslation(label, t)}</label>
      {name === "roleId" ? (
        <DropDownList
          style={
            !error
              ? value.roleDescription === "Select"
                ? { color: "var(--inchcape-light-grey)" }
                : { color: "var(--inchcape-dark-blue)" }
              : { color: "var(--inchcape-red)" }
          }
          onChange={handleChange}
          onOpen={handleOpen}
          onClose={handleClose}
          name={name}
          data={data}
          opened={open || false}
          value={value}
          textField={textField}
        />
      ) : (
        <MultiSelect
          data={data}
          className="k-input-flat"
          name={name}
          value={value}
          dataItemKey={dataItemKey}
          onOpen={handleOpen}
          onClose={handleClose}
          opened={name === "branchId" ? undefined : open || false}
          onChange={handleChange}
          disabled={disabled}
          autoClose={false}
          textField={textField}
          placeholder={getTranslation("Select", t)}
          itemRender={itemRender}
          tags={
            value.length === data.length - 1
              ? [
                  {
                    text: getTranslation(`All`, t),
                    data: [...value],
                  },
                ]
              : undefined
          }
        />
      )}
      {error && <p>Please add a {name.slice(0, -2)}.</p>}
    </div>
  );
};

export default UserDropdown;
