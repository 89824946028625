/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../styles/vehicles/vehiclesForm.css";
import { Input } from "@progress/kendo-react-inputs";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const ExtraDataForm = ({
  selectedLanguage,
  extraDataForm,
  handleExtraDataInputChange,
}) => {
  const { t } = useTranslation();
  const vehicleType = ["Sedan", "SUV", "Compact"];
  const stockType = [1];

  return (
    <div className="extra-fields-container">
      <div className="d-flex">
        <div className="forms-input">
          <div className="d-flex extra-fields-lbl">
            <label className="extra-data-form-label">
              {getTranslation("Label", t)}
            </label>
            <label className="extra-data-form-label">
              {getTranslation("Value", t)}
            </label>
          </div>
          <div className="extra-fields-forms">
            {extraDataForm.map((item) => {
              return (
                <div className="extra-fields-form d-flex mt-1">
                  <div className="vehicles-form-inputs">
                    <Input
                      className={`k-input-flat ${
                        item.isRequired && item.hasError ? "error-input " : ""
                      }`}
                      placeholder={getTranslation(item.label, t)}
                      type="input"
                      name={item.name}
                      value={getTranslation(item.label, t)}
                      disabled={true}
                      onChange={handleExtraDataInputChange}
                    />
                    {item.isRequired && item.hasError && (
                      <span className="error-text">
                        <p>{getTranslation("This field is required.",t)}</p>
                      </span>
                    )}
                  </div>
                  <div className="vehicles-form-inputs">
                    <Input
                      className={`k-input-flat ${
                        item.isRequired && item.hasError ? "error-input " : ""
                      }`}
                      type="input"
                      name={item.name}
                      value={item.value}
                      onChange={handleExtraDataInputChange}
                    />
                    {item.isRequired && item.hasError && (
                      <span className="error-text">
                        <p>{getTranslation("This field is required.",t)}</p>
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraDataForm;
