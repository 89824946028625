import { createSelector } from "reselect";

const stateSelector = (state) => state;
export const getCampaignListSelector = createSelector(
  stateSelector,
  (state) => state.reducer.campaignList
);

export const getLoading = createSelector(
  stateSelector,
  (state) => state.reducer.isLoading
);

export const getAuth = createSelector(
  stateSelector,
  (state) => state.reducer.isAuth
);

export const getCurrentUserSelector = createSelector(
  stateSelector,
  (state) => state.currentUserReducer
);

export const getUsersListSelector = createSelector(
  stateSelector,
  (state) => state.reducer.usersList
);

export const getUsersListPageSelector = createSelector(
  stateSelector,
  (state) => state.reducer.usersListPage
);

export const getCampaignListPageSelector = createSelector(
  stateSelector,
  (state) => state.reducer.campaignListPage
);

export const getCampaignLeadsListSelector = createSelector(
  stateSelector,
  (state) => state.reducer.campaignLeadsList
);

export const getCampaignLeadsListPageSelector = createSelector(
  stateSelector,
  (state) => state.reducer.campaignLeadsListPage
);

export const getMarketSettingsSelector = createSelector(
  stateSelector,
  (state) => state.reducer.marketSettings
);

export const getVehicleListSelector = createSelector(
  stateSelector,
  (state) => state.vehicleSelectorReducer
);
export const getLeadsListSelector = createSelector(
  stateSelector,
  (state) => state.reducer.leadsList
);

export const getLeadsListPageSelector = createSelector(
  stateSelector,
  (state) => state.reducer.leadsListPage
);

export const getVehiclesListSelector = createSelector(
  stateSelector,
  (state) => state.reducer.vehiclesList
);

export const getVehiclesListPageSelector = createSelector(
  stateSelector,
  (state) => state.reducer.vehiclesListPage
);

export const getVehicleDataSelector = createSelector(
  stateSelector,
  (state) => state.reducer.vehicleData
);

export const getLanguagesSelector = createSelector(
  stateSelector,
  (state) => state.reducer.languages
);

export const getVehicleLanguageIdSelector = createSelector(
  stateSelector,
  (state) => state.reducer.vehicleLanguageId
);

export const getLeadsReferenceKeySelector = createSelector(
  stateSelector,
  (state) => state.reducer.leadsReferenceKey
);

export const getLeadsCountSelector = createSelector(
  stateSelector,
  (state) => state.reducer.leadsCount
);

export const getHeaderUserMarketsSelector = createSelector(
  stateSelector,
  (state) => state.reducer.headerUserMarkets
);

export const getUserBranchesSelector = createSelector(
  stateSelector,
  (state) => state.reducer.userBranches
);

export const getPortfolioListSelector = createSelector(
  stateSelector,
  (state) => state.reducer.portfolioList
);

export const getPortfolioListPageSelector = createSelector(
  stateSelector,
  (state) => state.reducer.portfolioListPage
);

export const getPortfolioFiltersSelector = createSelector(
  stateSelector,
  (state) => state.reducer.portfolioFilters
);

export const getLoadingCountSelector = createSelector(
  stateSelector,
  (state) => state.reducer.loadingCount
);

export const getDashboardLoadType = createSelector(
  stateSelector,
  (state) => state.reducer.dashboardType
);

export const getServiceAlertsListSelector = createSelector(
  stateSelector,
  (state) => state.reducer.serviceAlertsList
);

export const getServiceAlertsListPageSelector = createSelector(
  stateSelector,
  (state) => state.reducer.serviceAlertsListPage
);

export const getServiceAlertsFiltersSelector = createSelector(
  stateSelector,
  (state) => state.reducer.serviceAlertsFilters
);

export const getSearchModuleListSelector = createSelector(
  stateSelector,
  (state) => state.reducer.searchModuleList
);

export const getSearchModuleListPageSelector = createSelector(
  stateSelector,
  (state) => state.reducer.searchModuleListPage
);

export const getSearchModuleParams = createSelector(
  stateSelector,
  (state) => state.reducer.searchModuleParams
);

export const getSuccessModalValues = createSelector(
  stateSelector,
  (state) => state.reducer.successModalValues
);

export const getCustomerRecordDataSelector = createSelector(
  stateSelector,
  (state) => state.reducer.customerRecordData
);

export const getClientPositionDataSelector = createSelector(
  stateSelector,
  (state) => state.reducer.clientPositionData
);

export const getFinanceRecordDataSelector = createSelector(
  stateSelector,
  (state) => state.reducer.financeRecordData
);

export const getSpecialistsSelector = createSelector(
  stateSelector,
  (state) => state.reducer.specialists
);

export const getActivityRecordSelector = createSelector(
  stateSelector,
  (state) => state.reducer.activityRecord
);

export const getReportsUsageGridListSelector = createSelector(
  stateSelector,
  (state) => state.reducer.reportsUsageGridList
);

export const getReportsUsageGridListPageSelector = createSelector(
  stateSelector,
  (state) => state.reducer.reportsUsageGridListPage
);

export const getReportsConversionGridListSelector = createSelector(
  stateSelector,
  (state) => state.reducer.reportsConversionGridList
);

export const getReportsConversionGridListPageSelector = createSelector(
  stateSelector,
  (state) => state.reducer.reportsConversionGridListPage
);

export const getImageUploadError = createSelector(
  stateSelector,
  (state) => state.reducer.imageUploadError
);
