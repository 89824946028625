/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getIcon } from "../../utils/iconUtils";
import { Checkbox } from "@progress/kendo-react-inputs";
import "../../styles/campaigns/campaignLeads.css";
import { campaignLeadsListColumns } from "./gridColumns";
import { useEffect, useState } from "react";
import { Pager } from "@progress/kendo-react-data-tools";
import ColumnHider from "../shared/ColumnHider";
import { getStatusClass } from "../../utils/statusUtil";
import fetchClient from "../../api/fetch";
import { validateUserPermission } from "../../utils/helpers";
import ExportCampaignModal from "../shared/ExportCampaignModal";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { DateFilterCell } from "../shared/DateFilterCell";

const CampaignLeads = ({
  getCampaignLeadsList,
  campaignLeadsList,
  campaignLeadsListPage,
  currentUser,
  exportCampaignToExcel
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [columns, setColumns] = useState(campaignLeadsListColumns);
  const [showColumnHider, setShowColumnHider] = useState(false);
  const [page, setPage] = useState({
    skip: 0,
    take: 10
  });
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState(undefined);
  const [params, setParams] = useState({
    sort: "",
    filter: "",
    search: ""
  });
  const [campaignType, setCampaignType] = useState({});
  const [campaignName, setCampaignName] = useState("");

  useEffect(() => {
    getCampaignLeadsList(id, queryBuilder());
  }, [id, page, params]);

  useEffect(() => {
    fetchClient()
      .get(`/v1/campaigns/${id}`)
      .then((res) => {
        const resData = res.data.data;
        setCampaignType(resData.campaignType);
        setCampaignName(resData.campaignName);
      })
      .catch((err) => console.log(err));
  }, []);

  const queryBuilder = () => {
    const filter = `filters=[${params.filter.concat(params.search)}]`;

    return `?${filter}${params.sort}&page=${JSON.stringify(page)}`;
  };

  const handleBack = () => {
    switch (location.state?.prevPage) {
      case "campaigns":
        Navigate(`/campaigns`);
        break;
      default:
        Navigate("/dashboard");
        break;
    }
  };

  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...e.page,
      skip: e.skip,
      take
    });
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      setParams({
        ...params,
        search: JSON.stringify({
          field: "campaignName",
          value: e.target.value,
          condition: "contains"
        })
      });
      setPage({ ...page, skip: 0 });
    } else {
      setParams({
        ...params,
        search: ""
      });
    }
  };

  const handleSort = (e) => {
    setSort(e.sort);
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `&sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.dataState.filter);
    if (e.dataState.filter !== undefined) {
      setParams({
        ...params,
        filter: e.dataState.filter.filters.map((item) => {
          let obj = { ...item };
          obj["condition"] = obj["operator"];
          delete obj["operator"];
          obj = JSON.stringify(obj);
          return obj;
        })
      });
      setPage({
        ...page,
        skip: 0
      });
    } else {
      setParams({ ...params, filter: "" });
    }
  };

  const leadActions = ({ dataItem }) => {
    return (
      <td className="text-center">
        <Checkbox />
      </td>
    );
  };

  const leadHeaderCell = ({ dataItem }) => {
    return (
      <span className="k-cell-inner">
        <span className="k-link">
          <span>
            <Checkbox />
          </span>
          <span className="k-column-title select-all">
            {getTranslation("Select all", t)}
          </span>
        </span>
      </span>
    );
  };

  const statusCell = (props) => {
    const { dataItem } = props;
    const { status } = dataItem;
    return (
      <td className="status-row-cell">
        <div className={`status-holder ${getStatusClass(status)}`}>
          <span className="status-label">{getTranslation(status, t)}</span>
        </div>
      </td>
    );
  };

  const customPager = (props) => {
    return (
      <div className="custom-pager">
        <div className="imgs">
          <img
            onClick={() => setShowColumnHider(true)}
            src={getIcon("settings.png")}
            alt="column hider icon"
          />
        </div>
        <Pager
          skip={props.skip}
          take={props.take}
          type="numeric"
          total={props.total}
          buttonCount={props.buttonCount}
          previousNext={true}
          pageSizes={props.pageSizes}
          pageSizeValue={props.pageSizeValue}
          onPageChange={pageChange}
        />
      </div>
    );
  };

  const handleExportCampaign = () => {
    exportCampaignToExcel(id);
  };

  return (
    <div className="campaign-leads">
      <div className="d-flex gap-5 align-items-center mb-4">
        <img
          // onClick={() => Navigate("/campaigns")}
          onClick={handleBack}
          src={getIcon("arrow-back.svg")}
          alt="back icon"
        />
        <p className="h1">{campaignName}</p>
        {campaignType === 1 && (
          <>
            {validateUserPermission(
              currentUser.permissions,
              "canEdit",
              "Campaigns"
            ) && (
              <button
                className="d-flex justify-content-center align-items-center gap-10 primary-btn edit-campaign-btn"
                onClick={() => {
                  Navigate(`/campaigns/${id}`);
                }}
              >
                <p className="m-0">{getTranslation("Edit campaign", t)}</p>
              </button>
            )}
          </>
        )}
      </div>
      <div className="module-cont">
        <div className={"header d-flex grid-view"}>
          <div className="search d-flex gap-4">
            <div className="d-flex flex-column">
              <label>{getTranslation("Search", t)}</label>
              <input
                name="search"
                onChange={handleSearch}
                className="primary-input"
                type="text"
              />
            </div>
            <button className="primary-btn">
              <img src={getIcon("searchInput.png")} alt="search icon" />
            </button>
          </div>
          {validateUserPermission(
            currentUser.permissions,
            "canEdit",
            "Campaigns"
          ) && (
            <button className="offset-1 d-flex justify-content-center align-items-center gap-2 primary-btn bulk-actions-btn">
              <p className="m-0">{getTranslation("Bulk actions", t)}</p>
            </button>
          )}
          <button
            className="offset-1 d-flex justify-content-center align-items-center gap-2 secondary-btn export-campaign-btn"
            onClick={handleExportCampaign}
          >
            <p className="m-0">{getTranslation("Export", t)}</p>
          </button>
        </div>
        <div className={"grid-cont grid-view"}>
          <Grid
            data={campaignLeadsList.map((item) => ({
              ...item,
              lastActivity: new Date(item.lastActivity)
            }))}
            skip={page.skip}
            take={page.take}
            total={campaignLeadsListPage.totalRecords}
            filterable={true}
            filter={filter}
            sortable={true}
            sort={sort}
            onSortChange={handleSort}
            onDataStateChange={handleFilterChange}
            pager={customPager}
            onRowClick={({ dataItem }) =>
              Navigate(`/customer-record/${dataItem.leadId}`, {
                state: {
                  prevPage: "campaign-leads",
                  campaignId: dataItem.campaignId
                }
              })
            }
            pageable={{
              buttonCount:
                campaignLeadsListPage.totalPages > 10
                  ? 10
                  : campaignLeadsListPage.totalPages,
              pageSizes: [5, 10, 15],
              pageSizeValue
            }}
          >
            {columns.map((column, idx) => {
              if (idx === 0) {
                return validateUserPermission(
                  currentUser.permissions,
                  "canEdit",
                  "Campaigns"
                ) ? (
                  <Column
                    key={idx}
                    width={column.width}
                    cell={leadActions}
                    headerCell={leadHeaderCell}
                    filterable={false}
                    title={getTranslation(column.title, t)}
                  />
                ) : null;
              } else if (idx === 2) {
                return column.isShown ? (
                  <Column
                    key={idx}
                    field={column.column}
                    width={column.width}
                    cell={statusCell}
                    title={getTranslation(column.title, t)}
                    filterable={true}
                    filter={column.filter}
                  />
                ) : null;
              } else {
                return column.isShown ? (
                  <Column
                    key={idx}
                    field={column.column}
                    width={column.width}
                    title={getTranslation(column.title, t)}
                    filterable={column.filter !== "numeric"}
                    filter={column.filter}
                    filterCell={column.filter === "date" ? DateFilterCell : undefined}
                    format={
                      column.filter === "date" ? "{0:dd/MM/yyyy}" : undefined
                    }
                  />
                ) : null;
              }
            })}
          </Grid>
        </div>
      </div>
      {showColumnHider && (
        <ColumnHider
          closeHider={() => setShowColumnHider(false)}
          columns={columns}
          setColumns={setColumns}
        />
      )}
    </div>
  );
};

export default CampaignLeads;
