import "../../styles/customerRecord/deskingSheet.css";
import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { format } from "date-fns";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import {formatCurrency } from "../../utils/helpers";

const DeskingSheet = ({
  marketSettings,
  financeData,
  customerRecordData,
  clientPosition
}) => {
  const { t } = useTranslation();

  const currencyFormatter = (value) => {
    let val = value?.toString();
    const isNegative = val?.includes("-");
    const currencySymbol = marketSettings.currencySymbol;

    if (val && isNegative) {
      const splitValue = val.split("-");
      val = `-${currencySymbol}${Number(splitValue[1])}`;
      return val;
    } else {
      return val ? `${currencySymbol}${Number(val)}` : null;
    }
  };

  const currencyOptions = {
    symbol: marketSettings.currencySymbol,
    separator: ","
  };

  const currentVehicleData = [
    {
      details: getTranslation("Brand", t),
      existing: financeData?.existing?.brand,
      proposed: financeData?.proposed?.brand
    },{
      details: getTranslation("Description", t),
      existing: financeData?.existing?.description,
      proposed: financeData?.proposed?.description
    },
    {
      details: getTranslation("New/Used", t),
      existing: financeData?.existing?.newOrUsed,
      proposed: financeData?.proposed?.newOrUsed
    },
    {
      details: getTranslation("Registration date", t),
      existing: financeData?.existing?.registrationDate
        ? format(
            new Date(financeData?.existing?.registrationDate),
            "dd/MM/yyyy"
          )
        : null,
      proposed: "N/A"
    },
    {
      details: getTranslation("Start date", t),
      existing: financeData?.existing?.startDate
        ? format(new Date(financeData?.existing?.startDate), "dd/MM/yyyy")
        : null,
      proposed: "N/A"
    },
    {
      details: getTranslation("Start kilometers", t),
      existing: financeData?.existing?.startKilometers,
      proposed: financeData?.proposed?.startKilometers
    }
  ];

  const currentFinanceData = [
    {
      details: getTranslation("Finance Type", t),
      existing: financeData?.existing?.financeTypeName,
      proposed: financeData?.proposed?.financeTypeName
    },
    {
      details: getTranslation("Purchased Price", t),
      existing: financeData?.existing?.purchasePriceFormatted
        ? formatCurrency(
            financeData?.existing?.purchasePriceFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: financeData?.proposed?.purchasePriceFormatted
        ? formatCurrency(
            financeData?.proposed?.purchasePriceFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null
    },
    {
      details: getTranslation("Equity", t),
      existing: financeData?.existing?.equityFormatted
        ? formatCurrency(
            financeData?.existing?.equityFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: "N/A"
    },
    {
      details: getTranslation("Trade-in Value", t),
      existing: financeData?.existing?.tradeInValueFormatted
        ? formatCurrency(
            financeData?.existing?.tradeInValueFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: "N/A"
    },
    {
      details: getTranslation("Settlement Value", t),
      existing: financeData?.existing?.settlementValueFormatted
        ? formatCurrency(
            financeData?.existing?.settlementValueFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: "N/A"
    },
    {
      details: getTranslation("Total Deposit", t),
      existing: financeData?.existing?.totalDepositFormatted
        ? formatCurrency(
            financeData?.existing?.totalDepositFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: financeData?.proposed?.totalDepositFormatted
        ? formatCurrency(
            financeData?.proposed?.totalDepositFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null
    },
    {
      details: getTranslation("Total manufacturer deposit", t),
      existing: "-",
      proposed: financeData?.proposed?.totalManufacturerDepositFormatted
        ? formatCurrency(
            financeData?.proposed?.totalManufacturerDepositFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null
    },
    {
      details: getTranslation("Total dealer deposit", t),
      existing: "-",
      proposed: financeData?.proposed?.totalDealerDepositFormatted
        ? formatCurrency(
            financeData?.proposed?.totalDealerDepositFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null
    },
    {
      details: getTranslation("Customer deposit (Cash)", t),
      existing: "-",
      proposed: financeData?.proposed?.totalCustomerDepositFormatted || 0
        ? formatCurrency(
            financeData?.proposed?.totalCustomerDepositFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null
    },
    {
      details: getTranslation("Total amount financed", t),
      existing: financeData?.existing?.totalAmountFinancedFormatted
        ? formatCurrency(
            financeData?.existing?.totalAmountFinancedFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: financeData?.proposed?.totalAmountFinancedFormatted
        ? formatCurrency(
            financeData?.proposed?.totalAmountFinancedFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null
    },
    {
      details: getTranslation("Interest rate", t),
      existing: financeData?.existing?.interestRateFormatted
        ? `${financeData?.existing?.interestRateFormatted}%`
        : null,
      proposed: financeData?.proposed?.interestRateFormatted
        ? `${financeData?.proposed?.interestRateFormatted}%`
        : null
    },
    {
      details: getTranslation("Term", t),
      existing: financeData?.existing?.term,
      proposed: financeData?.proposed?.term
    },
    {
      details: getTranslation("Monthly payment", t),
      existing: financeData?.existing?.monthlyPaymentFormatted
        ? formatCurrency(
            financeData?.existing?.monthlyPaymentFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: financeData?.proposed?.monthlyPaymentFormatted
        ? formatCurrency(
            financeData?.proposed?.monthlyPaymentFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null
    },
    {
      details: getTranslation("Guaranteed Future Value (GFV)", t),
      existing: financeData?.existing?.gfvFormatted
        ? formatCurrency(
            financeData?.existing?.gfvFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: financeData?.proposed?.gfvFormatted
        ? formatCurrency(
            financeData?.proposed?.gfvFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null
    },
    {
      details: getTranslation("Months Elapsed", t),
      existing: financeData?.existing?.monthsElapsed,
      proposed: ""
    },
    {
      details: getTranslation("Projected end date", t),
      existing: financeData?.existing?.contractEndDate
        ? format(
            new Date(financeData?.existing?.contractEndDate),
            "dd/MM/yyyy"
          )
        : null,
      proposed: ""
    },
    {
      details: getTranslation("Payments left", t),
      existing: financeData?.existing?.paymentsRemaining,
      proposed: ""
    }
  ];

  const equityCalculationData = [
    {
      details: getTranslation("Part Exchange Value", t),
       existing: financeData?.existing?.tradeInValueFormatted
        ? formatCurrency(
            financeData?.existing?.tradeInValueFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
    },
    {
      details: getTranslation("Assumed Current Mileage", t),
      existing:  financeData?.existing?.lastMileageFromServiceSystem,
    },
    {
      details: getTranslation("Settlement figure", t),
       existing: financeData?.existing?.settlementValueFormatted
        ? formatCurrency(
            financeData?.existing?.settlementValueFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
    },
    {
      details: getTranslation("Equity", t),
      existing: financeData?.existing?.equityFormatted
        ? formatCurrency(
            financeData?.existing?.equityFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null
    }
  ];

  const costData = [
    {
      details: getTranslation("Monthly Cost/Saving", t),
      existing: financeData?.proposed?.monthlyDifferenceFormatted
        ? currencyFormatter(
            financeData?.proposed?.monthlyDifferenceFormatted
          )
        : null
    }
  ];

  // Add styling on table rows
  const rowRender = (trElement, props) => {
    const trProps = {
      style: {
        backgroundColor: "#ffff",
        color: "#00233AE5"
      }
    };

    const tdProps = {
      style: {
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderBottomWidth: 1,
        height: 57
      }
    };
    return React.cloneElement(
      trElement,
      {
        ...trProps
      },
      trElement.props.children.map((tdElement) => {
        return React.cloneElement(
          tdElement,
          {
            ...tdProps
          },
          tdElement.props.children
        );
      })
    );
  };

  //Render table row
  const rowCell = (props) => {
    const { dataItem, field } = props;
    const { details } = dataItem;
    const newUsedDetails =
      details === getTranslation("Registration date", t) ||
      details === getTranslation("Start date", t) ||
      details === getTranslation("Start kilometers", t) ||
      details === getTranslation("Start age", t);
    const equityDetails =
      details === getTranslation("Trade-in Value", t) ||
      details === getTranslation("Settlement Value", t);
    const totalDepositDetails =
      details === getTranslation("Total manufacturer deposit", t) ||
      details === getTranslation("Total dealer deposit", t) ||
      details === getTranslation("Total customer deposit", t) ||
      details === getTranslation("Customer deposit (Cash)", t);
    return (
      //add rows to hide
      <td
        className={
          newUsedDetails ||
          equityDetails ||
          totalDepositDetails 
            ? `${"rowCell"} ${"expandedDetails"}`
            : "rowCell"
        }
      >
        <div
          className={
            details === getTranslation("Monthly payment", t) &&
            field !== "existing"
              ? details === getTranslation("Monthly payment", t) &&
              field === "details"  ?"row-highlight row-highlight-label" : "row-highlight"
              : details === getTranslation("Monthly payment", t) &&
                field === "existing"
              ? "monthlyPaymentAmount"
              : field === "proposed"
              ? "proposedRow"
              : field === "details" &&
              (newUsedDetails ||
                equityDetails ||
                totalDepositDetails)
              ? "expandedDetailsRow"
              : "row"
          }
        >
          {field === "details" ? dataItem[field] : dataItem[field]}
        </div>
      </td>
    );
  };

  return (
    <>
    <div className="desking-sheet">
      <p className="h1 mb-0 pdf-header">RETENTION ENGINE INTERNAL USE ONLY</p>
      <div className="pdf-header-container">
        <div className="pdf-header-container-wrapper">
          <p className="pdf-header-container-label">Dealer:</p>
          <p className="pdf-header-container-value">{customerRecordData.sellingDealer}</p>
          <p className="pdf-header-container-label">Campaign:</p>
          <p className="pdf-header-container-value">{clientPosition.campaignName}</p>
          <p className="pdf-header-container-label">Generation date:</p>
          <p className="pdf-header-container-value">{format(new Date(), "dd/MM/yyyy")}</p>
          <p className="pdf-header-container-label">Customer name:</p>
          <p className="pdf-header-container-value">{customerRecordData.customerName}</p>
        </div>
        <div className="pdf-header-container-wrapper">
          <p className="pdf-header-container-label-double">Preferred contact number:</p>
          <p className="pdf-header-container-value">{customerRecordData.customerPhone}</p>
          <p className="pdf-header-container-label-double">Alternative contact number:</p>
          <p className="pdf-header-container-value">{customerRecordData.AlternativeNumber}</p>
        </div>
      </div>
      <p className="pdf-title-common">Current vehicle details:</p>
      <div className="pdf-grid-title">
        <p className="pdf-grid-title-detail">Details</p>
        <p className="pdf-grid-title-existing">Existing</p>
        <p className="pdf-grid-title-proposed">Proposed</p>
      </div>
      <div className="grid-container">
        <Grid
          className="financeRecordTableGrid"
          data={currentVehicleData}
          rowRender={rowRender}
        >
          <GridColumn
            cell={rowCell}
            field="details"
            width="170px"
          />
          <GridColumn
            cell={rowCell}
            field="existing"
            width="175px"
          />
          <GridColumn
            cell={rowCell}
            field="proposed"
            width="175px"
          />
        </Grid>
      </div>
      <p className="pdf-title-common">Current Finance details:</p>
      <div className="pdf-grid-title">
        <p className="pdf-grid-title-detail">Details</p>
        <p className="pdf-grid-title-existing">Existing</p>
        <p className="pdf-grid-title-proposed">Proposed</p>
      </div>
      <div className="grid-container">
        <Grid
          className="financeRecordTableGrid"
          data={currentFinanceData}
          rowRender={rowRender}
        >
          <GridColumn
            cell={rowCell}
            field="details"
            width="170px"
          />
          <GridColumn
            cell={rowCell}
            field="existing"
            width="175px"
          />
          <GridColumn
            cell={rowCell}
            field="proposed"
            width="175px"
          />
        </Grid>
      </div>
      <div className="pdf-title-2col">
        <p className="pdf-title-common">Equity Calculation:</p>
        <p className="pdf-title-common">Cost - Saving to Change:</p>
      </div>
      <div className="pdf-header-container">
        <div>
          <div className="pdf-grid-title-2col">
            <p className="pdf-grid-title-detail-2col">Details</p>
            <p className="pdf-grid-title-proposed-2col">Proposed</p>
          </div>
          <div className="grid-container">
            <Grid
              className="financeRecordTableGrid"
              data={equityCalculationData}
              rowRender={rowRender}
            >
              <GridColumn
                cell={rowCell}
                field="details"
                width="130px"
              />
              <GridColumn
                cell={rowCell}
                field="existing"
                width="100px"
              />
            </Grid>
          </div>
        </div>
        <div className="pdf-grid-right-container">
          <div className="pdf-grid-title-2col">
            <p className="pdf-grid-title-detail-2col">Details</p>
            <p className="pdf-grid-title-proposed-2col">Proposed</p>
          </div>
          <div className="grid-container">
            <Grid
              className="financeRecordTableGrid"
              data={costData}
              rowRender={rowRender}
            >
              <GridColumn
                cell={rowCell}
                field="details"
                width="130px"
              />
              <GridColumn
                cell={rowCell}
                field="existing"
                width="100px"
              />
            </Grid>
          </div>
        </div>
      </div>
      <div className="pdf-grid-title-footer">
        <p className="pdf-grid-title-detail-footer">Contact Date</p>
        <p className="pdf-grid-title-existing-footer">Comments</p>
        <p className="pdf-grid-title-detail-footer">F/Up Date</p>
      </div>
      <br/>
      <br/>
      <br/>
      <div className="pdf-grid-title-footer">
        <p className="pdf-grid-title-detail-footer">Manager Comments & Action Plan</p>
      </div>
      </div>
    </>
  );
};

export default DeskingSheet;
