import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  GET_ACTIVITY_RECORD,
  GET_CUSTOMER_RECORD_DATA,
  GET_LEADS_COUNT,
  GET_LEADS_LIST,
  SET_REMOVE_OPPORTUNITY_DATA,
  UPDATE_ACTIVITY_RECORD,
} from "../actions/types";
import {
  setLoading,
  setLeadsList,
  setLeadsListPage,
  setLeadsCount,
  setLeadsReferenceKey,
  setCustomerRecordData,
  setClientPositionData,
  setFinanceRecordData,
  setActivityRecord,
} from "../actions";
import {
  getActivityRecordApi,
  getCustomerRecordDataApi,
  getLeadsCount,
  getLeadsList,
  removeOpportunityApi,
  updateActivityRecordApi,
} from "../api/handler";

function* handleLoadLeadsList(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getLeadsList, args.param);
    if (response.status === 200) {
      yield put(setLeadsList(response.data.data));
      yield put(
        setLeadsListPage({
          totalRecords: response.data.totalRecords,
          totalPages: response.data.totalPages,
        })
      );
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleLoadLeadsCount(args) {
  yield put(setLoading(true));

  // Reset data
  yield put(setLeadsCount(0));
  yield put(setLeadsReferenceKey(""));

  try {
    const response = yield call(getLeadsCount, args.payload);
    if (response.status === 200) {
      yield put(setLeadsCount(response.data.data.count));
      yield put(setLeadsReferenceKey(response.data.data.referenceKey));
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleRemoveOpportunity({ removeOpportunityData }) {
  yield put(setLoading(true));

  try {
    const response = yield call(removeOpportunityApi, removeOpportunityData);
    if (response.status === 200) {
      yield call(handleLoadCustomerRecord, { id: removeOpportunityData.ivId });
      yield call(handleLoadActivityRecord, { id: removeOpportunityData.ivId });
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleLoadCustomerRecord({ id }) {
  yield put(setLoading(true));

  try {
    const response = yield call(getCustomerRecordDataApi, id);
    if (response.status === 200) {
      yield put(setCustomerRecordData(response.data.customerRecord));
      yield put(setClientPositionData(response.data.clientPosition));
      yield put(setFinanceRecordData(response.data.financeRecord));
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleLoadActivityRecord({ id }) {
  yield put(setLoading(true));

  try {
    const response = yield call(getActivityRecordApi, id);
    if (response.status === 200) {
      yield put(setActivityRecord(response.data));
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleUpdateActivityRecord({ updateActivityRecordData, id }) {
  yield put(setLoading(true));

  try {
    const response = yield call(updateActivityRecordApi, updateActivityRecordData, id);
    if (response.status === 200) {
      yield call(handleLoadCustomerRecord, { id });
      yield call(handleLoadActivityRecord, { id });
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

export default function* leadsSaga() {
  yield all([
    takeLatest(GET_LEADS_LIST, handleLoadLeadsList),
    takeLatest(GET_LEADS_COUNT, handleLoadLeadsCount),
    takeLatest(GET_CUSTOMER_RECORD_DATA, handleLoadCustomerRecord),
    takeLatest(SET_REMOVE_OPPORTUNITY_DATA, handleRemoveOpportunity),
    takeLatest(GET_ACTIVITY_RECORD, handleLoadActivityRecord),
    takeLatest(UPDATE_ACTIVITY_RECORD, handleUpdateActivityRecord),
  ]);
}
