export const getStatusClassBox = (status) => {
  switch (status) {
    case "Appt.Attended":
    case "Appt.Scheduled":
    case "Appt. Attended":
    case "Appt. Scheduled":
    case "Ongoing":
    case "On going":
    case "Digital":
      return `first-status`;
    case "Call Back":
    case "Call back":
    case "CallBack":
    case "Contacted":
      return "second-status";
    case "Lead":
    case "Delivered":
      return "third-status";
    case "Opportunity":
    case "Future Opportunity":
    case "FutureOpportunity":
      return "fourth-status";
    case "Record":
      return "fifth-status";
    case "Do no contact":
    case "Do not contact":
    case "DoNoContact":
    case "Closed / Lost":
    case "Closed/Lost":
    case "Sold":
    case "Not avail. for contact":
    case "NotAvail.ForContact":
      return "sixth-status ";

    default:
      return;
  }
};
