import React from "react";
import "../../styles/campaigns/campaignBuilder.css";
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { format } from "date-fns";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const BuilderInputs = ({
  currentUser,
  payload,
  handleDateChange,
  dateValue,
  inputError,
  invalidDate,
  branchData,
  handleDropdownBranchOpen,
  branchesValue,
  handleBranchChange,
  showCampaignNameExistError,
}) => {
  const { t } = useTranslation();
  //Datepicker limits
  const minDate = new Date();
  const maxDate = new Date(2100, 12, 31);

  return (
    <div className="campaign-builder-inputs row m-0">
      <div className="builder-inputs col-12 col-md-4 p-0 pe-md-3 mb-4">
        <p style={inputError.campaignName ? { color: "red" } : { color: "" }}>
          {getTranslation("Campaign Name", t)}*
        </p>
        <Input
          style={
            inputError.campaignName
              ? { borderBottom: "1px solid red" }
              : { borderBottom: "" }
          }
          className="k-input-flat"
          name="campaignName"
          value={payload.campaignName}
          onChange={handleDateChange}
        />
        {showCampaignNameExistError && (
          <span>
            <p className="error-text">
              {getTranslation("Campaign Name already exists", t)}.
            </p>
          </span>
        )}
        <span className="error-input-text">{inputError.campaignName}</span>
      </div>
      <div className=" builder-inputs col-12 col-md-4 p-0 px-md-3 mb-4">
        <p style={inputError.validFrom ? { color: "red" } : { color: "" }}>
          {getTranslation("Valid from", t)}*
        </p>
        <DatePicker
          className={
            inputError.validFrom ? `error-input k-input-flat ` : "k-input-flat"
          }
          format={"dd/MM/yyyy"}
          name="validFrom"
          value={dateValue.start}
          onChange={handleDateChange}
          min={minDate}
          max={maxDate}
        />
        <span className="error-input-text">{inputError.validFrom}</span>
      </div>
      <div className=" builder-inputs col-12 col-md-4 p-0 ps-md-3 mb-4">
        <p style={inputError.validTo ? { color: "red" } : { color: "" }}>
          {getTranslation("Valid to", t)}*
        </p>
        <DatePicker
          className={
            inputError.validTo ? `error-input k-input-flat ` : "k-input-flat"
          }
          format={"dd/MM/yyyy"}
          name="validTo"
          value={dateValue.end}
          onChange={handleDateChange}
          min={minDate}
          max={maxDate}
        />
        <span className="error-input-text">{inputError.validTo}</span>
        {invalidDate && <span className="error-input-text">{invalidDate}</span>}
      </div>
      <div className=" col-12 col-md-4 p-0 pe-md-3 mb-md-0 mb-4">
        <p>{getTranslation("Market", t)}</p>
        <Input
          className="k-input-flat"
          disabled={true}
          value={currentUser.settings.marketDescription}
        />
      </div>
      <div className="col-12 col-md-4 p-0 px-md-3 mb-md-0 mb-4">
        <p>{getTranslation("Brand", t)}</p>
        <Input
          className="k-input-flat"
          disabled={true}
          value={currentUser.settings.brandDescription}
        />
      </div>
      <div className="col-12 col-md-4 p-0 ps-md-3 mb-md-0 mb-4">
        <p>{getTranslation("Branch", t)}</p>
        <DropDownList
          // defaultValue={"Select"}
          data={branchData}
          textField="branchDescription"
          value={branchesValue}
          onOpen={handleDropdownBranchOpen}
          onChange={handleBranchChange}
        />
      </div>
    </div>
  );
};

export default BuilderInputs;
