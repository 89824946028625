import style from "./InvalidPage.module.css";
import logo from "../../imagenes/icon/InchcapeIcon.png";
import lines from "../../imagenes/line/Lines.svg";
import { useNavigate } from "react-router-dom";

const InvalidPage = ({ status }) => {
  const Navigate = useNavigate();
  const getMsg = () => {
    switch (status) {
      case "100":
        return (
          <>
            <p className={style.title}>Wait!</p>
            <p className={style.msg}>
              We were unable to validate your identity in{" "}
              <strong>Vehicle Stock Data Manager,</strong> to view the content
              please log in.
            </p>
            <button onClick={() => Navigate("/login")} className="primary-btn">
              Login
            </button>
          </>
        );
      case "401":
        return (
          <>
            <p className={style.title}>We could not validate your identity!</p>
            <p className={style.msg}>
              At the moment you cannot access{" "}
              <strong>Vehicle Stock Data Manager,</strong> if you think you
              should have access to this application please manage a ticket in
              <strong> Manage Engine:</strong>
            </p>
            <a href="/">https://servicedesk.inchcapedigital.com</a>
          </>
        );
      case "300":
        return (
          <>
            <p className={style.title}>
              Sorry! There was an error in the system
            </p>
            <p className={style.msg}>
              Please place a ticket in <strong>Manage Engine</strong> and give
              details of how the error happened
            </p>
            <a href="/">https://servicedesk.inchcapedigital.com</a>
          </>
        );
      case "403":
        return (
          <>
            <p className={style.title}>
              We could not authorize your market access.
            </p>
            <p className={style.msg}>
              At the moment you cannot access this <strong>Market,</strong> if
              you think you should have access to this market please manage a
              ticket in
              <strong> Manage Engine:</strong>
            </p>
            <a href="/">https://servicedesk.inchcapedigital.com</a>
          </>
        );
      default:
        return <p></p>;
    }
  };

  return (
    <div className={`${style.main} d-flex flex-column flex-sm-row`}>
      <div className={`${style.left} order-2 order-sm-1`}>
        <img src={logo} alt="inchcape logo" />
        <div>
          <div className={style.contents}>{getMsg()}</div>
        </div>
      </div>
      <div
        className={`${style.right} order-1 order-sm-2 d-flex flex-column align-items-center`}
      >
        <img src={lines} alt="lines icon" />
        <div>
          <p>Vehicle</p>
          <p>Stock Data</p>
          <p>Manager</p>
        </div>
      </div>
    </div>
  );
};

export default InvalidPage;
