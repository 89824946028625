/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "../../styles/shared/sidebar.css";
import { connect } from "react-redux";
import { getIcon } from "../../utils/iconUtils";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Cookie from "../../api/Cookies";
import { formatLogo } from "./brandLogo";
import { fetchImg } from "../../utils/iconUtils";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../common/translation";
import { BsCarFront } from "react-icons/bs";

const Sidebar = ({
  setAuth,
  currentUser,
  setLoading,
  setLoadingCount,
  setSearchModuleParams,
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  let location = useLocation();

  const menuItems = [
    {
      name: "Vehicles",
      icon: "vehicleIcon",
      active: matchPath("/vehicles/*", location.pathname),
      navigate: "/vehicles",
    },
    {
      name: "User Management",
      icon: getIcon("user.svg"),
      active: matchPath("/user-management/*", location.pathname),
      navigate: "/user-management",
    },
    {
      name: "Settings",
      icon: getIcon("settings.png"),
      active: location.pathname === "/settings",
      navigate: "/settings",
    },
  ];

  //useStates
  const [toggleSidebar, setToggleSidebar] = useState(false);

  //useEffects
  //Auto toggle if screen is less than 768px
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768 && !toggleSidebar) {
        setToggleSidebar(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [toggleSidebar]);

  //sidebar handle toggle
  const handleSidebarToggle = () => setToggleSidebar(!toggleSidebar);
  const handleLogout = () => {
    setLoadingCount(0);
    Cookie.remove("token");
    Cookie.remove("rftoken");
    setAuth(false);
    setTimeout(() => Navigate("/"), 100);
  };

  // Load side menu items
  return (
    <div
      className={`sidebar-container ${
        !toggleSidebar ? "sidebar-container-open" : ""
      }`}
    >
      <div className="sidebar-upper">
        {/* INCHCAPE LOGO & TITLE */}
        <div className="sidebar-header">
          <div>
            <img
              src={
                !toggleSidebar
                  ? getIcon("inchcapeLogo.png")
                  : getIcon("inchcapeLoguito.png")
              }
              alt="inchcape logo"
            />
          </div>
          <span>Vehicle Stock Data Manager</span>
        </div>
        {/* USER NAME & POSITION */}
        <div className="sidebar-user-content d-flex align-items-center">
          <span className="user-icon">
            <img src={getIcon("avatar.png")} alt="user icon" />
          </span>
          <div className="user-items">
            <span className="profile-name">
              {currentUser.userInfo.userFullName}
            </span>
            <span className="profile-email">{currentUser.userInfo.email}</span>
            {/* <span className="profile-role">
              {currentUser.userInfo.roleDescription}
            </span> */}
          </div>
        </div>
        {/* SIDEBAR CONTENT */}
        <div className="content-sides">
          <div className="sidebar-content">
            <Nav className="sidebar-navigation flex-column">
              {menuItems.map((item, idx) => {
                // TODO: Uncomment once we need user menu items is ready
                // if (currentUser.menu.includes(item.name)) {
                return (
                  <Nav.Link
                    key={idx}
                    className="sidebar-links"
                    onClick={() => {
                      setLoading(false);
                      setLoadingCount(0);
                      Navigate(item.navigate);
                      setSearchModuleParams({
                        sort: "",
                        filters: [],
                        statusFilters: "",
                        leadType: "",
                        name: "",
                        bank: "",
                        postalCode: "",
                        currentVehicle: "",
                        contractId: "",
                        phoneNumber: "",
                        licensePlate: "",
                        vinNumber: "",
                      });
                    }}
                    active={item.active}
                  >
                    <span>
                      {item.icon === "vehicleIcon" ? (
                        <BsCarFront className="vehicle-icon" />
                      ) : (
                        <img
                          src={item.icon}
                          alt={`${item.name.toLowerCase()} icon`}
                        />
                      )}
                    </span>
                    <span>{getTranslation(item.name, t)}</span>
                  </Nav.Link>
                );
                // TODO: Uncomment once we need user menu items is ready
                // }
              })}
            </Nav>
          </div>
          <div>
            <Nav.Link className="logout-nav" onClick={handleLogout}>
              <span>
                <img src={getIcon("logOut.png")} alt="logout icon" />
              </span>
              <span>{getTranslation("Log Out", t)}</span>
            </Nav.Link>
          </div>
        </div>
      </div>
      {/* SIDEBAR FOOTER */}
      <div className="sidebar-lower">
        <div className="sidebar-footer">
          <Nav className="sidebar-navigation-footer flex-column">
            <Nav.Link className="sidebar-links" onClick={handleSidebarToggle}>
              <span>
                <img
                  src={
                    toggleSidebar
                      ? getIcon("toggle.png")
                      : getIcon("altToggle.svg")
                  }
                  alt="toggle icon"
                />
              </span>
              <span>{getTranslation("Toggle Sidebar", t)}</span>
            </Nav.Link>
          </Nav>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
