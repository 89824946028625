import { Dialog } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import "../../styles/campaigns/campaignModal.css";
import { useState } from "react";
import fetchClient from "../../api/fetch";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const DuplicateCampaignModal = ({
  setShowDuplicateCampaignModal,
  campaignId,
  setSuccessModalValues,
  getCampaignList,
  queryBuilder
}) => {
  const { t } = useTranslation();
  const [campaignName, setCampaignName] = useState(null);
  const [error, setError] = useState({ message: "", status: 400 });

  const handleAccept = () => {
    setError({ ...error, message: "", status: 400 });

    fetchClient()
      .post(
        `/v1/campaigns/${campaignId}/duplicate?campaignname=${campaignName}`
      )
      .then((res) => {
        setShowDuplicateCampaignModal(false);
        setSuccessModalValues({
          message: "Campaign saved successfully",
          isVisible: true
        });
        setTimeout(() => {
          setSuccessModalValues({ message: "", isVisible: false });
          getCampaignList(queryBuilder());
        }, 3000);
      })
      .catch((err) => {
        setError({ ...error, message: err.response.data });
        console.log(err);
      });
  };

  const handleChange = (e) => {
    setCampaignName(e.value);
  };

  return (
    <Dialog
      className="duplicate-campaign"
      onClose={() => setShowDuplicateCampaignModal(false)}
    >
      <div className="duplicate-campaign-box d-flex flex-column gap-3 justify-content-between ">
        <h1 className="h1 text-align-center mb-3 pt-3">
          {getTranslation("Name the duplicated campaign", t)}
        </h1>
        <div className="justify-content-center gap-3">
          <label>{getTranslation("New name", t)}</label>
          <Input
            className="k-input-flat"
            name="newName"
            onChange={handleChange}
            value={campaignName}
          />
          {error.message && (
            <span>
              <p className="error-text">{getTranslation(error.message, t)}</p>
            </span>
          )}
        </div>
        <div className="buttons d-flex justify-content-center gap-2">
          <button
            className="bg-none-btn"
            onClick={() => setShowDuplicateCampaignModal(false)}
          >
            <p className="m-0">{getTranslation("Cancel", t)}</p>
          </button>
          <button
            className="gap-2 primary-btn"
            onClick={() => handleAccept()}
            disabled={!campaignName}
          >
            <p className="m-0">{getTranslation("Accept", t)}</p>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default DuplicateCampaignModal;
