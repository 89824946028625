import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  GET_VEHICLES_LIST,
  SET_REMOVE_VEHICLE,
  SAVE_VEHICLE,
  GET_VEHICLE,
  SAVE_EXISTING_VEHICLE,
  UPLOAD_IMAGES,
  DELETE_IMAGES,
} from "../actions/types";
import {
  setImageUploadError,
  setLoading,
  setSuccessModalValues,
  setVehicleData,
  setVehiclesList,
  setVehiclesListPage,
} from "../actions";
import {
  deleteVehicleApi,
  deleteVehicleImagesApi,
  getVehicleDataApi,
  getVehiclesListApi,
  saveExistingVehicleApi,
  saveVehicleApi,
  updatePrimaryImageApi,
  uploadVehicleImagesApi,
} from "../api/handler";

function* handleLoadVehiclesList(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getVehiclesListApi, args.param);
    if (response.status === 200) {
      yield put(setVehiclesList(response.data.data));
      yield put(
        setVehiclesListPage({
          totalRecords: response.data.totalRecords,
          totalPages: response.data.totalPages,
        })
      );
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleSaveVehicle({ data, primaryImageIndex, imageFiles }) {
  yield put(setLoading(true));
  try {
    const response = yield call(saveVehicleApi, data);
    if (response.status === 200) {
      if (imageFiles.length) {
        yield call(handleUploadVehicleImages, {
          vehicleId: response.data.data.vehicleId,
          primaryImageIndex: primaryImageIndex,
          imageFiles: imageFiles,
        });
      } else {
        yield put(
          setSuccessModalValues({
            message: "Vehicle saved successfully",
            isVisible: true,
          })
        );

        setTimeout(() => {
          window.location.href = `${window.location.origin}/vehicles`;
        }, 3000);
      }
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleSaveExistingVehicle({
  data,
  id,
  primaryImageIndex,
  imageFiles,
  isUpdatePrimaryImage,
  newPrimaryImage,
  isNew,
  deletedImages,
}) {
  yield put(setLoading(true));
  try {
    const response = yield call(saveExistingVehicleApi, data, id);
    if (response.status === 200) {
      if (deletedImages.length) {
        yield call(handleDeleteVehicleImages, {
          vehicleId: id,
          deletedImages: { imageNames: deletedImages },
        });
      }

      if (imageFiles.length) {
        yield call(handleUploadVehicleImages, {
          vehicleId: id,
          primaryImageIndex: primaryImageIndex,
          imageFiles: imageFiles,
        });
      }

      if (isUpdatePrimaryImage) {
        yield call(handleUpdatePrimaryImage, {
          imageNames: newPrimaryImage,
          isNew: isNew,
          vehicleId: id,
        });
      }

      if (
        !imageFiles.length &&
        !isUpdatePrimaryImage &&
        !deletedImages.length
      ) {
        yield put(
          setSuccessModalValues({
            message: "Vehicle saved successfully",
            isVisible: true,
          })
        );

        setTimeout(() => {
          window.location.href = `${window.location.origin}/vehicles`;
        }, 3000);
      }
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleDeleteVehicle({ id }) {
  yield put(setLoading(true));
  try {
    yield call(deleteVehicleApi, id);
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
  yield put(
    setSuccessModalValues({
      message: "Vehicle deleted successfully",
      isVisible: true,
    })
  );
  yield call(handleLoadVehiclesList, {
    param: `?countryCodeId=AUS&languageId=ENG&page=${JSON.stringify({
      skip: 0,
      take: 10,
    })}`,
  });
}

function* handleLoadVehicleData(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getVehicleDataApi, args.param);
    if (response.status === 200) {
      yield put(setVehicleData(response.data.data[0]));
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleUploadVehicleImages({
  vehicleId,
  primaryImageIndex,
  imageFiles,
}) {
  yield put(setLoading(true));
  try {
    const response = yield call(
      uploadVehicleImagesApi,
      vehicleId,
      primaryImageIndex,
      imageFiles
    );
    if (response.status === 200) {
      setImageUploadError(null);
      yield put(
        setSuccessModalValues({
          message: "Vehicle saved successfully",
          isVisible: true,
        })
      );

      setTimeout(() => {
        window.location.href = `${window.location.origin}/vehicles`;
      }, 3000);
    }
  } catch (e) {
    console.warn(e);
    yield put(setImageUploadError(e.response.data));
  }
  yield put(setLoading(false));
}

function* handleDeleteVehicleImages({ vehicleId, deletedImages }) {
  yield put(setLoading(true));
  try {
    const response = yield call(
      deleteVehicleImagesApi,
      vehicleId,
      deletedImages
    );

    if (response.status === 200) {
      yield put(
        setSuccessModalValues({
          message: "Vehicle saved successfully",
          isVisible: true,
        })
      );

      setTimeout(() => {
        window.location.href = `${window.location.origin}/vehicles`;
      }, 3000);
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleUpdatePrimaryImage(data) {
  yield put(setLoading(true));
  try {
    const response = yield call(updatePrimaryImageApi, data);

    if (response.status === 200) {
      yield put(
        setSuccessModalValues({
          message: "Vehicle saved successfully",
          isVisible: true,
        })
      );

      setTimeout(() => {
        window.location.href = `${window.location.origin}/vehicles`;
      }, 3000);
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

export default function* vehiclesSaga() {
  yield all([takeLatest(GET_VEHICLES_LIST, handleLoadVehiclesList)]);
  yield all([takeLatest(SAVE_VEHICLE, handleSaveVehicle)]);
  yield all([takeLatest(SET_REMOVE_VEHICLE, handleDeleteVehicle)]);
  yield all([takeLatest(GET_VEHICLE, handleLoadVehicleData)]);
  yield all([takeLatest(SAVE_EXISTING_VEHICLE, handleSaveExistingVehicle)]);
  yield all([takeLatest(UPLOAD_IMAGES, handleUploadVehicleImages)]);
  yield all([takeLatest(DELETE_IMAGES, handleDeleteVehicleImages)]);
}
