export const getSubdomain = () => {
  var host = window.location.host;
  var subdomain = host.split(".")[0];

  return subdomain;
};

export const validateUserPermission = (permissions, action, module) => {
  let hasPermission = false;

  for (let i in permissions) {
    if (permissions[i].moduleName === module && permissions[i][action]) {
      hasPermission = permissions[i][action];
    }
  }

  return hasPermission;
};

export const formatCurrency = (value, symbol) => {
  let val = value?.toString();
  const isNegative = val?.includes("-");

  if (val && isNegative) {
    const splitValue = val.split("-");
    val = `-${symbol}${Number(splitValue[1]).toLocaleString()}`;
    return val;
  } else {
    return val ? `${symbol}${Number(val).toLocaleString()}` : null;
  }
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
