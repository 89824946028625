import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import { cloneElement, useEffect, useState } from "react";
import "../../styles/shared/statusMultiselect.css";
import { getStatusClass } from "../../utils/statusUtil";
import statusData from "./statusData";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const StatusMultiselect = ({
  name,
  placeholder,
  value,
  handleChange,
  className
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState(statusData);
  const itemRender = (li, itemProps) => {
    const ids = value.map((value) => value.value);
    const itemChildren = (
      <div className="status-items d-flex option gap-2 m-1 align-items-center w-100">
        <Checkbox value={ids.includes(itemProps.dataItem.value)} />
        <p
          className={`mb-0 w-100 ${getStatusClass(
            itemProps.dataItem.classKey
          )}`}
        >
          {li.props.children}
        </p>
      </div>
    );
    return cloneElement(li, li.props, itemChildren);
  };

  useEffect(() => {
    setData(
      statusData.map((item) => ({
        ...item,
        status: getTranslation(item.status, t)
      }))
    );
  }, [t]);

  return (
    <MultiSelect
      data={data}
      value={value}
      placeholder={placeholder}
      className={`custom ${className}`}
      name={name}
      dataItemKey="value"
      itemRender={itemRender}
      onChange={handleChange}
      autoClose={false}
      textField="status"
      tags={
        value.length > 0
          ? [
              {
                text: `${value.length} items selected`,
                data: [...value]
              }
            ]
          : []
      }
    />
  );
};

export default StatusMultiselect;
