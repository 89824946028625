import React, { useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const LanguageDialog = ({
  toggleLanguageDialog,
  handleAcceptLanguage,
  handleLanguageChange,
  languageData,
  dropdownState,
  selectedLanguage,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog>
      <div className="language-dialog-box">
        <span className="dialog-title">
          {getTranslation("Change language", t)}
        </span>
        <div className="language-dropdown">
          <p>{getTranslation("Language", t)}</p>
          <DropDownList
            data={languageData}
            textField="language"
            dataItemKey="id"
            value={dropdownState}
            onChange={(e) => {
              handleLanguageChange(e);
            }}
          />
        </div>
        <div className="dialog-footer">
          <div>
            <button
              className="dialog-btn bg-none-btn"
              onClick={toggleLanguageDialog}
            >
              {getTranslation("Cancel", t)}
            </button>
          </div>
          <div>
            <button
              className="dialog-btn primary-btn"
              onClick={(e) => {
                handleAcceptLanguage();
              }}
              disabled={dropdownState.language === selectedLanguage}
            >
              {getTranslation("Accept", t)}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default LanguageDialog;
