/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../styles/vehicles/vehiclesForm.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import MainForm from "./MainForm.";
import SpecificationsForm from "./SpecificationsForm";
import ImageUpload from "./ImageUpload";
import TechSpecsFeaturesForm from "./TechSpecsFeaturesForm";
import {
  commonFormData,
  mainFormData,
  specsFormData,
  extraFormData,
} from "./formData";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getImageSize } from "../../utils/imageSizeUtil";
import ExtraDataForm from "./ExtraDataForm";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { toTitleCase } from "../../utils/helpers";
import fetchClient from "../../api/fetch";

const VehiclesForm = ({
  marketSettings,
  saveVehicle,
  saveExistingVehicle,
  vehicleData,
  languages,
  vehicleLanguageId,
  setLoading,
  imageUploadError,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const mode =
    location.pathname === "/vehicles/create-vehicle" ? "create" : "edit";
  const Navigate = useNavigate();
  const initialCommonFormPayload = commonFormData;
  const initialExtraDataFormPayload = extraFormData;
  const initialMainFormPayload = languages.map((language) => {
    return {
      language: language.languageName,
      languageId: language.languageId,
      data: mainFormData,
    };
  });
  const initialSpecsFormPayload = languages.map((language) => {
    return {
      language: language.languageName,
      languageId: language.languageId,
      data: specsFormData,
    };
  });
  const initialSpecsCategoriesPayload = [
    { id: 1, name: "category1", value: "", hasError: false, isRequired: true },
  ];
  const initialFeaturesPayload = [
    {
      label: {
        id: 1,
        name: "category1",
        value: "",
        hasError: false,
        isRequired: true,
      },
      value: {
        id: 1,
        name: "categoryValue1",
        value: "",
        hasError: false,
        isRequired: true,
      },
    },
  ];
  const initialSpecsValuesPayload = [
    {
      label: {
        id: 1,
        name: "category1",
        value: "",
        hasError: false,
        isRequired: true,
      },
      value: {
        id: 1,
        name: "categoryValue1",
        value: "",
        hasError: false,
        isRequired: true,
      },
      category: {
        id: null,
        name: "categoryDropdown1",
        value: "",
        hasError: false,
        isRequired: true,
      },
    },
  ];
  const [requiredLanguages, setRequiredLanguages] = useState([]);
  const [videoLink, setVideoLink] = useState("");
  const [isLive, setIsLive] = useState(true);
  const [files, setFiles] = useState([]);
  const [displayImagefiles, setDisplayImagefiles] = useState([
    {
      imageId: null,
      name: null,
      uploadedImg: null,
      isPrimary: false,
      isNew: false,
    },
  ]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState({
    imageId: null,
    name: null,
    uploadedImg: null,
    isNew: false,
  });
  const [isUpdatePrimaryImage, setIsUpdatePrimaryImage] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Main");
  const [selectedLanguage, setSelectedLanguage] = useState({
    languageName: null,
    languageCode: null,
    languageId: null,
  });
  const [commonForm, setCommonForm] = useState(initialCommonFormPayload);
  const [extraDataForm, setExtraDataForm] = useState(
    initialExtraDataFormPayload
  );
  const [mainForm, setMainForm] = useState(initialMainFormPayload);
  const [specsForm, setSpecsForm] = useState(initialSpecsFormPayload);
  const [specsCategories, setSpecsCategories] = useState(
    initialSpecsCategoriesPayload
  );
  const [features, setFeatures] = useState(initialFeaturesPayload);
  const [specsValues, setSpecsValues] = useState(initialSpecsValuesPayload);
  const [vehicleImages, setVehicleImages] = useState([]);

  useEffect(() => {
    if (mode === "edit") {
      setLoading(true);
      const vehicleLanguage =
        JSON.parse(localStorage.getItem("vehicleLanguage")) ||
        languages.filter((lang) => lang.languageId === vehicleLanguageId)[0];
      const countryCodeId = marketSettings.defaultLanguage
        ?.substr(0, 3)
        .toUpperCase();

      fetchClient()
        .get(
          `v1/vehicle/vehicleById?countryCodeId=${countryCodeId}&languageId=${vehicleLanguage.languageId}&vehicleId=${id}`
        )
        .then((res) => {
          const data = res.data.data[0];
          // Set Common form
          setCommonForm(
            commonForm.map((common) => {
              if (common.name === "vrm") {
                return { ...common, value: data.vehicleIdentifier };
              } else if (common.name === "vin") {
                return {
                  ...common,
                  value: data.secondaryDescriptionIdentifier,
                };
              } else if (
                common.name === "registrationDate" ||
                common.name === "soldDate"
              ) {
                return {
                  ...common,
                  value: data[common.name] ? new Date(data[common.name]) : null,
                };
              } else if (common.name === "stockTypeId") {
                return data.stockTypeId === 1
                  ? { ...common, value: { id: "USED", text: "Used vehicle" } }
                  : {
                      ...common,
                      value: { id: "NEW", text: "Brand new vehicle" },
                    };
              }

              return { ...common, value: data[common.name] };
            })
          );

          // Set Main form
          setMainForm(
            languages.map((language) => {
              return {
                language: language.languageName,
                languageId: language.languageId,
                data: mainFormData.map((mainData) => {
                  return {
                    ...mainData,
                    value: data[`${mainData.name}s`]
                      ?.map((vData) => {
                        if (mainData.name === "vehicleType") {
                          return {
                            id: vData[`${mainData.name}Translation`],
                            text: `${toTitleCase(
                              vData[`${mainData.name}Translation`]
                            )}s`,
                          };
                        } else if (language.languageId === vData.languageId)
                          return vData[`${mainData.name}Translation`];
                      })
                      .filter((d) => d)[0],
                  };
                }),
              };
            })
          );

          // Set Specifications form
          setSpecsForm(
            languages.map((language) => {
              return {
                language: language.languageName,
                languageId: language.languageId,
                data: specsFormData.map((specData) => {
                  return {
                    ...specData,
                    value: data[`${specData.name}s`]
                      ?.map((vData) => {
                        if (language.languageId === vData.languageId)
                          return vData[`${specData.name}Translation`];
                      })
                      .filter((d) => d)[0],
                  };
                }),
              };
            })
          );

          // Set Specification Categories
          setSpecsCategories(
            data.vehicleTechnicalCategories?.map((techCategory) => {
              return {
                id: techCategory.order,
                name: `category${techCategory.order}`,
                value: techCategory.category,
                hasError: false,
                isRequired: true,
              };
            })
          );

          // Set Features
          setFeatures(
            data.features?.map((feature, idx) => {
              return {
                label: {
                  id: idx + 1,
                  name: `category${idx + 1}`,
                  value: feature.label,
                  hasError: false,
                  isRequired: true,
                },
                value: {
                  id: idx + 1,
                  name: `categoryValue${idx + 1}`,
                  value: feature.value,
                  hasError: false,
                  isRequired: true,
                },
              };
            })
          );

          let vehicleTechnicalSpecifications = [];
          data.vehicleTechnicalCategories?.forEach((techCategory) => {
            return techCategory.vehicleTechnicalSpecifications?.forEach(
              (specs) => {
                vehicleTechnicalSpecifications.push({
                  category: techCategory.category,
                  order: techCategory.order,
                  data: specs,
                });
              }
            );
          });

          // Set Soecification values
          setSpecsValues(
            vehicleTechnicalSpecifications?.map((specs, index) => {
              const categoryId = data.vehicleTechnicalCategories.filter(
                (techCategory) => techCategory.category === specs.category
              )[0].order;

              return {
                label: {
                  id: index + 1,
                  name: `category${index + 1}`,
                  value: specs.data.description,
                  hasError: false,
                  isRequired: true,
                },
                value: {
                  id: index + 1,
                  name: `categoryValue${index + 1}`,
                  value: specs.data.technicalValue,
                  hasError: false,
                  isRequired: true,
                },
                category: {
                  id: categoryId,
                  name: `categoryDropdown${index + 1}`,
                  value: specs.category,
                  hasError: false,
                  isRequired: true,
                },
              };
            })
          );

          // Set Images
          let primaryImage = {
            imageId: null,
            name: null,
            uploadedImg: null,
            isNew: false,
          };
          const images = data.vehicleImages
            ?.map((image) => {
              if (getImageSize(image.size) === "original") {
                if (!primaryImage.imageId && image.isPrimary) {
                  primaryImage = {
                    imageId: image.vehicleImageId,
                    name: image.image,
                    uploadedImg: image.cdnUrl,
                    isNew: false,
                  };
                }

                return {
                  imageId: image.vehicleImageId,
                  name: image.image,
                  uploadedImg: image.cdnUrl,
                  isPrimary: image.isPrimary,
                  isNew: false,
                };
              }
            })
            .filter((i) => i);

          setDisplayImagefiles(images);
          setSelectedThumbnail(primaryImage);
          setVehicleImages(data.vehicleImages);

          // Set extra fields
          setExtraDataForm(
            extraDataForm.map((extra) => {
              return { ...extra, value: data[extra.name] };
            })
          );

          // Set video link
          setVideoLink(data.videoLink);

          // Set is live
          setIsLive(data.isLive);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });

      // Set default language
      setSelectedLanguage(vehicleLanguage);
    } else {
      // Set default language
      setSelectedLanguage({
        languageName: marketSettings.defaultLanguage,
        languageCode: marketSettings.defaultLanguage
          ?.substr(0, 3)
          .toLowerCase(),
        languageId: marketSettings.languageId,
      });

      // Set Main form
      setMainForm(
        languages.map((language) => {
          return {
            language: language.languageName,
            languageId: language.languageId,
            data: mainFormData,
          };
        })
      );

      // Set Specifications form
      setSpecsForm(
        languages.map((language) => {
          return {
            language: language.languageName,
            languageId: language.languageId,
            data: specsFormData,
          };
        })
      );
    }
  }, [languages, vehicleData, marketSettings]);

  const addRow = (category) => {
    switch (category) {
      case "specsCategories":
        setSpecsCategories([
          ...specsCategories,
          {
            id: specsCategories[specsCategories.length - 1].id + 1,
            name: `category${
              specsCategories[specsCategories.length - 1].id + 1
            }`,
            value: "",
            hasError: false,
          },
        ]);
        break;
      case "features":
        setFeatures([
          ...features,
          {
            label: {
              id: features[features.length - 1].label.id + 1,
              name: `category${features[features.length - 1].label.id + 1}`,
              value: "features",
              hasError: false,
            },
            value: {
              id: features[features.length - 1].value.id + 1,
              name: `categoryValue${
                features[features.length - 1].value.id + 1
              }`,
              value: "",
              hasError: false,
            },
          },
        ]);
        break;
      case "specsValues":
        setSpecsValues([
          ...specsValues,
          {
            label: {
              id: specsValues[specsValues.length - 1].label.id + 1,
              name: `category${
                specsValues[specsValues.length - 1].label.id + 1
              }`,
              value: "",
              hasError: false,
            },
            value: {
              id: specsValues[specsValues.length - 1].value.id + 1,
              name: `categoryValue${
                specsValues[specsValues.length - 1].value.id + 1
              }`,
              value: "",
              hasError: false,
            },
            category: {
              id: 0,
              name: `categoryDropdown${
                specsValues[specsValues.length - 1].category.id + 1
              }`,
              value: "",
              hasError: false,
            },
          },
        ]);
        break;
    }
  };

  const deleteRow = (category, i) => {
    switch (category) {
      case "specsCategories":
        if (i !== -1 && specsCategories.length > 1) {
          let value = null;
          setSpecsCategories(
            specsCategories.filter((spec, idx) => {
              if (idx === i) value = spec.value;
              return idx !== i;
            })
          );

          // Removed/Delete the selected dropdown value for technical specifications values (category dropdown field)
          setSpecsValues(
            specsValues.map((spec) => {
              if (spec.category.value === value) {
                return {
                  ...spec,
                  category: {
                    ...spec.category,
                    id: value.id,
                    value: "",
                    hasError: value.value === "",
                  },
                };
              }
              return spec;
            })
          );
        }
        break;
      case "features":
        if (i !== -1 && features.length > 1) {
          setFeatures(features.filter((feature, idx) => idx !== i));
        }
        break;
      case "specsValues":
        if (i !== -1 && specsValues.length > 1) {
          setSpecsValues(specsValues.filter((spec, idx) => idx !== i));
        }
        break;
    }
  };

  const handleSpecsCategoriesChange = (e) => {
    let previousValue = null;
    const currentValue = e.value;

    setSpecsCategories(
      specsCategories.map((spec) => {
        if (spec.name === e.target.name) {
          previousValue = spec.value;

          return {
            ...spec,
            value: currentValue,
            hasError: currentValue === "",
          };
        }
        return spec;
      })
    );

    // Update / Remove value for technical specifications values (category dropdown field)
    setSpecsValues(
      specsValues.map((spec) => {
        if (
          spec.category.value !== "" &&
          spec.category.value === previousValue
        ) {
          return {
            ...spec,
            category: {
              ...spec.category,
              id: currentValue !== "" ? spec.category.id : 0,
              value: currentValue,
              hasError: currentValue === "",
            },
          };
        }
        return spec;
      })
    );

    // Remove value for technical specifications values (category dropdown field)
    setSpecsValues(
      specsValues.map((spec) => {
        if (spec.language === selectedLanguage.languageName) {
          return {
            languageId: spec.languageId,
            language: spec.language,
            data: spec.data.map((item) => {
              if (
                item.category.value !== "" &&
                spec.category.value === previousValue
              ) {
                return {
                  ...item,
                  category: {
                    ...item.category,
                    id: currentValue !== "" ? item.category.id : 0,
                    value: currentValue,
                    hasError: currentValue === "",
                  },
                };
              }
              return item;
            }),
          };
        }
        return spec;
      })
    );
  };

  const handleFeaturesChange = (e) => {
    setFeatures(
      features.map((feature) => {
        if (feature.label.name === e.target.name) {
          return {
            ...feature,
            label: {
              ...feature.label,
              value: e.value,
              hasError: e.value === "",
            },
          };
        } else if (feature.value.name === e.target.name)
          return {
            ...feature,
            value: {
              ...feature.value,
              value: e.value,
              hasError: e.value === "",
            },
          };

        return feature;
      })
    );
  };

  const handleSpecsValuesChange = (e, index) => {
    const name = e.target.name;
    const value = e.value;
    setSpecsValues(
      specsValues.map((spec, idx) => {
        if (spec.label.name === name && idx === index) {
          return {
            ...spec,
            label: { ...spec.label, value: value, hasError: value === "" },
          };
        } else if (spec.category.name === name && idx === index) {
          return {
            ...spec,
            category: {
              ...spec.category,
              id: value.id,
              value: value.value,
              hasError: value.value === "",
            },
          };
        } else if (spec.value.name === name && idx === index) {
          return {
            ...spec,
            value: { ...spec.value, value: value, hasError: value === "" },
          };
        }
        return spec;
      })
    );
  };

  const handleMainInputChange = (e) => {
    setMainForm(
      mainForm.map((form) => {
        if (form.language === selectedLanguage.languageName) {
          return {
            language: form.language,
            languageId: form.languageId,
            data: form.data.map((item) => {
              if (item.name === e.target.name) {
                return { ...item, value: e.value, hasError: e.value === "" };
              }

              return item;
            }),
          };
        }

        return form;
      })
    );
  };

  const handleExtraDataInputChange = (e) => {
    setExtraDataForm(
      extraDataForm.map((form) => {
        if (form.name === e.target.name) {
          return { ...form, value: e.value, hasError: e.value === "" };
        }

        return form;
      })
    );
  };

  const handleVideoLinkChange = (e) => {
    setVideoLink(e.value);
  };

  const handleIsLiveChange = (e) => {
    setIsLive(e.value);
  };

  const handleCommonInputChange = (e) => {
    setCommonForm(
      commonForm.map((form) => {
        if (form.name === e.target.name) {
          return { ...form, value: e.value, hasError: e.value === "" };
        }

        return form;
      })
    );
  };

  const handleSpecsInputChange = (e) => {
    setSpecsForm(
      specsForm.map((form) => {
        if (form.language === selectedLanguage.languageName) {
          return {
            language: form.language,
            languageId: form.languageId,
            data: form.data.map((item) => {
              if (item.name === e.target.name) {
                return { ...item, value: e.value, hasError: e.value === "" };
              }

              return item;
            }),
          };
        }

        return form;
      })
    );
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.value);
  };

  const handleSave = () => {
    let commonFormValues = {};
    for (var i = 0; i < commonForm.length; i++) {
      commonFormValues[commonForm[i].name] = commonForm[i].value;
    }

    const mainFormValues = mainForm.map((form) => {
      let values = [];
      for (var i = 0; i < form.data.length; i++) {
        values.push({
          languageId: form.languageId,
          prop: form.data[i].name,
          value: form.data[i].value,
        });
      }

      return values;
    });

    const specsFormValues = specsForm.map((form) => {
      let values = [];
      for (var i = 0; i < form.data.length; i++) {
        values.push({
          languageId: form.languageId,
          prop: form.data[i].name,
          value: form.data[i].value,
        });
      }

      return values;
    });
    const payload = {
      LanguageId: marketSettings.languageId,
      C02Emission: parseInt(commonFormValues.c02Emission),
      CombineFuelConsumption: parseFloat(
        commonFormValues.combineFuelConsumption
      ),
      DeliveryPrice: parseInt(commonFormValues.deliveryPrice),
      Door: parseInt(commonFormValues.door),
      EngineSize: parseFloat(commonFormValues.engineSize),
      IsPreTaxPrice: false,
      NumberOfGear: parseInt(commonFormValues.numberOfGear),
      ModelYear: parseInt(commonFormValues.modelYear),
      OriginalPrice: parseInt(commonFormValues.originalPrice),
      Price: parseInt(commonFormValues.price),
      PriceForTax: parseInt(commonFormValues.priceForTax),
      PrimaryDescriptionIdentifierKey: "key",
      PrimaryDescriptionIdentifierValue: "value",
      RegistrationDate: commonFormValues.registrationDate || null,
      RegistrationFee: parseInt(commonFormValues.registrationFee),
      RoadTax: parseInt(commonFormValues.roadTax),
      Seat: parseInt(commonFormValues.seat),
      StockTypeId: commonFormValues.stockTypeId.id,
      VideoLink: videoLink,
      ExternalLocationId: commonFormValues.locationId,
      ExternalStockNumber: commonFormValues.externalStockNumber,
      FabricCategory: "FabricCategory",
      FactoryFitOptions: "FactoryFitOptions",
      ExternalVehicleId: "ext_veh_789",
      OdometerReading: commonFormValues.odometerReading,
      SecondaryDescriptionIdentifier: commonFormValues.vin,
      SoldDate: commonFormValues.soldDate ? commonFormValues.soldDate : null,
      SourceCreatedDate: "2023-08-17T10:00:00",
      SourceModifiedDate: "2023-08-17T15:00:00",
      StatusTypeId: 1,
      CountryCodeId: marketSettings.defaultLanguage.substr(0, 3).toUpperCase(),
      VehicleIdentifier: commonFormValues.vrm,
      ExtraData1: extraDataForm[0].value,
      ExtraData2: extraDataForm[1].value,
      ExtraData3: extraDataForm[2].value,
      ExtraData4: extraDataForm[3].value,
      ExtraData5: extraDataForm[4].value,
      ExtraData6: extraDataForm[5].value,
      ExtraData7: extraDataForm[6].value,
      ExtraData8: extraDataForm[7].value,
      ExtraData9: extraDataForm[8].value,
      ExtraData10: extraDataForm[9].value,
      ExtraData11: extraDataForm[10].value,
      ExtraData12: extraDataForm[11].value,
      ExtraData13: extraDataForm[12].value,
      ExtraData14: extraDataForm[13].value,
      ExtraData15: extraDataForm[14].value,
      ReferenceKey: "254FDC72-BA00-4B73-9F34-72BAED97AF89",
      isLive: isLive,
      isSold: false,
      Make: getTranslationValue(mainFormValues, "make"),
      Model: getTranslationValue(mainFormValues, "model"),
      VehicleType: getVehicleTypeValue(
        getTranslationValue(mainFormValues, "vehicleType")
      ),
      DisplayVehicleDescription: getTranslationValue(
        mainFormValues,
        "displayVehicleDescription"
      ),
      Transmission: getTranslationValue(specsFormValues, "transmission"),
      FuelType: getTranslationValue(specsFormValues, "fuelType"),
      ColourGroup: getTranslationValue(specsFormValues, "colourGroup"),
      BodyStyle: getTranslationValue(specsFormValues, "bodyStyle"),
      Specification: [
        {
          LanguageId: 1,
          SpecificationTranslation: "KF Series AWrD-ENG",
        },
      ],
      ShortDerivative: getTranslationValue(mainFormValues, "shortDerivative"),
      Profile: [
        {
          LanguageId: 1,
          ProfileTranslation: "Profileses-ENG",
        },
      ],
      LifestyleCategory: [
        {
          LanguageId: 1,
          LifestyleCategoryTranslation: "LifestyleCategorieses-ENG",
        },
      ],
      InteriorTrim: getTranslationValue(specsFormValues, "interiorTrim"),
      ExteriorColour: getTranslationValue(specsFormValues, "exteriorColour"),
      EmmissionStandard: getTranslationValue(
        specsFormValues,
        "emmissionStandard"
      ),
      DriveTrain: getTranslationValue(specsFormValues, "driveTrain"),
      Comment: getTranslationValue(mainFormValues, "comment"),
      Feature: features.map((feature) => {
        return feature.value.value;
      }),
      KeyFeature: ["Tiptronic", "Mineral"],
      VehicleTechnicalCategory: specsCategories.map((spec) => {
        return spec.value;
      }),
      VehicleTechnicalSpecification: specsValues.map((spec) => {
        return {
          Description: spec.label.value,
          TechnicalValue: spec.value.value,
          Category: spec.category.value,
        };
      }),
    };

    if (checkIfHasErrorOrEmpty()) {
      return;
    }

    const primaryIndex = displayImagefiles.findIndex(
      (file) => file.imageId === selectedThumbnail.imageId
    );

    if (mode === "create") saveVehicle(payload, primaryIndex, files);
    else
      saveExistingVehicle(
        payload,
        vehicleData.vehicleId || id,
        primaryIndex,
        files,
        isUpdatePrimaryImage,
        selectedThumbnail.name,
        isNew,
        deletedImages
      );
  };

  const getVehicleTypeValue = (vehicleTypes) => {
    return vehicleTypes.map((vehicleType) => {
      if (
        ["Cars", "Trucks", "Vans", "Trailers", "Others"].includes(
          vehicleType.vehicleTypeTranslation
        )
      ) {
        return {
          ...vehicleType,
          vehicleTypeTranslation: vehicleType.vehicleTypeTranslation
            .substr(0, vehicleType.vehicleTypeTranslation.length - 1)
            .toUpperCase(),
        };
      } else if (vehicleType.vehicleTypeTranslation === "Freight") {
        return {
          ...vehicleType,
          vehicleTypeTranslation: "FREIGHT",
        };
      }

      return vehicleType;
    });
  };

  const getTranslationValue = (formValues, prop) => {
    let values = [];

    formValues.forEach((formVal) => {
      return formVal.forEach((val) => {
        if (val.prop === prop && val.value) {
          values.push({
            languageId: val.languageId,
            [`${prop}Translation`]:
              prop === "vehicleType" ? val.value.id : val.value,
          });
        }
      });
    });

    return values;
  };

  const checkIfHasErrorOrEmpty = () => {
    let hasError = false;
    let requiredLanguages = [];

    setCommonForm(
      commonForm.map((common) => {
        if (!hasError && common.isRequired) {
          hasError = common.value === "" || !common.value;

          if (
            hasError &&
            !requiredLanguages.includes(selectedLanguage.languageName)
          ) {
            requiredLanguages.push(selectedLanguage.languageName);
          } else if (!hasError) {
            requiredLanguages.filter(
              (lang) => lang !== selectedLanguage.languageName
            );
          }
        }
        return { ...common, hasError: common.value === "" || !common.value };
      })
    );

    setMainForm(
      mainForm.map((form) => {
        for (var i = 0; i < form.data.length; i++) {
          if (
            !hasError &&
            !form.data[i].value &&
            form.data[i].isRequired &&
            form.language === selectedLanguage.languageName &&
            !requiredLanguages.includes(form.language)
          ) {
            hasError = form.data[i].value === "" || !form.data[i].value;
            requiredLanguages.push(form.language);
          } else if (
            hasError &&
            !form.data[i].value &&
            form.data[i].isRequired &&
            form.language === selectedLanguage.languageName &&
            !requiredLanguages.includes(form.language)
          ) {
            requiredLanguages.push(form.language);
          } else {
            setRequiredLanguages([]);
          }
        }

        if (requiredLanguages.includes(form.language) && hasError)
          return {
            language: form.language,
            languageId: form.languageId,
            data: form.data.map((item) => {
              if (!hasError && item.isRequired) {
                hasError = item.value === "" || !item.value;
              }

              return { ...item, hasError: item.value === "" || !item.value };
            }),
          };

        return form;
      })
    );

    setSpecsForm(
      specsForm.map((form) => {
        for (var i = 0; i < form.data.length; i++) {
          if (
            !hasError &&
            !form.data[i].value &&
            form.data[i].isRequired &&
            form.language === selectedLanguage.languageName &&
            !requiredLanguages.includes(form.language)
          ) {
            hasError = form.data[i].value === "" || !form.data[i].value;
            requiredLanguages.push(form.language);
          } else if (
            hasError &&
            !form.data[i].value &&
            form.data[i].isRequired &&
            form.language === selectedLanguage.languageName &&
            !requiredLanguages.includes(form.language)
          ) {
            requiredLanguages.push(form.language);
          } else {
            setRequiredLanguages([]);
          }
        }

        if (requiredLanguages.includes(form.language) && hasError)
          return {
            language: form.language,
            languageId: form.languageId,
            data: form.data.map((item) => {
              if (!hasError && item.isRequired) {
                hasError = item.value === "" || !item.value;
              }
              return { ...item, hasError: item.value === "" || !item.value };
            }),
          };

        return form;
      })
    );

    setSpecsCategories(
      specsCategories.map((spec) => {
        if (!hasError) {
          hasError = spec.value === "";

          if (
            hasError &&
            !requiredLanguages.includes(selectedLanguage.languageName)
          ) {
            requiredLanguages.push(selectedLanguage.languageName);
          } else if (!hasError) {
            requiredLanguages.filter(
              (lang) => lang !== selectedLanguage.languageName
            );
          }
        }
        return { ...spec, hasError: spec.value === "" };
      })
    );

    setFeatures(
      features.map((feature) => {
        if (!hasError) {
          hasError = feature.label.value === "" || feature.value.value === "";

          if (
            hasError &&
            !requiredLanguages.includes(selectedLanguage.languageName)
          ) {
            requiredLanguages.push(selectedLanguage.languageName);
          } else if (!hasError) {
            requiredLanguages.filter(
              (lang) => lang !== selectedLanguage.languageName
            );
          }
        }

        return {
          ...feature,
          label: { ...feature.label, hasError: feature.label.value === "" },
          value: { ...feature.value, hasError: feature.value.value === "" },
        };
      })
    );

    setSpecsValues(
      specsValues.map((spec) => {
        if (!hasError) {
          hasError =
            spec.label.value === "" ||
            spec.category.value === "" ||
            spec.value.value === "";

          if (
            hasError &&
            !requiredLanguages.includes(selectedLanguage.languageName)
          ) {
            requiredLanguages.push(selectedLanguage.languageName);
          } else if (!hasError) {
            requiredLanguages.filter(
              (lang) => lang !== selectedLanguage.languageName
            );
          }
        }

        return {
          ...spec,
          label: {
            ...spec.label,
            hasError: spec.label.value === "",
          },
          category: {
            ...spec.category,
            hasError: spec.category.value === "",
          },
          value: {
            ...spec.value,
            hasError: spec.value.value === "",
          },
        };
      })
    );

    setRequiredLanguages(requiredLanguages);

    return hasError;
  };

  return (
    <div className="vehicles-form-container">
      <label className="h1 mb-4">
        {mode === "create"
          ? getTranslation("Create Vehicle", t)
          : getTranslation("Edit Vehicle", t)}
      </label>

      <div className="module-cont">
        <div className="header d-flex justify-content-evenly">
          <div
            className={`d-flex justify-content-center align-items-center gap-2 nav-bar ${
              selectedTab === "Main" ? "active-bar" : ""
            }`}
            onClick={() => setSelectedTab("Main")}
            disabled={selectedTab === "Main"}
          >
            <label className="m-0">{getTranslation("Main", t)}</label>
          </div>
          <div
            className={`d-flex justify-content-center align-items-center gap-2 nav-bar ${
              selectedTab === "Specifications" ? "active-bar" : ""
            }`}
            onClick={() => setSelectedTab("Specifications")}
            disabled={selectedTab === "Specifications"}
          >
            <label className="m-0">{getTranslation("Specifications", t)}</label>
          </div>

          <div
            className={`d-flex justify-content-center align-items-center gap-2 nav-bar ${
              selectedTab === "Images" ? "active-bar" : ""
            }`}
            onClick={() => setSelectedTab("Images")}
            disabled={selectedTab === "Images"}
          >
            <label className="m-0">
              {getTranslation("Images", t)}/{getTranslation("Video", t)}
            </label>
          </div>
          <div
            className={`d-flex justify-content-center align-items-center gap-2 nav-bar ${
              selectedTab === "TechSpecs" ? "active-bar" : ""
            }`}
            onClick={() => setSelectedTab("TechSpecs")}
            disabled={selectedTab === "TechSpecs"}
          >
            <label className="m-0">
              {getTranslation("Tech Specs", t)} &{" "}
              {getTranslation("Features", t)}
            </label>
          </div>
          <div
            className={`d-flex justify-content-center align-items-center gap-2 nav-bar ${
              selectedTab === "ExtraData" ? "active-bar" : ""
            }`}
            onClick={() => setSelectedTab("ExtraData")}
            disabled={selectedTab === "ExtraData"}
          >
            <label className="m-0">{getTranslation("Extra Fields", t)}</label>
          </div>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "200px" }}
          >
            <label>{getTranslation("Language", t)}</label>
            <DropDownList
              className="primary-input"
              textField="languageName"
              dataItemKey="languageId"
              data={languages}
              name="languages"
              value={selectedLanguage}
              onChange={handleLanguageChange}
            />
          </div>
        </div>

        {getTranslation(selectedTab, t) === getTranslation("Main", t) && (
          <MainForm
            selectedLanguage={selectedLanguage.languageName}
            commonForm={commonForm}
            handleCommonInputChange={handleCommonInputChange}
            mainForm={mainForm}
            handleMainInputChange={handleMainInputChange}
            isLive={isLive}
            handleIsLiveChange={handleIsLiveChange}
          />
        )}
        {getTranslation(selectedTab, t) ===
          getTranslation("Specifications", t) && (
          <SpecificationsForm
            selectedLanguage={selectedLanguage.languageName}
            commonForm={commonForm}
            handleCommonInputChange={handleCommonInputChange}
            specsForm={specsForm}
            handleSpecsInputChange={handleSpecsInputChange}
          />
        )}
        {getTranslation(selectedTab, t) === getTranslation("ExtraData", t) && (
          <ExtraDataForm
            selectedLanguage={selectedLanguage.languageName}
            extraDataForm={extraDataForm}
            handleExtraDataInputChange={handleExtraDataInputChange}
          />
        )}
        {getTranslation(selectedTab, t) === getTranslation("Images", t) && (
          <ImageUpload
            selectedLanguage={selectedLanguage.languageName}
            vehicleImages={vehicleImages}
            files={files}
            setFiles={setFiles}
            displayImagefiles={displayImagefiles}
            setDisplayImagefiles={setDisplayImagefiles}
            deletedImages={deletedImages}
            setDeletedImages={setDeletedImages}
            selectedThumbnail={selectedThumbnail}
            setSelectedThumbnail={setSelectedThumbnail}
            handleVideoLinkChange={handleVideoLinkChange}
            videoLink={videoLink}
            setIsUpdatePrimaryImage={setIsUpdatePrimaryImage}
            setIsNew={setIsNew}
          />
        )}
        {getTranslation(selectedTab, t) === getTranslation("TechSpecs", t) && (
          <TechSpecsFeaturesForm
            selectedLanguage={selectedLanguage.languageName}
            specsCategories={specsCategories}
            features={features}
            specsValues={specsValues}
            addRow={addRow}
            deleteRow={deleteRow}
            handleSpecsCategoriesChange={handleSpecsCategoriesChange}
            handleFeaturesChange={handleFeaturesChange}
            handleSpecsValuesChange={handleSpecsValuesChange}
          />
        )}

        {imageUploadError && (
          <div style={{ marginLeft: "20px", marginTop: "50px" }}>
            <p className="error-text">{`${imageUploadError}. Please check duplicate images.`}</p>
          </div>
        )}

        {requiredLanguages.length !== 0 && (
          <div style={{ marginLeft: "20px", marginTop: "50px" }}>
            <span>
              <p className="error-text">
                {getTranslation(
                  "Please complete the required fields with the following languages:",
                  t
                )}{" "}
                {requiredLanguages.map((lang, idx) => {
                  return (
                    <>
                      {lang}
                      {requiredLanguages.length > 1 &&
                        requiredLanguages.length !== idx + 1 &&
                        ", "}
                    </>
                  );
                })}
              </p>
            </span>
          </div>
        )}
        <div className="footer d-flex justify-content-end gap-3">
          <button
            onClick={() => Navigate(`/vehicles`)}
            className="d-flex justify-content-center align-items-center gap-2 secondary-btn"
          >
            <label className="m-0">{getTranslation("Cancel", t)}</label>
          </button>
          <button
            onClick={() => handleSave()}
            className="d-flex justify-content-center align-items-center gap-2 primary-btn"
          >
            <label className="m-0">{getTranslation("Save", t)}</label>
          </button>
        </div>
      </div>
    </div>
  );
};

export default VehiclesForm;
