/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../styles/dashboard/dashboard.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DashboardCampaign from "./DashboardCampaign";
import DashboardTasks from "./DashboardTasks";
import DashboardPerformance from "./DashboardPerformance";
import fetchClient from "../../api/fetch";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const Dashboard = ({
  setLoading,
  loadingCount,
  setLoadingCount,
  setDashboardLoadType,
}) => {
  const { t } = useTranslation();
  //Dropdown Filters
  const dropdownFilter = [
    getTranslation("This week", t),
    getTranslation("This month", t),
    getTranslation("This year", t),
  ];

  //Set Funnel Color
  const getFunnelColor = (type) => {
    switch (type) {
      case "Opportunities":
        return "#16BAE7";
      case "Leads":
        return "#B7CD00";
      case "Appointed":
        return "#9E61A4";
      case "Attended":
        return "#7887fb";
      case "Sold":
        return "#00233a";
      default:
        return;
    }
  };

  //useStates
  const [campaignDropdownValue, setCampaignDropdownValue] = useState(
    getTranslation("This week", t)
  );
  const [taskDropdownValue, setTaskDropdownValue] = useState(
    getTranslation("This week", t)
  );
  const [performanceDropdownValue, setPerformanceDropdownValue] = useState(
    getTranslation("This week", t)
  );
  const [sort, setSort] = useState([]);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [page, setPage] = useState({
    skip: 0,
    take: 20,
  });
  const [statusFilter, setStatusFilter] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const [dashboardData, setDashboardData] = useState({
    campaigns: [],
    task: [],
    performance: [],
  });
  const [params, setParams] = useState({
    campaigns: "week",
    task: "week",
    performance: "week",
    sort: "",
  });

  //useEffect
  //GET DASHBOARD CAMPAIGNS
  useEffect(() => {
    setDashboardLoadType("campaigns");
    setLoading(true);
    fetchClient()
      .get(`/v1/dashboard/campaigns?date=${params.campaigns}`)
      .then((res) => {
        setDashboardData((prevState) => ({
          ...prevState,
          campaigns: res.data.data,
        }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        if (loadingCount) {
          setLoading(false);
        }
      });
  }, [params.campaigns]);

  //GET DASHBOARD PERFORMANCE
  useEffect(() => {
    setDashboardLoadType("performance");
    setLoading(true);
    fetchClient()
      .get(`/v1/dashboard/performance?date=${params.performance}`)
      .then((res) => {
        const data = res.data.data.map((e) => ({
          statusName: e.statusName,
          status: e.status,
          value: e.rawValue,
          percent: e.formatted,
          color: getFunnelColor(e.statusName),
        }));
        setDashboardData((prevState) => ({
          ...prevState,
          performance: data,
        }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setLoadingCount(1);
      });
  }, [params.performance]);

  //GET DASHBOARD TASK
  useEffect(() => {
    setDashboardLoadType("tasks");
    setLoading(true);
    fetchClient()
      .get(`/v1/dashboard/task?` + queryBuilder())
      .then((res) => {
        setDashboardData((prevState) => ({
          ...prevState,
          task: res.data,
        }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setLoadingCount(1);
      });
  }, [params.task, page, params.sort, statusFilter]);

  //query function for params
  const queryBuilder = () => {
    return `${params.sort}${
      params.sort ? "&" : ""
    }&statusFilters=[${statusFilter.toString()}]&page=${JSON.stringify(
      page
    )}&date=${params.task}`;
  };

  const getValue = (val) => {
    if(['This week', "See nädal", "Šią savaitę", "Šonedēļ"].includes(val)) {
      return "week";
    } else if(['This month', "Sel kuul", "Šį mėnesį", "Šomēnes"].includes(val)) {
      return "month";
    } else if(['This year', "Sellel aastal", "Šiais metais", "Šogad"].includes(val)) {
      return "year";
    }
  };

  //Handle Campaigns Dropdown Filter
  const handleCampaignFilterChange = (e) => {
    setCampaignDropdownValue(getTranslation(e.target.value, t));
    setParams({ ...params, campaigns: getValue(e.target.value) });
  };

  //Handle Task Dropdown Filter
  const handleTaskFilterChange = (e) => {
    setTaskDropdownValue(getTranslation(e.target.value, t));
    setParams({ ...params, task: getValue(e.target.value) });
  };

  //Handle Performance Dropdown Filter
  const handlePerformanceFilterChange = (e) => {
    setPerformanceDropdownValue(getTranslation(e.target.value, t));
    setParams({ ...params, performance: getValue(e.target.value) });
  };

  //Dropdown custom style
  const itemRender = (li) => {
    const itemChildren = (
      <span className="dropdown-list-items">{li.props.children}</span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  //Dashboard task pageChange function
  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...e.page,
      skip: e.page.skip,
      take,
    });
  };

  //Dashboard task sorting change function
  const handleSortChange = (e) => {
    setSort(e.sort);
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  //Dashboard task handle status change
  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value.map((item) => item.value));
    setSelectedStatus(e.value);
  };

  return (
    <div className="dashboard-container">
      <p className="h1 mb-4">{getTranslation("Dashboard", t)}</p>
      <div className="dashboard-contents row p-0 m-0">
      </div>
    </div>
  );
};

export default Dashboard;
