/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import "../../styles/searchModule/searchModule.css";
import { useNavigate } from "react-router-dom";
import { getIcon } from "../../utils/iconUtils";
import { Input } from "@progress/kendo-react-inputs";
import { getStatusClass } from "../../utils/statusUtil";
import ColumnHider from "../shared/ColumnHider";
import { Pager } from "@progress/kendo-react-data-tools";
import { searchModuleColumns } from "./gridColumns";
import { validateUserPermission } from "../../utils/helpers";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { DateFilterCell } from "../shared/DateFilterCell";

const SearchModule = ({
  searchModuleParams,
  setSearchModuleParams,
  getSearchModuleList,
  searchModuleList,
  setSearchModuleList,
  searchModuleListPage,
  currentUser
}) => {
  const { t } = useTranslation();
  const [isGridVisible, setIsGridVisible] = useState(false);
  const Navigate = useNavigate();
  const [page, setPage] = useState({
    skip: 0,
    take: 10
  });
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState(undefined);
  const [params, setParams] = useState(searchModuleParams);
  const [hasSearchOnLoad, setHasSearchOnLoad] = useState(false);
  const [showColumnHider, setShowColumnHider] = useState(false);
  const [columns, setColumns] = useState(searchModuleColumns);

  useEffect(() => {
    if (!hasSearchOnLoad) {
      for (var key in params) {
        if (
          (key === "filters" && params[key].length !== 0) ||
          (key !== "filters" && params[key] !== null && params[key] !== "")
        ) {
          getSearchModuleList(queryBuilder());
          setIsGridVisible(true);
        }
      }
      setHasSearchOnLoad(true);
    }
  }, [hasSearchOnLoad]);

  const queryBuilder = () => {
    let queryString = "";
    for (var key in params) {
      if (queryString !== "") {
        queryString += "&";
      }
      if (!["filters", "sort", "statusFilters", "page"].includes(key))
        queryString += key + "=" + params[key];
    }

    return `?${queryString}`;
  };

  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    const pageData = {
      ...e.page,
      skip: e.skip,
      take
    };
    setPage(pageData);
    getSearchModuleList(`${queryBuilder()}&page=${JSON.stringify(pageData)}`);
  };

  const handleButtonClick = () => {
    const emptyObj = {
      sort: "",
      filters: [],
      statusFilters: "",
      leadType: "",
      name: "",
      bank: "",
      postalCode: "",
      currentVehicle: "",
      contractId: "",
      phoneNumber: "",
      licensePlate: "",
      vinNumber: ""
    };

    if (JSON.stringify(params) !== JSON.stringify(emptyObj)) {
      getSearchModuleList(queryBuilder());
      setSearchModuleParams(params);
      setIsGridVisible(true);
    } else {
      setSearchModuleList([]);
      setIsGridVisible(false);
    }
  };

  const handleSearch = (e) => {
    const key = e.target.props.name;
    const value = e.target.value;
    const numOnly = /^[0-9\s]*$/; // only allows numbers and backspace

    if (
      (["postalCode", "phoneNumber"].includes(key) && numOnly.test(value)) ||
      (!["postalCode", "phoneNumber"].includes(key) && value)
    ) {
      setParams({
        ...params,
        [key]: value
      });
      setPage({ ...page, skip: 0 });
    } else {
      setParams({
        ...params,
        [key]: ""
      });
    }
  };

  const handleSort = (e) => {
    setSort(e.sort);
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
    getSearchModuleList(
      `${queryBuilder()}&${params.sort}${
        params.sort ? "&" : ""
      }&page=${JSON.stringify(page)}`
    );
  };

  const handleFilterChange = (e) => {
    setFilter(e.dataState.filter);
    let filters = [];
    if (e.dataState.filter !== undefined) {
      filters = e.dataState.filter.filters.map((item) => {
        let obj = { ...item };
        obj["condition"] = obj["operator"];
        delete obj["operator"];
        obj = JSON.stringify(obj);
        return obj;
      });

      setParams({
        ...params,
        filters: filters
      });
      setPage({
        ...page,
        skip: 0
      });
    } else {
      filters = [];
      setParams({ ...params, filters: [] });
    }

    const filter = `filters=[${filters}]`;

    getSearchModuleList(
      `${queryBuilder()}&${params.sort}${
        params.sort ? "&" : ""
      }&page=${JSON.stringify(page)}&${filter}`
    );
  };

  const statusCell = (props) => {
    return (
      <td onClick={() => handleRowClick(props)}>
        <span className={getStatusClass(props.dataItem.statusName)}>
          {getTranslation(props.dataItem.statusName, t)}
        </span>
      </td>
    );
  };

  const customPager = (props) => {
    return (
      <div className="custom-pager">
        <div className="imgs">
          <img
            className="column-hider"
            onClick={() => setShowColumnHider(true)}
            src={getIcon("settings.png")}
            alt="column hider icon"
          />
        </div>
        <Pager
          skip={props.skip}
          take={props.take}
          type="numeric"
          total={props.total}
          buttonCount={props.buttonCount}
          previousNext={true}
          pageSizes={props.pageSizes}
          pageSizeValue={props.pageSizeValue}
          onPageChange={pageChange}
        />
      </div>
    );
  };

  const handleRowClick = ({ dataItem }) => {
    const id =
      dataItem.statusName === "Record" ? dataItem.ivId : dataItem.leadId;
    if (validateUserPermission(currentUser.permissions, "canView", "Leads"))
      Navigate(`/customer-record/${id}`, {
        state: { prevPage: "search-module" }
      });
  };

  return (
    <div className="search-module">
      <p className="h1 mb-4">{getTranslation("Search Module", t)}</p>
      <div className="module-cont">
        <div className="header d-flex mb-2">
          <div className="search d-flex flex-column">
            <div className="topRow">
              <div className="row g-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <label>{getTranslation("Name", t)}</label>
                    <Input
                      name="name"
                      onChange={handleSearch}
                      className="primary-input"
                      type="text"
                      value={params.name}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <label>{getTranslation("Contract ID", t)}</label>
                    <Input
                      name="contractId"
                      onChange={handleSearch}
                      className="primary-input"
                      type="text"
                      value={params.contractId}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <label>{getTranslation("Post code", t)}</label>
                    <Input
                      name="postalCode"
                      onChange={handleSearch}
                      className="primary-input"
                      type="text"
                      value={params.postalCode}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <label>{getTranslation("Bank/Seller", t)}</label>
                    <Input
                      name="bank"
                      onChange={handleSearch}
                      className="primary-input"
                      type="text"
                      value={params.bank}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <label>{getTranslation("License plate", t)}</label>
                    <Input
                      name="licensePlate"
                      onChange={handleSearch}
                      className="primary-input"
                      type="text"
                      value={params.licensePlate}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex second-row gap-5">
              <div className="col-md-3">
                <div className="d-flex flex-column">
                  <label>{getTranslation("VIN", t)}</label>
                  <Input
                    name="vinNumber"
                    onChange={handleSearch}
                    className="primary-input"
                    type="text"
                    value={params.vinNumber}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-column">
                  <label>{getTranslation("Current vehicle", t)}</label>
                  <Input
                    name="currentVehicle"
                    onChange={handleSearch}
                    className="primary-input"
                    type="text"
                    value={params.currentVehicle}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-column">
                  <label>{getTranslation("Mobile No.", t)}</label>
                  <Input
                    name="phoneNumber"
                    onChange={handleSearch}
                    className="primary-input"
                    type="text"
                    value={params.phoneNumber}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <button
                  className="search-btn primary-btn"
                  onClick={handleButtonClick}
                >
                  <img src={getIcon("searchInput.png")} alt="search icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isGridVisible && (
        <div className="grid-cont">
          <Grid
            data={searchModuleList.map((item) => ({
              ...item,
              lastActivity: item.lastActivity
                ? new Date(item.lastActivity)
                : null
            }))}
            skip={page.skip}
            take={page.take}
            filter={filter}
            sortable={true}
            sort={sort}
            onSortChange={handleSort}
            total={searchModuleListPage.totalRecords}
            pager={customPager}
            pageable={{
              buttonCount:
                searchModuleListPage.totalPages > 10
                  ? 10
                  : searchModuleListPage.totalPages,
              pageSizes: [5, 10, 15],
              pageSizeValue
            }}
            filterable={true}
            onDataStateChange={handleFilterChange}
            onRowClick={(e) => handleRowClick(e)}
          >
            {columns.map((column, idx) => {
              if (idx === 0) {
                return (
                  <Column
                    field={column.column}
                    key={idx}
                    cell={statusCell}
                    filterable={true}
                    filter={column.filter}
                    title={getTranslation(column.title, t)}
                  />
                );
              } else {
                return column.isShown ? (
                  <Column
                    key={idx}
                    field={column.column}
                    title={getTranslation(column.title, t)}
                    filterable={true}
                    filter={column.filter}
                    filterCell={column.filter === "date" ? DateFilterCell : undefined}
                    format={
                      column.filter === "date" ? "{0:dd/MM/yyyy}" : undefined
                    }
                  />
                ) : null;
              }
            })}
          </Grid>
        </div>
      )}
      {showColumnHider && (
        <ColumnHider
          closeHider={() => setShowColumnHider(false)}
          columns={columns}
          setColumns={setColumns}
        />
      )}
    </div>
  );
};

export default SearchModule;
