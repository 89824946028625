import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_MARKET_SETTINGS, SAVE_DEFAULT_LANGUAGE } from "../actions/types";
import { setMarketSettings, setLoading } from "../actions";
import { getMarketSettingsApi, saveDefaultLanguageApi } from "../api/handler";

function* handleLoadMarketSettings() {
  yield put(setLoading(true));
  try {
    const response = yield call(getMarketSettingsApi);
    if (response.status === 200) {
      yield put(setMarketSettings(response.data.data));
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleSaveDefaultLanguage({ data }) {
  yield put(setLoading(true));
  try {
    const response = yield call(saveDefaultLanguageApi, data);
    if (response.status === 200) {
      yield call(handleLoadMarketSettings);
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

export default function* marketSettingsSaga() {
  yield all([takeLatest(GET_MARKET_SETTINGS, handleLoadMarketSettings)]);
  yield all([takeLatest(SAVE_DEFAULT_LANGUAGE, handleSaveDefaultLanguage)]);
}
