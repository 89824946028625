/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { getIcon } from "../../utils/iconUtils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CardBody, CardTitle, StackLayout } from "@progress/kendo-react-layout";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import cars from "./sampleCars.js";
import SlideButton from "./SlideButton";
import "../../styles/editProposedDeal/editProposedDeal.css";
import { Input, RadioGroup } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import FinanceRecord from "../editProposedDeal/FinanceRecord";
import fetchClient from "../../api/fetch";
import noDataImg from "../../assets/images/no_result.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { financeRecordData } from "./financeRecordData";
import { financeData } from "../portfolio/FilterDropdownData";
import { format } from "date-fns";
import placeholder from "../../assets/images/empty-car.png";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const EditProposedDeal = ({
  marketSettings,
  setLoading,
  setLoadingCount,
  isLoading,
  loadingCount
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const { id } = useParams();
  let location = useLocation();
  const handleBack = () => {
    Navigate(`/customer-record/${id}`, {
      state: { prevPage: location.state?.prevPage }
    });
  };

  const initialInputs = {
    totalKilometers: "",
    tradeInValue: "",
    settlementFigure: ""
  };

  const initialFilters = {
    priceFrom: "",
    priceTo: "",
    monthlyPaymentFrom: "",
    monthlyPaymentTo: ""
  };

  const [carDistribution, setCarDistribution] = useState([]);
  const [selectedCar, setSelectedCar] = useState(undefined);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [showTermError, setShowTermError] = useState(false);
  const [carousel, setCarousel] = useState({
    type: "model",
    typeNum: 1
  });
  const [carouselContent, setCarouselContent] = useState([]);
  const [data, setData] = useState({
    financeGrid: financeRecordData,
    carousel: cars,
    financeType: financeData
      .filter((item) => item.value !== 1)
      .map((item) => ({
        ...item,
        textField: getTranslation(item.textField, t)
      }))
  });
  //Type of car
  const [values, setValues] = useState({
    carType: "New",
    financeType: data.financeType[1]
  });
  const [payload, setPayload] = useState({});
  const carPerPage = useRef(0);
  const clientName = useRef("");
  const isApply = useRef(false);
  const isInitial = useRef(true);
  const isSave = useRef(false);
  const swiperEl = useRef(undefined);
  const selectedHolder = useRef(null);
  const filterHolder = useRef(initialFilters);

  const typeOfCar = [
    {
      label: getTranslation("New", t),
      value: "New",
      className: "newTypeOfCar",
      disabled: values.carType === "Used" || !values.carType
    },
    {
      label: getTranslation("Used", t),
      value: "Used",
      className: "usedTypeOfCar",
      disabled: values.carType === "New" || !values.carType
    }
  ];

  const setFinanceGridData = (financeRes, data) => {
    let index;
    if (data.type && data.type === "price") {
      index = data.field.indexOf("Formatted");
    }
    const symbol = marketSettings.currencySymbol;

    const getTermKmCondition = (type) =>
      `${financeRes[type].term} / ${
        financeRes[type].totalKilometers
          ? financeRes[type].totalKilometers.toLocaleString() + " km"
          : "0 km"
      }`;

    const getNonTermKmCondition = (type) =>
      !financeRes[type][data.field]
        ? data.type === "price"
          ? financeRes[type][data.field.slice(0, index)] !== null
            ? symbol + "0"
            : "N/A"
          : [
              "startKilometers",
              "startAge",
              "vin",
              "licencePlate",
              "totalKilometers"
            ].includes(data.field)
          ? type === "proposed" && data.field === "totalKilometers"
            ? "N/A"
            : null
          : "N/A"
        : data.type
        ? data.type === "date"
          ? format(new Date(financeRes[type][data.field]), "dd/MM/yyyy")
          : data.type === "price"
          ? symbol + financeRes[type][data.field].toLocaleString()
          : financeRes[type][data.field].toLocaleString() + "%"
        : financeRes[type][data.field];

    return data.field === "termOrTotalKm"
      ? {
          ...data,
          proposed: financeRes.proposed ? getTermKmCondition("proposed") : "",
          existing: getTermKmCondition("existing")
        }
      : {
          ...data,
          proposed: financeRes.proposed
            ? getNonTermKmCondition("proposed")
            : "",
          existing: getNonTermKmCondition("existing")
        };
  };

  useEffect(() => {
    setLoading(true);
    let URL = `/v1/leads/${id}/proposed/vehicles?level=${carousel.typeNum}${
      carousel.typeNum > 1
        ? "&model=" +
          (selectedCar ? selectedCar.model : selectedHolder.current.model) +
          (carousel.typeNum === 3
            ? "&version=" +
              (selectedCar
                ? selectedCar.version
                : selectedHolder.current.version)
            : "")
        : ""
    }`;

    if (isApply.current) {
      for (const item in payload.filters) {
        if (payload.filters[item]) {
          URL += `&${item}=${payload.filters[item]}`;
        }
      }
    }

    fetchClient()
      .get(URL)
      .then((res) => {
        if (!isSave.current) {
          swiperEl?.current.slideTo(0);
        }
        const financeRes = res.data.financeRecord;
        if (selectedCar === undefined) {
          clientName.current = res.data.customerRecord.customerName;
          const newData = {
            ...data,
            client: res.data.clientPosition,
            finance: financeRes,
            existing: {
              totalKilometers: financeRes.existing.totalKilometers,
              tradeInValue: financeRes.existing.tradeInValueFormatted,
              settlementFigure: financeRes.existing.settlementValueFormatted
            },
            carousel: res.data.proposedVehicles
          };
          setData(newData);
          setPayload({
            ...payload,
            filters: initialFilters,
            dealCalc: { ...payload.dealCalc, ...initialInputs }
          });
          setSelectedCar(
            res.data.proposedVehicles.length > 0 ? financeRes.proposed : null
          );

          if (isInitial.current) {
            setCarousel({ type: "modelFullName", typeNum: 3 });
            return;
          }
        }
        setCarouselContent(res.data.proposedVehicles);
        if (isInitial.current || isSave.current) {
          handleChangeCar(financeRes.proposed);
          isSave.current = false;
        } else {
          handleChangeCar(
            res.data.proposedVehicles.length > 0
              ? carPerPage.current > 2 && res.data.proposedVehicles.length > 2
                ? res.data.proposedVehicles[1]
                : res.data.proposedVehicles[0]
              : null
          );
        }
        isInitial.current = false;
      })
      .catch((err) => {
        setSelectedCar(null);
        console.log(err);
      })
      .finally(() => {
        if (!isInitial.current) {
          setTimeout(() => setLoading(false), 100);
        }
      });
  }, [carousel]);

  //Swiper
  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    const pageNum = Math.min(3, Math.floor(window.innerWidth / 480));
    carPerPage.current = pageNum;
    const arr = [];
    // store to array 0, 0 + carPerPage, ...
    // previous element becomes start & next element is end
    for (let i = 0; i < Math.ceil(carouselContent.length / pageNum); i++) {
      if (i === 0) {
        arr.push(i);
      } else {
        arr.push(arr[i - 1] + pageNum);
      }
    }

    setCarDistribution(arr);
  }, [currentWidth, carouselContent]);

  useEffect(() => {
    const loaderbg = document.querySelector(".loading.grid");
    if (isApply.current && loadingCount && loaderbg) {
      loaderbg.style.background = "transparent";
    }
  }, [isLoading]);

  useEffect(() => {
    setValues({
      ...values,
      financeType: {
        ...values.financeType,
        textField: t(
          values.financeType.financeType.toLowerCase().split(" ").join("_")
        )
      }
    });
    setData({
      ...data,
      financeType: data.financeType.map((item) => ({
        ...item,
        textField: t(item.financeType.toLowerCase().split(" ").join("_"))
      }))
    });
  }, [t]);

  const handleSwiperButtons = (swiper) => {
    if (swiper.isBeginning) {
      swiper.navigation.prevEl[0].classList.add("disabled");
      swiper.navigation.nextEl[0].classList.remove("disabled");
    } else if (swiper.isEnd) {
      swiper.navigation.nextEl[0].classList.add("disabled");
      swiper.navigation.prevEl[0].classList.remove("disabled");
    } else {
      swiper.navigation.prevEl[0].classList.remove("disabled");
      swiper.navigation.nextEl[0].classList.remove("disabled");
    }
  };

  const handleDropdownChange = (e) => {
    setValues({ ...values, financeType: e.target.value });
    setPayload({
      ...payload,
      dealCalc: {
        ...payload.dealCalc,
        replacementFinanceType: e.target.value.financeType
      }
    });
  };

  // moving selection forward
  const handleChangeCar = (car) => {
    if (!isInitial.current && selectedCar && selectedCar === car) {
      // move to next level
      switch (carousel.type) {
        case "model":
          setCarouselContent([]);
          setCarousel({ type: "version", typeNum: 2 }); // also save previously selected
          setLoadingCount(0);
          break;
        case "version":
          setCarouselContent([]);
          setCarousel({ type: "modelFullName", typeNum: 3 });
          setLoadingCount(0);
          break;
        default:
          break;
      }
      setPayload({ ...payload, filters: filterHolder.current });
    } else {
      setSelectedCar(car);
      setValues({
        financeType: car
          ? data.financeType.find(
              (item) => item.financeType === car.financeTypeName
            )
          : { financeType: "" },
        carType: car ? car.newOrUsed : null
      });
      setShowTermError(false);
      setData((prevData) => ({
        ...prevData,
        finance: {
          ...prevData.finance,
          proposed: car
        },
        financeGrid: prevData.financeGrid.map((element) =>
          setFinanceGridData(
            {
              existing: prevData.finance.existing,
              proposed: car
            },
            element
          )
        )
      }));
      if (car) {
        selectedHolder.current = car;
        setPayload({
          ...payload,
          dealCalc: {
            ...payload.dealCalc,
            ...initialInputs,
            dealerDeposit: car.totalDealerDepositFormatted.toFixed(2),
            additionalCustomerDeposit:
              car.totalCustomerDepositFormatted.toFixed(2),
            replacementFinanceTerm: car.term,
            replacementFinanceType: car.financeTypeName,
            replacementInterestRatePercentage: car.interestRateFormatted,
            newOrUsed: car.newOrUsed,
            purchasePrice: car.purchasePriceFormatted,
            replacementVehicleId: car.replacementVehicleId
          }
        });
      }
    }
  };

  // moving selection backwards
  const handleCarouselBtnClick = (e) => {
    setCarouselContent([]);
    let typeNum;
    switch (e.target.name) {
      case "model":
        typeNum = 1;
        break;
      case "version":
        typeNum = 2;
        break;
      default:
        typeNum = 3;
        break;
    }
    setCarousel({ type: e.target.name, typeNum });
    setPayload({ ...payload, filters: filterHolder.current });
    setLoadingCount(0);
    setShowTermError(false);
  };

  const handleChangeInput = (e) => {
    if (
      [
        "priceFrom",
        "priceTo",
        "monthlyPaymentFrom",
        "monthlyPaymentTo"
      ].includes(e.target.name) &&
      (Number(e.target.value) ||
        e.target.value === "-" ||
        !e.target.value ||
        e.target.value === "0")
    ) {
      setPayload({
        ...payload,
        filters: {
          ...payload.filters,
          [e.target.name]: e.target.value
        }
      });
    } else if (
      e.target.value >= 0 &&
      (e.target.name === "replacementFinanceTerm"
        ? !(Number(e.target.value) % 1)
        : true) && // disallow decimals
      (["totalKilometers", "tradeInValue", "settlementFigure"].includes(
        e.target.name
      )
        ? e.target.value.split(".").length <= 2 // limits inputted dot to one
        : true) &&
      (e.target.name === "replacementInterestRatePercentage"
        ? e.target.value <= 100
        : true)
    ) {
      setPayload({
        ...payload,
        dealCalc: {
          ...payload.dealCalc,
          [e.target.name]:
            e.target.name === "replacementFinanceTerm"
              ? e.target.value
                ? Number(e.target.value.replace(".", ""))
                : ""
              : [
                  "totalKilometers",
                  "tradeInValue",
                  "settlementFigure"
                ].includes(e.target.name)
              ? e.target.value
                ? e.target.value.replace("-", "") // disallow negatives
                : ""
              : e.target.value
        }
      });
    }
    if (
      e.target.name === "replacementFinanceTerm" &&
      ((e.target.value >= 12 &&
        e.target.value <= 60 &&
        !(e.target.value % 12)) ||
        Number(e.target.value) ===
          data.financeGrid.find((item) => item.field === "term").proposed)
    ) {
      setShowTermError(false);
    } else if (e.target.name !== "replacementFinanceTerm") {
      return;
    } else {
      setShowTermError(true);
    }
  };

  const handleFocusOut = (e) => {
    if (
      [
        "priceFrom",
        "priceTo",
        "monthlyPaymentFrom",
        "monthlyPaymentTo"
      ].includes(e.target.name)
    ) {
      setPayload({
        ...payload,
        filters: {
          ...payload.filters,
          [e.target.name]:
            payload.filters[e.target.name] &&
            payload.filters[e.target.name] !== "-"
              ? Number(payload.filters[e.target.name]).toFixed(2)
              : ""
        }
      });
    } else {
      setPayload({
        ...payload,
        dealCalc: {
          ...payload.dealCalc,
          [e.target.name]:
            e.target.name === "replacementFinanceTerm"
              ? payload.dealCalc.replacementFinanceTerm
              : !payload.dealCalc[e.target.name]
              ? e.target.name === "additionalCustomerDeposit" ||
                e.target.name === "dealerDeposit"
                ? "0.00"
                : ""
              : payload.dealCalc[e.target.name]
              ? Number(payload.dealCalc[e.target.name]).toFixed(2)
              : ""
        }
      });
    }
  };

  const configDataTypes = () => {
    const nonFloat = [
      "newOrUsed",
      "replacementFinanceType",
      "totalKilometers",
      "replacementVehicleId"
    ];
    let newData = {};
    for (const item in payload.dealCalc) {
      if (payload.dealCalc[item] || payload.dealCalc[item] === 0) {
        newData[item] = nonFloat.includes(item)
          ? payload.dealCalc[item]
          : Number(payload.dealCalc[item]);
      } else {
        newData[item] = null;
      }
    }

    return newData;
  };

  const handleApply = () => {
    isApply.current = true;
    const newData = configDataTypes();
    if (
      isApply.current &&
      (filterHolder.current !== payload.filters || !selectedCar)
    ) {
      setLoadingCount(0);
      filterHolder.current = payload.filters;
      setCarousel({
        ...carousel
      });
    } else {
      setLoadingCount(1);
    }
    setLoading(true);
    fetchClient()
      .post(`/v1/leads/${id}/proposed/calculate`, newData)
      .then((res) => {
        const newExisting = {
          ...data.finance.existing,
          equity: res.data.data.equity,
          equityFormatted: res.data.data.equityFormatted,
          tradeInValueFormatted: newData.tradeInValue
            ? newData.tradeInValue
            : data.finance.existing.tradeInValueFormatted,
          settlementValueFormatted: newData.settlementFigure
            ? newData.settlementFigure
            : data.finance.existing.settlementValueFormatted,
          totalKilometers: newData.totalKilometers
        };

        const newProposed = {
          ...data.finance.proposed,
          monthlyPayment: res.data.data.newMonthlyPayment,
          monthlyPaymentFormatted: res.data.data.newMonthlyPaymentFormatted,
          totalDeposit: res.data.data.totalDeposit,
          totalDepositFormatted: res.data.data.totalDepositFormatted,
          monthlyDifference: res.data.data.monthlyDifference,
          monthlyDifferenceFormatted: res.data.data.monthlyDifferenceFormatted,
          cashback: res.data.data.depositToMatchThePayment,
          cashbackFormatted: res.data.data.depositToMatchThePaymentFormatted,
          gfv: res.data.data.gfv,
          gfvFormatted: res.data.data.gfvFormatted,
          totalAmountFinanced: res.data.data.totalAmountFinanced,
          totalAmountFinancedFormatted:
            res.data.data.toTalAmountFinancedFormatted,
          financeTypeName: newData.replacementFinanceType,
          term: newData.replacementFinanceTerm,
          totalCustomerDeposit: res.data.data.totalCustomerDeposit,
          totalCustomerDepositFormatted: newData.additionalCustomerDeposit,
          totalDealerDeposit: res.data.data.totalDealerDeposit,
          totalDealerDepositFormatted: newData.dealerDeposit
        };

        setData({
          ...data,
          finance: {
            ...data.finance,
            existing: newExisting,
            proposed: newProposed
          },
          financeGrid: data.financeGrid.map((element) =>
            setFinanceGridData(
              {
                existing: newExisting,
                proposed: newProposed
              },
              element
            )
          )
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = (value = null, field = null) => {
    setLoadingCount(1);
    setLoading(true);
    isApply.current = false;
    const calcPayload = configDataTypes();
    fetchClient()
      .post(`/v1/leads/${id}/proposed/calculate`, calcPayload)
      .then((res) => {
        const src = res.data.data;
        let newData = {
          ...calcPayload,
          equity: src.equity,
          totalDeposit: src.totalDeposit,
          totalAmountFinanced: src.totalAmountFinanced,
          gfv: src.gfv,
          newMonthlyPayment: src.newMonthlyPayment,
          monthlyDifference: src.monthlyDifference,
          costOfChange: src.depositToMatchThePayment,
          purchasePrice:
            field === "purchasePrice" ? value : calcPayload.purchasePrice,
          replacementInterestRatePercentage:
            field === "interestRate"
              ? value
              : calcPayload.replacementInterestRatePercentage
        };
        newData.term = newData.replacementFinanceTerm;
        newData.financeType = newData.replacementFinanceType;
        newData.additionalDealerDeposit = newData.dealerDeposit;
        newData.settlementValue = newData.settlementFigure;
        delete newData.replacementFinanceTerm;
        delete newData.replacementFinanceType;
        delete newData.dealerDeposit;
        delete newData.settlementFigure;

        fetchClient()
          .put(`/v1/leads/${id}/proposed`, newData)
          .then((res) => {
            isSave.current = true;
            setSelectedCar(undefined);
            setCarousel({ ...carousel });
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="edit-deal">
      <div className="d-flex gap-5 align-items-center mb-4 header">
        <img
          onClick={handleBack}
          src={getIcon("arrow-back.svg")}
          alt="back icon"
          className="back-btn"
        />
        <p className="h1 mb-0">{clientName.current}</p>
      </div>

      <CardBody>
        <div className="edit-deal-btn">
          <button
            name="model"
            onClick={
              carousel.type === "model" ? undefined : handleCarouselBtnClick
            }
            className="primary-btn"
          >
            {getTranslation("Model", t)}
          </button>
          <button
            name="version"
            onClick={
              carousel.type === "version" ? undefined : handleCarouselBtnClick
            }
            className={
              carousel.type === "model" ? "secondary-btn" : "primary-btn"
            }
          >
            {getTranslation("Version", t)}
          </button>
          <button
            disabled={carousel.type === "model"}
            className={`btn ${
              carousel.type === "modelFullName"
                ? "primary-btn"
                : "secondary-btn"
            }`}
          >
            {getTranslation("Model Full Name", t)}
          </button>
        </div>

        <div className="swiper-cont mb-3">
          <Swiper
            modules={[Navigation, Pagination]}
            navigation={{
              nextEl: ".slider-btn-next",
              prevEl: ".slider-btn-prev"
            }}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{
              clickable: true,
              type: "bullets"
            }}
            onInit={(swiper) => {
              swiperEl.current = swiper;
              swiper.navigation.prevEl[0].classList.add("disabled");
            }}
            //onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={(swiper) => handleSwiperButtons(swiper)}
          >
            {selectedCar !== null ? (
              carDistribution.map((current, idx, arr) => {
                return (
                  <SwiperSlide key={idx}>
                    {(idx !== carDistribution.length
                      ? carouselContent.slice(current, arr[idx + 1])
                      : carouselContent.slice(current)
                    ).map((car, index, arr) => {
                      let imageSizeClass;
                      if (
                        selectedCar?.replacementVehicleId ===
                        car?.replacementVehicleId
                      ) {
                        imageSizeClass = "img-lg";
                      } else {
                        if (arr.includes(selectedCar)) {
                          const carIndex = arr.indexOf(selectedCar);
                          switch (true) {
                            case index === 0 && carIndex === 2:
                            case index === 2 && carIndex === 0:
                              imageSizeClass = "img-sm";
                              break;
                            default:
                              imageSizeClass = "img-md";
                              break;
                          }
                        } else {
                          imageSizeClass = "img-md";
                        }
                      }
                      return (
                        <div
                          key={index}
                          onClick={() => handleChangeCar(car)}
                          className="car-card mb-2 text-center"
                        >
                          <div className="car-info">
                            <div className="orig-price">
                              From{" "}
                              <b className="price-styles">
                                {marketSettings.currencySymbol +
                                  car?.monthlyPaymentFormatted}{" "}
                              </b>
                              per month
                            </div>
                            <div className="match-pay">
                              {car.monthlyDifferenceFormatted > 0
                                ? "Add "
                                : "Less "}
                              <b className="price-styles">
                                {marketSettings.currencySymbol +
                                  Math.abs(
                                    car.monthlyDifferenceFormatted
                                  )?.toLocaleString()}
                              </b>{" "}
                              to match payment
                            </div>
                          </div>
                          <span className={imageSizeClass}>
                            <img
                              draggable={false}
                              src={placeholder}
                              alt={`${car.name} icon`}
                            />
                          </span>
                          <div
                            className={
                              selectedCar?.replacementVehicleId ===
                              car?.replacementVehicleId
                                ? "car-model selected p-0"
                                : "car-model p-0"
                            }
                          >
                            {car.title}
                          </div>
                        </div>
                      );
                    })}
                  </SwiperSlide>
                );
              })
            ) : (
              <SwiperSlide>
                <div className="car-card mb-2 text-center">
                  <span>
                    <img
                      className="no-data"
                      src={noDataImg}
                      alt="no data icon"
                    />
                  </span>
                  <div>
                    <h4>Sorry! No result found</h4>
                    <p>
                      The data is not currently available. Please try again at
                      any time later.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
          <SlideButton mode="prev" />
          <SlideButton mode="next" />
        </div>
      </CardBody>

      <StackLayout className="row m-0">
        <div className="col-lg-6 p-0 pe-lg-3">
          <CardBody className="finance-record">
            <CardTitle>{getTranslation("Finance Record", t)}</CardTitle>
            <FinanceRecord
              payload={payload}
              selectedCar={selectedCar}
              handleSave={handleSave}
              handleChange={handleChangeInput}
              setPayload={setPayload}
              currencySymbol={marketSettings.currencySymbol}
              data={data}
            />
          </CardBody>
        </div>
        <div className="col-lg-6 p-0 ps-lg-3">
          <CardBody className="deal-calculator">
            <CardTitle>{getTranslation("Deal calculator", t)}</CardTitle>

            <div className="calculations">
              <div className="d-flex flex-wrap gap-1 align-items-center mb-4">
                <label className="label">{getTranslation("Mileage", t)}</label>
                <div className="d-flex align-items-center">
                  <Input
                    value={
                      data.existing?.totalKilometers
                        ? data.existing.totalKilometers.toFixed(2)
                        : "N/A"
                    }
                    readOnly={true}
                    placeholder="Calculated"
                  />{" "}
                  -{" "}
                  <Input
                    name="totalKilometers"
                    disabled={!selectedCar}
                    value={payload.dealCalc?.totalKilometers}
                    onChange={handleChangeInput}
                    onBlur={handleFocusOut}
                    placeholder="Override"
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap gap-1 align-items-center mb-4">
                <label className="label">
                  {getTranslation("Part exchange", t)}
                </label>
                <div className="d-flex align-items-center">
                  <Input
                    value={
                      data.existing?.tradeInValue
                        ? data.existing.tradeInValue.toFixed(2)
                        : "N/A"
                    }
                    readOnly={true}
                    placeholder="Calculated"
                  />{" "}
                  -{" "}
                  <Input
                    name="tradeInValue"
                    disabled={!selectedCar}
                    value={payload.dealCalc?.tradeInValue}
                    onChange={handleChangeInput}
                    onBlur={handleFocusOut}
                    placeholder="Override"
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap gap-1 align-items-center mb-4">
                <label className="label">
                  {getTranslation("Settlement figure", t)}
                </label>
                <div className="d-flex align-items-center">
                  <Input
                    value={
                      data.existing?.settlementFigure
                        ? data.existing.settlementFigure.toFixed(2)
                        : "N/A"
                    }
                    readOnly={true}
                    placeholder="Calculated"
                  />{" "}
                  -{" "}
                  <Input
                    name="settlementFigure"
                    disabled={!selectedCar}
                    value={payload.dealCalc?.settlementFigure}
                    onChange={handleChangeInput}
                    onBlur={handleFocusOut}
                    placeholder="Override"
                  />
                </div>
              </div>
            </div>

            <div className="type-of-car">
              <CardTitle className="deal-calc-lbl">
                {getTranslation("Type of car", t)}
              </CardTitle>
              <RadioGroup
                data={typeOfCar}
                layout={"horizontal"}
                value={values.carType}
              />
            </div>

            <div className="price">
              <CardTitle className="deal-calc-lbl">
                {getTranslation("Price", t)}
              </CardTitle>
              <div className="input-container">
                <div className="d-flex flex-column">
                  <div className="label">{getTranslation("From", t)}</div>
                  <Input
                    name="priceFrom"
                    value={payload.filters?.priceFrom}
                    onChange={handleChangeInput}
                    onBlur={handleFocusOut}
                    className="primary-input"
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="label">{getTranslation("To", t)}</div>
                  <Input
                    name="priceTo"
                    value={payload.filters?.priceTo}
                    onChange={handleChangeInput}
                    onBlur={handleFocusOut}
                    className="primary-input"
                  />
                </div>
              </div>
            </div>

            <div className="monthly-pay-range">
              <CardTitle className="deal-calc-lbl">
                {getTranslation("Monthly payment range", t)}
              </CardTitle>
              <div className="input-container">
                <div className="d-flex flex-column">
                  <div className="label">{getTranslation("From", t)}</div>
                  <Input
                    name="monthlyPaymentFrom"
                    value={payload.filters?.monthlyPaymentFrom}
                    onChange={handleChangeInput}
                    onBlur={handleFocusOut}
                    className="primary-input"
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="label">{getTranslation("To", t)}</div>
                  <Input
                    name="monthlyPaymentTo"
                    value={payload.filters?.monthlyPaymentTo}
                    onChange={handleChangeInput}
                    onBlur={handleFocusOut}
                    className="primary-input"
                  />
                </div>
              </div>
            </div>

            <div className="campaign">
              <CardTitle className="deal-calc-lbl">
                {getTranslation("Campaign name", t)}
              </CardTitle>
              <label>
                {data.client?.campaignName
                  ? data.client.campaignName
                  : getTranslation("No campaign assigned", t)}
              </label>
            </div>

            <div className="finance-type">
              <CardTitle className="deal-calc-lbl">
                {getTranslation("Finance type", t)}
              </CardTitle>
              <DropDownList
                data={data.financeType}
                value={values.financeType}
                textField="textField"
                disabled={!selectedCar}
                onChange={handleDropdownChange}
              />
            </div>

            <div className="term">
              <CardTitle className="deal-calc-lbl">
                {getTranslation("Term", t)}
              </CardTitle>
              <Input
                value={payload.dealCalc?.replacementFinanceTerm}
                onChange={handleChangeInput}
                onBlur={handleFocusOut}
                disabled={!selectedCar}
                name="replacementFinanceTerm"
                className="term-input primary-input"
              />
              {showTermError && (
                <div className="term-error">
                  Allowed terms are 12, 24, 36, 48,{" "}
                  {[12, 24, 36, 48, 60].includes(
                    Number(data.finance.proposed.term)
                  )
                    ? "and "
                    : ""}
                  60{" "}
                  {[12, 24, 36, 48, 60].includes(
                    Number(data.finance.proposed.term)
                  )
                    ? ""
                    : "and " + data.finance.proposed.term}
                </div>
              )}
            </div>

            <div className="customer-deposit">
              <div className="label">
                {getTranslation("Customer deposit (Cash)", t)}
              </div>
              <Input
                name="additionalCustomerDeposit"
                onChange={handleChangeInput}
                type="number"
                onBlur={handleFocusOut}
                disabled={!selectedCar}
                value={payload.dealCalc?.additionalCustomerDeposit}
                className="term-input primary-input"
              />
            </div>

            <div className="add-dealer-deposit">
              <div className="label">
                {getTranslation("Additional dealer deposit", t)}
              </div>
              <Input
                name="dealerDeposit"
                onChange={handleChangeInput}
                type="number"
                onBlur={handleFocusOut}
                disabled={!selectedCar}
                value={payload.dealCalc?.dealerDeposit}
                className="term-input primary-input"
              />
            </div>

            <div className="deal-calc-btn">
              <button
                onClick={handleApply}
                disabled={showTermError}
                className="btn apply-btn secondary-btn"
              >
                {getTranslation("Apply", t)}
              </button>
              <button
                onClick={handleSave}
                disabled={showTermError || !selectedCar}
                className="btn save-btn primary-btn"
              >
                {getTranslation("Save", t)}
              </button>
            </div>
          </CardBody>
        </div>
      </StackLayout>
    </div>
  );
};

export default EditProposedDeal;
