export const financeData = [
  {
    financeType: "All",
    value: 1,
    textField: "All"
  },
  {
    financeType: "Finance Lease",
    value: 2,
    textField: "Finance Lease"
  },
  {
    financeType: "Operating Lease",
    value: 3,
    textField: "Operating Lease"
  }
];

export const newOrUsedData = [
  {
    newOrUsed: "New",
    value: "new"
  },
  {
    newOrUsed: "Used",
    value: "used"
  }
];

export const activityData = [
  {
    activityType: "Lead created",
    value: "leadCreated"
  },
  {
    activityType: "Lead changed",
    value: "leadChanged"
  },
  {
    activityType: "Lead removed",
    value: "leadRemoved"
  }
];

export const leadData = [
  {
    leadType: "Dealer",
    value: 1
  },
  {
    leadType: "Aftersales",
    value: 2
  },
  {
    leadType: "Central",
    value: 3
  }
];
