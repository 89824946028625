import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  GET_HEADER_USER_MARKETS,
  GET_LANGUAGES,
  GET_USER_BRANCHES,
} from "../actions/types";
import {
  setLoading,
  setHeaderUserMarkets,
  setUserBranches,
  setLanguages,
} from "../actions";
import {
  getHeaderUserMarketsApi,
  getLanguagesApi,
  getUserBranchesApi,
} from "../api/handler";

function* handleLoadUserMarkets() {
  try {
    const response = yield call(getHeaderUserMarketsApi);
    if (response.status === 200) {
      yield put(setHeaderUserMarkets(response.data.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* handleLoadUserBranches({ marketId }) {
  try {
    const response = yield call(getUserBranchesApi, marketId);
    if (response.status === 200) {
      yield put(setUserBranches(response.data.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* handleLoadLanguages() {
  try {
    const response = yield call(getLanguagesApi);

    if (response.status === 200) {
      yield put(setLanguages(response.data.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

export default function* commonSagas() {
  yield all([
    takeLatest(GET_HEADER_USER_MARKETS, handleLoadUserMarkets),
    takeLatest(GET_USER_BRANCHES, handleLoadUserBranches),
    takeLatest(GET_LANGUAGES, handleLoadLanguages),
  ]);
}
