import React from "react";
import "../../styles/campaigns/campaignModal.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../common/translation";

const ConfirmationModal = ({
  setShowConfirmationModal,
  deleteVehicle,
  selectedVehicle,
}) => {
  const { t } = useTranslation();
  const handleAccept = () => {
    setShowConfirmationModal(false);
    deleteVehicle(selectedVehicle.vehicleId);
  };

  return (
    <Dialog onClose={() => setShowConfirmationModal(false)}>
      <div className="delete-campaign-box d-flex flex-column gap-3 justify-content-between">
        <h5 className="text-center mb-3 pt-3">
          {getTranslation(
            "Are you sure you want to permanently delete vehicle",
            t
          )}
          {selectedVehicle.modelTranslation}?
        </h5>
        <div className="buttons d-flex justify-content-center gap-2">
          <button
            className="bg-none-btn"
            onClick={() => setShowConfirmationModal(false)}
          >
            <p className="m-0">{getTranslation("No", t)}</p>
          </button>
          <button className="gap-2 primary-btn" onClick={() => handleAccept()}>
            <p className="m-0">{getTranslation("Yes", t)}</p>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
