export const conversionReportGrid = [
  {
    column: "regionDescription",
    width: "176px",
    title: "Region",
    filter: "text",
    isShown: true
  },
  {
    column: "marketDescription",
    width: "176px",
    title: "Market",
    filter: "text",
    isShown: true
  },
  {
    column: "brandDescription",
    width: "176px",
    title: "Brand",
    filter: "text",
    isShown: true
  },
  {
    column: "branchDescription",
    width: "176px",
    title: "Branch",
    filter: "text",
    isShown: true
  },
  {
    column: "campaign",
    width: "176px",
    title: "Campaign",
    filter: "numeric",
    isShown: true
  },
  {
    column: "customerLead",
    width: "176px",
    title: "Customers Leads",
    filter: "numeric",
    isShown: true
  },
  {
    column: "contacted",
    width: "176px",
    title: "Contacted",
    filter: "numeric",
    isShown: true
  },
  {
    column: "formattedContacted",
    width: "200px",
    title: "Contacted %",
    filter: "numeric",
    isShown: true
  },
  {
    column: "contactedMthsElapsed",
    width: "215px",
    title: "Contacted: Months Elapsed",
    filter: "numeric",
    isShown: true
  },
  {
    column: "formattedContactedMthsElapsed",
    width: "280px",
    title: "Contacted: % Agreement Elapsed",
    filter: "numeric",
    isShown: true
  },
  {
    column: "appointed",
    width: "176px",
    title: "Appointed",
    filter: "numeric",
    isShown: true
  },
  {
    column: "formattedAppointed",
    width: "200px",
    title: "Appointed %",
    filter: "numeric",
    isShown: true
  },
  {
    column: "appointmentAttended",
    width: "200px",
    title: "Appointment Attended",
    filter: "numeric",
    isShown: true
  },
  {
    column: "formattedAppointmentAttended",
    width: "200px",
    title: "Appointment Attended %",
    filter: "numeric",
    isShown: true
  },
  {
    column: "unableToContact",
    width: "176px",
    title: "Unable to Contact",
    filter: "numeric",
    isShown: true
  },
  {
    column: "formattedUnableToContact",
    width: "176px",
    title: "Unable to Contact %",
    filter: "numeric",
    isShown: true
  },
  {
    column: "futureOpportunity",
    width: "176px",
    title: "Future Opportunity",
    filter: "numeric",
    isShown: true
  },
  {
    column: "formattedFutureOpportunity",
    width: "176px",
    title: "Future Opportunity %",
    filter: "numeric",
    isShown: true
  },
  {
    column: "closedLost",
    width: "176px",
    title: "Closed / Lost",
    filter: "numeric",
    isShown: true
  },
  {
    column: "formattedClosedLost",
    width: "176px",
    title: "Closed / Lost %",
    filter: "numeric",
    isShown: true
  },
  {
    column: "doNotContact",
    width: "176px",
    title: "Do Not Contact",
    filter: "numeric",
    isShown: true
  },
  {
    column: "formattedDoNotContact",
    width: "176px",
    title: "Do Not Contact %",
    filter: "numeric",
    isShown: true
  },
  {
    column: "sold",
    width: "176px",
    title: "Sold",
    filter: "numeric",
    isShown: true
  },
  {
    column: "formattedSold",
    width: "176px",
    title: "Sold %",
    filter: "numeric",
    isShown: true
  },
  {
    column: "soldMthsElapsed",
    width: "200px",
    title: "Sold: Months Elapsed",
    filter: "numeric",
    isShown: true
  },
  {
    column: "formattedSoldMthsElapsed",
    width: "215px",
    title: "Sold: % Agreement Elapsed",
    filter: "numeric",
    isShown: true
  },
  {
    column: "formattedFinalOutCome",
    width: "200px",
    title: "Final Outcome Rate",
    filter: "numeric",
    isShown: true
  }
];
