import { Dialog } from "@progress/kendo-react-dialogs";
import { getIcon } from "../../utils/iconUtils";
import "../../styles/shared/columnHider.css";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const ColumnHider = ({ closeHider, setColumns, columns }) => {
  const { t } = useTranslation();

  return (
    <Dialog className="column-hider">
      <div className="d-flex flex-column gap-3 p-2">
        <img
          className="align-self-end"
          onClick={closeHider}
          src={getIcon("close.svg")}
          alt="close icon"
        />
        <h1 className="h1 ms-3 mb-3">{getTranslation("Show/Hide Columns", t)}</h1>
        <div className="row justify-content-between gap-3">
          {columns.map((column, idx) =>
            !column.canBeHidden ? null : (
              <div key={idx} className="col-12 col-sm-6 row align-items-center">
                <p className="mb-0 col-9">{getTranslation(column.title, t)}</p>
                <span className="col-3 text-end d-flex align-items-center">
                  <img
                    onClick={() =>
                      setColumns(
                        columns.map((item) =>
                          item.column === column.column
                            ? { ...item, isShown: !item.isShown }
                            : item
                        )
                      )
                    }
                    src={
                      column.isShown ? getIcon("show.svg") : getIcon("hide.svg")
                    }
                    alt="hide/shown icon"
                  />
                </span>
              </div>
            )
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ColumnHider;
