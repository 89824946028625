/* eslint-disable react-hooks/exhaustive-deps */

import { useNavigate } from "react-router-dom";
import "../../styles/userManagement/accessControl.css";
import { getIcon } from "../../utils/iconUtils";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const AccessControl = ({ setSuccessModalValues }) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const actions = ["Create", "Edit", "View", "Delete"];
  const modules = [
    { id: 1, moduleName: "Dashboard", icon: getIcon("dashboard.svg") },
    { id: 2, moduleName: "Campaigns", icon: getIcon("campaigns.png") },
    { id: 3, moduleName: "Service Alerts", icon: getIcon("serviceAlerts.png") },
    { id: 4, moduleName: "Portfolio", icon: getIcon("portfolio.png") },
    { id: 5, moduleName: "Search Module", icon: getIcon("searchModule.png") },
    { id: 6, moduleName: "Reports", icon: getIcon("reports.png") },
    { id: 7, moduleName: "User Management", icon: getIcon("user.svg") },
    { id: 8, moduleName: "Settings", icon: getIcon("settings.png") },
  ];

  const renderCheckboxCell = ({ dataItem }) => {
    const { moduleName } = dataItem;
    return (
      <td className="text-center">
        <Checkbox checked={true} key={moduleName} />
        {["Campaigns", "User Management"].includes(moduleName) && (
          <div className="actions-module-checkbox">
            {actions.map((action) => {
              return (
                <span className="action">
                  <Checkbox checked={true} key={action} />
                </span>
              );
            })}
          </div>
        )}
      </td>
    );
  };

  const renderModuleCell = ({ dataItem }) => {
    const { moduleName, icon } = dataItem;
    return (
      <td>
        <div>
          <span className="module-icon">
            <img src={icon} alt={`${moduleName.toLowerCase()} icon`} />
          </span>
          <span>
            <label>{getTranslation(moduleName, t)}</label>
          </span>
        </div>
        {["Campaigns", "User Management"].includes(moduleName) && (
          <div className="actions-module">
            {actions.map((action) => {
              return (
                <span className="action">
                  <label>&#x2022; {getTranslation(action, t)}</label>
                </span>
              );
            })}
          </div>
        )}
      </td>
    );
  };

  const renderHeaderCell = (props) => {
    return (
      <span className="k-cell-inner table-header">
        <span className="k-link ">
          <span className="k-column-title">
            {getTranslation(props.title, t)}
          </span>
        </span>
      </span>
    );
  };

  const handleSave = () => {
    // TODO: Update when endpoint is ready
    setSuccessModalValues({
      message: "Access control updated successfully",
      isVisible: true,
    });
  };

  return (
    <div className="access-control">
      <div className="d-flex gap-5 align-items-center mb-4">
        <img
          className="back-btn"
          onClick={() => Navigate("/user-management")}
          src={getIcon("arrow-back.svg")}
          alt="back icon"
        />
        <p className="h1">{getTranslation("Access Control", t)}</p>
      </div>
      <div className="access-control-table">
        <Grid data={modules}>
          <GridColumn
            field="moduleName"
            title="Module"
            cell={renderModuleCell}
          />
          <GridColumn
            title="Group F&I Manager"
            headerCell={renderHeaderCell}
            cell={renderCheckboxCell}
          />
          <GridColumn
            title="Regional F&I Lead"
            headerCell={renderHeaderCell}
            cell={renderCheckboxCell}
          />
          <GridColumn
            title="Country F&I Lead"
            headerCell={renderHeaderCell}
            cell={renderCheckboxCell}
          />
          <GridColumn
            title="Brand Retention Specialist"
            headerCell={renderHeaderCell}
            cell={renderCheckboxCell}
          />
          <GridColumn
            title="Sales Executive"
            headerCell={renderHeaderCell}
            cell={renderCheckboxCell}
          />
        </Grid>
        <div className="control-btn">
          <button
            className="primary-btn save-btn"
            disabled={false}
            onClick={() => handleSave()}
          >
            {getTranslation("Save", t)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccessControl;
