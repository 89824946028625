import React, { cloneElement, useState } from "react";
import "../../styles/serviceAlerts/serviceAlerts.css";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { getIcon } from "../../utils/iconUtils";
import SuccessModal from "../shared/SuccessModal";
import { Pager } from "@progress/kendo-react-data-tools";
import { serviceAlertsGridColumn } from "./gridColumn";
import ColumnHider from "../shared/ColumnHider";
import { Checkbox } from "@progress/kendo-react-inputs";
import { validateUserPermission, formatCurrency } from "../../utils/helpers";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { DateFilterCell } from "../shared/DateFilterCell";

const ServiceAlertsTable = ({
  data,
  serviceAlertsListPage,
  pageChange,
  handleSort,
  handleFilterChange,
  gridSetting,
  alertsModal,
  createAftersalesLeads,
  selectedIvIds,
  setSelectedIvIds,
  marketSettings,
  handleRowClick,
  currentUser
}) => {
  const { t } = useTranslation();
  //useStates
  const [showColumnHider, setShowColumnHider] = useState(false);
  const [columns, setColumns] = useState(serviceAlertsGridColumn);

  const isDisabled = data.every((item) => !item.isAllowed);
  const customPager = (props) => {
    return (
      <div className="custom-pager">
        <img
          onClick={() => setShowColumnHider(true)}
          src={getIcon("settings.png")}
          alt="column hider icon"
        />
        <Pager
          skip={props.skip}
          take={props.take}
          type="numeric"
          total={props.total}
          buttonCount={props.buttonCount}
          previousNext={true}
          pageSizes={props.pageSizes}
          pageSizeValue={props.pageSizeValue}
          onPageChange={pageChange}
        />
      </div>
    );
  };

  const handleSelectAll = () => {
    if (selectedIvIds.length > 0) {
      setSelectedIvIds([]);
    } else {
      setSelectedIvIds([""]);
    }
  };

  const selectAllCell = () => {
    return (
      <div
        onClick={handleSelectAll}
        className="d-flex align-items-center justify-content-center gap-2"
      >
        <Checkbox value={selectedIvIds.length === 0} />
        <span className="k-cell-inner">
          <span className="k-column-title">
            {getTranslation("Select All", t)}
          </span>
        </span>
      </div>
    );
  };

  const handleSelect = (dataItem) => {
    if (selectedIvIds.length === 0) {
      setSelectedIvIds(selectedIvIds.concat(dataItem.ivId));
    } else {
      if (selectedIvIds.includes(dataItem["ivId"])) {
        // check first if idsArray is empty
        const holderArr = selectedIvIds.filter(
          (item) => item !== dataItem["ivId"]
        );
        // remove id from selected
        setSelectedIvIds(holderArr.length > 0 ? holderArr : [""]);
      } else {
        // remove empty string if present then add selected id
        setSelectedIvIds(
          selectedIvIds.includes("")
            ? [dataItem["ivId"]]
            : selectedIvIds.concat(dataItem["ivId"])
        );
      }
    }
  };

  const checkboxCell = ({ dataItem }) => {
    return (
      <td onClick={() => handleSelect(dataItem)}>
        <Checkbox
          disabled={!dataItem.isAllowed}
          value={
            !dataItem.isAllowed
              ? false
              : selectedIvIds.length === 0 ||
                selectedIvIds.includes(dataItem.ivId)
          }
        />
      </td>
    );
  };

  const rowRender = (trElement, props) => {
    const clickable = !props.dataItem.isAllowed;
    const trProps = {
      className:
        props.className + (clickable ? " k-disabled" : " tr-selectable")
    };
    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  const currencyFormatter = (value) => {
    const currencySymbol = marketSettings.currencySymbol;
    let val = value?.toString();
    const isNegative = val?.includes("-");

    if (val && isNegative) {
      const splitValue = val.split("-");
      val = `-${formatCurrency(Number(splitValue[1]), currencySymbol)}`;
      return val;
    } else {
      return val ? `${formatCurrency(Number(val), currencySymbol)}` : null;
    }
  };

  //handle pageChange
  const equityCell = ({ dataItem }) => {
    const equityValue = dataItem.equityFormatted;
    const isNegative = equityValue?.toString()?.includes("-");
    return (
      <td colSpan={1} role="gridCell">
        <span className="equity-cell">
          {isNegative
            ? `(${currencyFormatter(equityValue)})`
            : `${currencyFormatter(equityValue)}`}
        </span>
      </td>
    );
  };

  const currencyCell = (props) => {
    const { dataItem, field } = props;
    const value = dataItem[field];
    const isNegative = value?.toString()?.includes("-");

    return (
      <td colSpan={1} role="gridCell" className="k-table-td">
        {isNegative
          ? `(${currencyFormatter(value)})`
          : `${currencyFormatter(value)}`}
      </td>
    );
  };

  return (
    <div className="service-alerts-grid">
      <div className="mb-3">
        <Grid
          data={data?.map((item) => ({
            ...item,
            lastActivityDate: item.lastActivityDate
              ? new Date(item.lastActivityDate)
              : null
          }))}
          filterable={true}
          skip={gridSetting.page.skip}
          take={gridSetting.page.take}
          sort={gridSetting.sort}
          rowRender={rowRender}
          filter={gridSetting.filter}
          total={serviceAlertsListPage.totalRecords}
          sortable={true}
          onSortChange={handleSort}
          onDataStateChange={handleFilterChange}
          pager={customPager}
          onPageChange={pageChange}
          pageable={{
            buttonCount:
              serviceAlertsListPage.totalPages > 10
                ? 10
                : serviceAlertsListPage.totalPages,
            info: true,
            pageSizes: [5, 10, 20],
            pageSizeValue: gridSetting.pageSizeValue
          }}
          onRowClick={handleRowClick}
        >
          {columns.map((column, idx) => {
            if (idx === 0) {
              return validateUserPermission(
                currentUser.permissions,
                "canCreate",
                "Service Alerts"
              ) ? (
                <Column
                  key={idx}
                  filterable={false}
                  width={column.width}
                  field={column.column}
                  headerCell={selectAllCell}
                  cell={checkboxCell}
                />
              ) : null;
            } else if (column.title === "Equity") {
              return column.isShown ? (
                <Column
                  key={idx}
                  field={column.column}
                  width={column.width}
                  title={getTranslation(column.title, t)}
                  cell={
                    column.column === "equityFormatted" ? equityCell : undefined
                  }
                  filterable={column.filter}
                  filter={column.filter}
                />
              ) : null;
            } else if (
              [
                getTranslation("Payment Diff", t),
                getTranslation("Cost to Change", t),
                getTranslation("New Payment", t),
                getTranslation("Payment", t)
              ].includes(getTranslation(column.title, t))
            ) {
              return (
                <Column
                  key={idx}
                  field={column.column}
                  width={column.width}
                  cell={currencyCell}
                  title={getTranslation(column.title, t)}
                  filterable={true}
                />
              );
            } else {
              return column.isShown ? (
                <Column
                  key={idx}
                  field={column.column}
                  width={column.width}
                  title={getTranslation(column.title, t)}
                  filterable={true}
                  filterCell={column.filter === "date" ? DateFilterCell : undefined}
                  format={
                    column.filter === "date" ? "{0:dd/MM/yyyy}" : undefined
                  }
                />
              ) : null;
            }
          })}
        </Grid>
      </div>
      <div>
        {validateUserPermission(
          currentUser.permissions,
          "canCreate",
          "Service Alerts"
        ) && (
          <button
            className="primary-btn"
            onClick={createAftersalesLeads}
            disabled={selectedIvIds[0] === "" || isDisabled}
          >
            <img src={getIcon("plus.svg")} alt="search icon" />
            <span>{getTranslation("Create aftersales leads", t)}</span>
          </button>
        )}
      </div>

      {alertsModal && (
        <SuccessModal
          toggleSuccessModal={createAftersalesLeads}
          message={"Aftersales leads made successfully"}
        />
      )}
      {showColumnHider && (
        <ColumnHider
          closeHider={() => setShowColumnHider(false)}
          columns={columns}
          setColumns={setColumns}
        />
      )}
    </div>
  );
};

export default ServiceAlertsTable;
