import fetchClient from "./fetch";
import { format } from "date-fns";

export function getCampaignList(param) {
  return fetchClient().get("/v1/campaigns" + param);
}

// TODO: endpoint is still in progress, change url if not the same
export function getCampaignLeadsList(campaignId, param) {
  return fetchClient().get(`v1/campaigns/${campaignId}/leads` + param);
}

export function getUsersList(param) {
  return fetchClient().get("/v1/users" + param);
}

export function getMarketSettingsApi() {
  return fetchClient().get("/v1/marketsetting");
}

export function getVehicleSelectorList(mode, param) {
  if (mode === "existing") {
    return fetchClient().get("/v1/existingvehicles" + param);
  } else {
    return fetchClient().get("/v1/replacementvehicles" + param);
  }
}

export function getLeadsList(param) {
  return fetchClient().get("v1/leads/preview" + param);
}

export function getLeadsCount(data) {
  return fetchClient().post("v1/leads/find", data);
}

export function getHeaderUserMarketsApi() {
  return fetchClient().get("v1/common/usermarkets");
}

export function getUserBranchesApi(marketId) {
  return fetchClient().get(`v1/common/userbranches?marketId=${marketId}`);
}

export function updateUserSettingsApi(data) {
  return fetchClient().put("v1/usersettings", data);
}

export function saveCampaignLeadsApi(data) {
  return fetchClient().post("v1/campaigns/leads", data);
}

export function getPortfolioList(param) {
  return fetchClient().get("/v1/portfolio" + param);
}

export function getServiceAlertsList(param) {
  return fetchClient().get("/v1/servicealerts/preview" + param);
}
export function getSearchModuleListApi(param) {
  return fetchClient().get("v1/searchmodule" + param);
}

export function exportCampaignApi(campaignId) {
  var clientDate = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  return fetchClient().get(
    `v1/export/campaigns/${campaignId}?clientDate=${clientDate}`,
    { responseType: "arraybuffer" }
  );
}

export function removeOpportunityApi(data) {
  return fetchClient().delete(
    `v1/leads/${data.leadId}/removeopportunity`,
    data
  );
}

export function getCustomerRecordDataApi(id) {
  return fetchClient().get(`v1/leads/${id}`);
}

export function getSpecialistsApi() {
  return fetchClient().get("v1/users/specialists");
}

export function getActivityRecordApi(id) {
  return fetchClient().get(`v1/leads/${id}/activities`);
}

export function updateActivityRecordApi(updateActivityRecordData, id) {
  return fetchClient().put(`v1/leads/${id}`, updateActivityRecordData);
}

export function getReportsUsageGridList(param) {
  return fetchClient().get("/v1/reports/userLogins/grid" + param);
}

export function getReportsConversionGridList(param) {
  return fetchClient().get("/v1/reports/conversion/grid" + param);
}

export function exportUsageReportApi(filters) {
  var clientDate = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  return fetchClient().get(
    `/v1/export/reports/userlogin?clientDate=${clientDate}${filters}`,
    { responseType: "arraybuffer" }
  );
}

export function exportConversionReportApi(filters) {
  var clientDate = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  return fetchClient().get(
    `/v1/export/reports/conversion?clientDate=${clientDate}${filters}`,
    { responseType: "arraybuffer" }
  );
}

// TODO: Update when endpoint is ready
export function updateAccessControl(controls) {
  return fetchClient().put(`v1/users/controls`, controls);
}

export function getVehiclesListApi(param) {
  return fetchClient().get("v1/vehicle/vehicle" + param);
}

export function saveVehicleApi(data) {
  return fetchClient().post("v1/vehicle", data);
}

export function saveExistingVehicleApi(data, id) {
  return fetchClient().put(`v1/vehicle?vehicleId=${id}`, data);
}

export function deleteVehicleApi(id) {
  return fetchClient().delete(`v1/vehicle?vehicleId=${id}`);
}

export function getVehicleDataApi(param) {
  return fetchClient().get("v1/vehicle/vehicleById" + param);
}

export function deleteVehicleImagesApi(vehicleId, deletedImages) {
  return fetchClient().delete(`v1/imageUpload?vehicleId=${vehicleId}`, {
    data: deletedImages,
  });
}

export function updatePrimaryImageApi(data) {
  return fetchClient().put(`v1/imageUpload`, data);
}

export function uploadVehicleImagesApi(
  vehicleId,
  primaryImageIndex,
  imageFiles
) {
  const FormData = require("form-data");
  const formData = new FormData();

  for (var x = 0; x < imageFiles.length; x++) {
    formData.append("imageFiles", imageFiles[x]);
  }

  return fetchClient("formData").post(
    `v1/imageUpload?vehicleID=${vehicleId}&primaryImageIndex=${primaryImageIndex}`,
    formData
  );
}

export function saveDefaultLanguageApi(data) {
  return fetchClient().post("v1/marketsetting", data);
}

export function getLanguagesApi() {
  return fetchClient().get("v1/common/languages");
}
