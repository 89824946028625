/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getIcon } from "../../utils/iconUtils";
import "../../styles/userManagement/userManagement.css";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import { useNavigate } from "react-router-dom";
import fetchClient from "../../api/fetch";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import SavingModal from "../shared/SavingModal";

const UserManagement = ({
  usersList,
  getUsersList,
  usersListPage,
  currentUser,
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const [page, setPage] = useState({
    skip: 0,
    take: 10,
  });
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState(undefined);
  const [params, setParams] = useState({
    sort: "",
    filter: "",
    search: "",
  });
  const [dialogValues, setDialogValues] = useState({
    success: false,
    message: "",
    dataItem: {},
  });

  useEffect(() => {
    getUsersList(queryBuilder());
  }, [page, params]);

  const queryBuilder = () => {
    const filter = `filters=[${params.filter.concat(params.search)}]`;

    return `?${params.sort}${
      params.sort ? "&" : ""
    }${filter}&page=${JSON.stringify(page)}`;
  };

  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...e.page,
      skip: e.page.skip,
      take,
    });
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      setParams({
        ...params,
        search: JSON.stringify({
          field: "userFullName",
          value: e.target.value,
          condition: "contains",
        }),
      });
      setPage({ ...page, skip: 0 });
    } else {
      setParams({
        ...params,
        search: "",
      });
    }
  };

  const handleSort = (e) => {
    setSort(e.sort);
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.dataState.filter);
    if (e.dataState.filter !== undefined) {
      setParams({
        ...params,
        filter: e.dataState.filter.filters.map((item) => {
          let obj = { ...item };
          obj["condition"] = obj["operator"];
          delete obj["operator"];
          obj = JSON.stringify(obj);
          return obj;
        }),
      });
      setPage({
        ...page,
        skip: 0,
      });
    } else {
      setParams({ ...params, filter: "" });
    }
  };

  const changeUserState = (userId, currentStatus) => {
    setDialogValues({ ...dialogValues, back: false });
    fetchClient()
      .put(`/v1/users/${userId}?isDisable=${!currentStatus}`)
      .then((res) => getUsersList(queryBuilder()))
      .catch((err) => console.log(err));
  };

  const userSwitch = ({ dataItem }) => {
    return (
      <td>
        <Switch
          checked={!dataItem.isDisabled}
          onChange={() =>
            setDialogValues({
              back: true,
              message: `Do you want to ${
                dataItem.isDisabled ? "enable" : "disable"
              } this user?`,
              dataItem: dataItem,
            })
          }
          dataItem={dataItem}
        />
      </td>
    );
  };

  return (
    <div className="user-management">
      <p className="h1 mb-4">{getTranslation("User Management", t)}</p>
      <div className="module-cont">
        <div className="header d-flex mb-2">
          <div className="search d-flex justify-content-center gap-4">
            <div className="d-flex flex-column">
              <label>{getTranslation("Search", t)}</label>
              <input
                name="search"
                onChange={handleSearch}
                className="primary-input"
                type="text"
              />
            </div>
            <button className="primary-btn">
              <img src={getIcon("searchInput.png")} alt="search icon" />
            </button>
          </div>
          <button
            onClick={() => Navigate(`/user-management/new`)}
            className="offset-1 d-flex justify-content-center align-items-center gap-2 primary-btn"
          >
            <img src={getIcon("plus.svg")} alt="add icon" />
            <p className="m-0">{getTranslation("New user", t)}</p>
          </button>
        </div>
        <div className="grid-cont">
          <Grid
            data={usersList.map((item) => ({
              ...item,
              lastLoginDate: item.lastLoginDate
                ? new Date(item.lastLoginDate)
                : null,
              createdDate: new Date(item.createdDate),
            }))}
            skip={page.skip}
            take={page.take}
            filter={filter}
            sortable={true}
            sort={sort}
            onSortChange={handleSort}
            total={usersListPage.totalRecords}
            pageable={{
              buttonCount:
                usersListPage.totalPages > 10 ? 10 : usersListPage.totalPages,
              pageSizes: [5, 10, 15],
              pageSizeValue,
            }}
            filterable={true}
            onDataStateChange={handleFilterChange}
            onPageChange={pageChange}
            onRowClick={(e) => {
              Navigate(`/user-management/${e.dataItem.userId}`);
            }}
          >
            <Column width="100px" cell={userSwitch} filterable={false} />
            <Column
              field="name"
              title={getTranslation("First Name", t)}
              width="180px"
            />
            <Column
              field="surname"
              title={getTranslation("Last Name", t)}
              width="180px"
            />
            <Column
              field="email"
              title={getTranslation("Email", t)}
              width="200px"
            />
            <Column
              field="market"
              title={getTranslation("Country", t)}
              width="200px"
            />
          </Grid>
        </div>
      </div>

      {dialogValues.back && (
        <SavingModal
          message={dialogValues.message}
          onclickHandleNo={() =>
            setDialogValues({ ...dialogValues, back: false })
          }
          onclickHandleYes={() =>
            changeUserState(
              dialogValues.dataItem.userId,
              dialogValues.dataItem.isDisabled
            )
          }
        />
      )}
    </div>
  );
};
export default UserManagement;
