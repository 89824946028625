import React from "react";
import "../../styles/vehicles/importModal.css";
import { getIcon } from "../../utils/iconUtils";
import { Dialog } from "@progress/kendo-react-dialogs";
import { PiWarningOctagonFill } from "react-icons/pi";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const ImportModalSuccess = ({ handleCloseSuccess, uploadEvent }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog>
        <div className="d-flex align-items-center justify-content-between mb-5">
          <p className="m-0 import-vehicle-title">
            {(getTranslation("Import Vehicle"), t)}
          </p>
          <img
            className="close-icon"
            onClick={handleCloseSuccess}
            src={getIcon("close.svg")}
            alt="close icon"
          />
        </div>
        <div className="import-vehicle-box d-flex flex-column ">
          <div className="mb-3">
            {uploadEvent.success ? (
              <BsFillCheckCircleFill className="success-icon" />
            ) : (
              <PiWarningOctagonFill />
            )}
          </div>
          <span
            className={uploadEvent.error ? "error-title" : "successful-title"}
          >
            {uploadEvent.message}
          </span>

          <div className="d-flex align-items-end justify-content-end gap-5 mt-4">
            <button
              className="d-flex justify-content-center align-items-center gap-2 primary-btn"
              onClick={handleCloseSuccess}
            >
              <label className="m-0">{getTranslation("OK", t)}</label>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ImportModalSuccess;
